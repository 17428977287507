import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInBrowser from "@mui/icons-material/OpenInBrowser";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import openInNew from "../src/Assets/Images/questionUp.svg";
import { TextField } from "@mui/material";
import { useState } from "react";
import {  API, Amplify, graphqlOperation  } from 'aws-amplify';
import AWS from 'aws-sdk';
import awsExports from "./aws-exports";
import { updateCompany,createCompanyQuestion, deleteCompanyQuestion  } from "./graphql/mutations";
import { useSelector } from "react-redux";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useRevalidator } from "react-router-dom";


Amplify.configure(awsExports);


const AccordionItem = ({ title, className, children }) => {
  const CustomIcon = () => {
    return (
        <Box
            sx={{
                width: 30, 
                height: 30, 
                backgroundColor: '#FFFFFF', 
                borderRadius: '50%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.29)'
            }}
        >
            <ExpandMoreIcon style={{color: "#5186EC"}}/>
        </Box>
    );
  };
  return (
    <Accordion
        width="90%"
        height="76px"
        sx={{
          background: "#FAFAFC",
          boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
          borderRadius: "8px",
          opacity: "1",
          '&:before': {
                display: 'none',
            },
          "&.inrisk": {
            background: "#F25A4826",
            boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
            borderRadius: "8px",
            opacity: "1",
          },
        }}
      >
      <AccordionSummary // title
        expandIcon={<CustomIcon />}
        className={className}
        sx={{
          "&.inrisk": {
            background: "#F25A4826",
            borderRadius: "8px",
          },
          color:"#1A2A56",
          fontSize:"20px",
          fontWeight:"500"
        }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails sx={{color:"#777A82",fontSize:"14px"}}>{children}</AccordionDetails>
    </Accordion>
  );
};

function QuestionsAccordion({questions, setQuestions, companyId }) {
  const [newQuestion, setNewQuestion] = useState('');
  const { revalidate } = useRevalidator();

  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenant  = useSelector(state => state.userInfo.tenantId);
  const [tenantId,setTenantId] = useState(isAdmin?URLTenant:currentTenant)

  const handleQuestionChange = (event) => {
    setNewQuestion(event.target.value);
  };

  const updateCompanyWithNewQuestion = async (companyId) => {
    try {
      const input = {
        tenantId,
        id: companyId,
        view: 1,
      };
      await API.graphql(graphqlOperation(updateCompany, { input }));
      revalidate();
      // console.log("Company updated with new question");
    } catch (error) {
      console.error("Error updating company:", error);
    }
  };


  const deleteQuestion = async (question) => {
    try {
      const input = {
        tenantId: question.tenantId,
        id: question.id,
      };
      await API.graphql(graphqlOperation(deleteCompanyQuestion, { input }));
      const updatedQuestions = questions.filter(item => item.id !== question.id);
      setQuestions(updatedQuestions);
      // console.log("Question deleted successfully");
      revalidate();
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  const handleAddQuestion = async () => {
    if (newQuestion.trim().length > 0 && newQuestion.trim().length <= 100) {
      try {
        const input = {
          tenantId,
          question: newQuestion,
          answer: false,
          summary: '',
          companyQuestionsId:companyId,
          companyQuestionsTenantId:tenantId
        };
        const result = await API.graphql(graphqlOperation(createCompanyQuestion, { input }));
        const newQuestionData = result.data.createCompanyQuestion;
        const updatedQuestions = [...questions, newQuestionData];
        setQuestions(updatedQuestions);
        setNewQuestion('');
      await updateCompanyWithNewQuestion(companyId);
      } catch (error) {
        console.error('Error creating new question:', error);
      }
    }
  };
  
  

  const questionCharactersRemaining = 100 - newQuestion.length;

  if (!questions) {
    return <p>No Questions Available</p>;
  }

  const sortedQuestions = [...questions].sort(
    (a, b) => {

      if (a.answer === true && b.answer !== true) return -1;
      if (a.answer !== true && b.answer === true) return 1;

      return (a.template?.position || 0) - (b.template?.position || 0);
    }
  );

    const cardStyle = { 
      height: '175px',
      boxShadow : 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029', 
      position:'relative',
      padding:"20px"
    }

    const typoStyle = { 
      color:"#2F3D63" , 
      fontSize: "20px" , 
      alignContent: "left" , 
      fontWeight: '500',
      paddingTop:"10px"
    }

    return (
          <Box >
            <Card variant="outlined" style={cardStyle}>
  <Typography style={typoStyle}>
    Ask a question
  </Typography>
  <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "500px", gap: "10px" }}>
            <TextField
              style={{ width: 500 }}
              label="Write your question here"
              variant="standard"
              value={newQuestion}
              onChange={handleQuestionChange}
              InputProps={{
                endAdornment: (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      my: 1,
                      width: "90px",
                      height: "30px",
                      backgroundColor: "#5186EC",
                      borderRadius: "8px",
                      '&:hover': {
                        backgroundColor: '#5186EC',
                      },
                    }}
                    onClick={handleAddQuestion}
                    disabled={newQuestion.length === 0 || newQuestion.length > 100}
                  >
                    Add
                  </Button>
                )
              }}
            />
            <Typography style={{ alignSelf: "flex-end", fontSize: "14px", color: "#777A82" }}>
              {questionCharactersRemaining} characters remaining
            </Typography>
          </Box>
          </Card>
            
                <List sx={{display:"flex",flexDirection:'column',gap:"20px"}}>
                {sortedQuestions.map((row) => {
                  return (
                    <AccordionItem
                      title={row.question}
                      key={row.id}
                      className={row.template?.inRisk ? "inrisk" : ""}
                    >
                    <div className="flex flex-row justify-between align-top gap-2">
                      <Typography variant="body1">{row.summary}</Typography>

                      <Button
                        onClick={() => deleteQuestion(row)}
                        style={{
                          color: "#5186EC",
                        }}
                      >
                        <img
                          src="/binIcon.png"
                          alt="Bin Icon"
                          style={{
                            width: "30px",
                            height: "22px",
                            marginTop: "5px",
                          }}
                        />
                      </Button>
                    </div>

                      <List sx={{ padding: 0 }}>
                        {row.citations?.items?.map((citation) => (
                            <ListItem key={citation.id} href={citation.link} target="_blank" rel="noopener noreferrer" sx={{padding:0,display:"flex",width:"fit-content"}}>
                          <Button
                            size="small"
                            target="_blank"
                            rel="noopener noreferrer"
                              sx={{ textTransform: "none",padding:"0 10px 0 5px",minWidth:"fit-content" }}
                            href={citation.link}
                          >
                              <img src={openInNew} alt="Open in new tab"/>
                          </Button>
                          <ListItemText
                            primary={
                              <>
                                  {citation.text} - <span style={{ fontStyle: 'italic' }}>{citation.date}</span>
                              </>
                            }
                            sx={{ textAlign: "right" }}
                          />
                        </ListItem>
                        
                        ))}
                      </List>
                    </AccordionItem>
                  );
                })}
              </List>

          </Box>
    );
}

export default QuestionsAccordion;
