import { companiesByAccountID, companiesByName, getCompanySummaryRecord, listCompanySummaryRecords } from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import JSZip from "jszip";
import { toast } from "react-toastify";
import { createCompanySummaryRecord, deleteCompanySummaryRecord, updateCompanySummaryRecord } from "../graphql/mutations";
function formatNumber(value) {
  return isNaN(value) || !isFinite(value)
    ? "0"
    : Math.round(value).toLocaleString();
}

function checkFormat(value) {
  const numericValue = parseFloat(value);
  return !isNaN(numericValue) ? numericValue : 0;
}

export function constrainValue(value, low, high) {
  return Math.max(Math.min(parseFloat(value), high), low);
}

export function normalizePaydex(value) {
  return constrainValue(parseFloat(value)?.toFixed(1), 1, 10);
}

export function formatArcScore(value) {
  if (value === null || value === undefined || value === "") {
    return "-";
  }
  return constrainValue(parseFloat(value)?.toFixed(1), 1, 10);
}

export function formatCurrency(value) {
  let numericValue = parseFloat(value); 
  if (isNaN(numericValue)) numericValue = 0; 
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(numericValue);
}

export const formatCurrencyValue = (value) => {
  let numericValue = parseFloat(value);
  if (isNaN(numericValue)) return 0;
  return Math.round(numericValue);
};

export async function fetchFileFromS3(key, bucket = 'crediarc-content01853-production') {
  const s3Url = `https://${bucket}.s3.amazonaws.com/${key}`;
  console.log("Fetching data from S3 URL:", s3Url);

  try {
    const response = await fetch(s3Url, {
      method: "GET",
      headers: {
        "If-Modified-Since": new Date(0).toUTCString(), 
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log(`Data fetched successfully for key: ${key} -->`, data);
      return data;
    } 
  } catch (error) {
    console.error(`Failed to fetch data for key: ${key} -->`, error);
    throw error;
  }
}

export async function uploadFileToS3 (key, body, contentType = 'application/json', bucket = 'crediarc-content01853-production') {
  const url = `https://${bucket}.s3.amazonaws.com/${key}`;
  
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': contentType,
    },
    body: body,
  });

  if (!response.ok) {
    throw new Error(`Error uploading file: ${response.statusText}`);
  }

  return {
    url,
    status: response.status,
  };
};


export async function fetchSummaryJSON(tenantId) {
  const key = "public/summary_" + tenantId + ".json";
  let summary = await fetchFileFromS3(key);
  summary = normalizeRowData(summary);
  return summary;
}

export function normalizeRowData(summary) {
  return summary.map((row) => {
    const normalizedRow = { ...row }; 
    for (const key in normalizedRow) {
      if (key!=="Company Name" && typeof normalizedRow[key] === "string") {
        const numericValue = parseFloat(normalizedRow[key].replace(/,/g, ""));
        normalizedRow[key] = isNaN(numericValue) ? normalizedRow[key] : numericValue;
      }
    }
    return normalizedRow;
  });
}

export const getCompanyData = async (row, tenantId) => {
  try {
    const accountId = row["Account ID"];
    const companyName = row["Company Name"];
    let company = null;

    if (accountId) {
      console.log("Searching By ID...", accountId) 
      const accountIdResult = await API.graphql(
        graphqlOperation(companiesByAccountID, {
          accountID: String(accountId), 
          filter: {
            tenantId: { eq: tenantId },
          },
        })
      );

      
      const accountIdItems = accountIdResult.data.companiesByAccountID.items;
      if (accountIdItems.length > 0) {
        company = accountIdItems[0];
      }
    }

    if (!company && companyName) {
      console.log("Searching By Name...")
      let nextToken = null;
      do {
        const nameResult = await API.graphql(
          graphqlOperation(companiesByName, {
            name: companyName,
            filter: {
              tenantId: { eq: tenantId },
            },
            limit: 1,
            nextToken: nextToken,
          })
        );

        const nameItems = nameResult.data.companiesByName.items;
        nextToken = nameResult.data.companiesByName.nextToken;

        if (nameItems.length > 0) {
          company = nameItems[0];
          break;
        }
      } while (nextToken);
    }

    return company;
  } catch (error) {
    console.error("Error fetching company data:", error);
    return null;
  }
};

const listCompaniesQuery = `
query ListCompanies(
  $tenantId: ID
  $id: ModelIDKeyConditionInput
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCompanies(
    tenantId: $tenantId
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      tenantId
      id
      name
      country
    }
    nextToken
    __typename
  }
}
`;

export const getCompaniesByCompanyName = async (companyName, tenantId) => {
  try {
    console.log('Searching companies with params:', { companyName, tenantId });
    
    if (!companyName || !tenantId) {
      throw new Error('Company name and tenant ID are required');
    }

    let nextToken = null;
    let allCompanies = [];

    do {
      console.log('Fetching companies from API...');
      const result = await API.graphql(
        graphqlOperation(listCompaniesQuery, {
          tenantId,
          nextToken,
          limit: 2000
        })
      );
      
      const companies = result?.data?.listCompanies?.items || [];
      nextToken = result?.data?.listCompanies?.nextToken;
      
      console.log('API response batch size:', companies.length);
      allCompanies = allCompanies.concat(companies);

    } while (nextToken);

    console.log('Total companies fetched:', allCompanies.length);
    
    const filteredCompanies = allCompanies.filter(company => {
      const matches = company.name.toLowerCase() === companyName.toLowerCase();
      return matches;
    });
    
    console.log('Filtered companies:', filteredCompanies);
    return filteredCompanies;

  } catch (error) {
    console.error('Error fetching companies by name:', error);
    throw new Error('Failed to fetch companies by name');
  }
};

export function processAgingData(Data, agingDays) {
  const agingData = {};
  Object.keys(Data).forEach(key => {
      if (key.startsWith('AR Balance Aging')) {
          const agingPeriod = key.replace('AR Balance Aging ', '');
          agingData[`Aging${agingPeriod}`] = Data[key] || 0;
      }
  });
  const preferences = [agingDays.age1, agingDays.age2, agingDays.age3];
  
  const numericPreferences = preferences.map(pref => 
      typeof pref === 'string' ? parseInt(pref.replace('+', '')) : pref
  ).sort((a, b) => a - b);

  const result = {};
  preferences.forEach(pref => result[pref] = 0);

  const numericAgingData = new Map();
  for (const [key, value] of Object.entries(agingData)) {
      let num = parseInt(key.slice(5).replace('+', ''));
      let isPlus = key.includes('+');  
      if (isPlus) {
          num += '+';  
      }
      numericAgingData.set(num, parseFloat(value) || 0);
  }

  const agingPeriods = Array.from(numericAgingData.keys()).sort((a, b) => {
      const isAPlus = typeof a === 'string' && a.includes('+');
      const isBPlus = typeof b === 'string' && b.includes('+');
      if (isAPlus && !isBPlus) return 1; 
      if (!isAPlus && isBPlus) return -1;
      return parseInt(a) - parseInt(b);  
  });

  agingPeriods.forEach(period => {
      let value = numericAgingData.get(period);
      let targetPref = null;

      if (typeof period === 'string' && period.includes('+')) {
          targetPref = preferences[preferences.length - 1];  
      } else {
          for (let i = 0; i < numericPreferences.length; i++) {
              if (period <= numericPreferences[i]) {
                  targetPref = preferences[i];  
                  break;
              }
          }
      }

      if (targetPref) {
          result[targetPref] += value;
      }
  });

  return result;
}

export const downloadFilesAsZip = async (filePaths, zipName) => {
  const zip = new JSZip();

  for (let i = 0; i < filePaths.length; i++) {
    const fileUrl = filePaths[i].path;
    const fileName = filePaths[i].name;
    try {
      const response = await fetch(fileUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      const blob = await response.blob();
      zip.file(fileName, blob);
    } catch (error) {
      toast.error(`Failed to download file: ${fileName}`);
      console.error(`Error downloading file: ${fileUrl}`, error);
    }
  }

  try {
    const content = await zip.generateAsync({ type: "blob" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    link.download = zipName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error generating ZIP file:", error);
    throw new Error(`Failed to generate ZIP file.`);
  }
};

export const fetchCompanySummary = async (tenantId, companyId) => {
  try {
    const response = await API.graphql(
      graphqlOperation(getCompanySummaryRecord, {
        tenantId,
        companyId,
      })
    );
    return JSON.parse(response.data.getCompanySummaryRecord.summaryData);
  } catch (error) {
    console.error("Error fetching company summary record:", error);
    throw new Error("Failed to fetch company summary record.");
  }
};

export const createCompanySummary = async (input) => {
  try {
    const result = await API.graphql(
      graphqlOperation(createCompanySummaryRecord, {
        input,
      })
    );
    return result;
  } catch (error) {
    console.error("Error creating company summary:", error);
    throw new Error("Failed to create company summary.");
  }
};

export const updateCompanySummary = async (input) => {
  try {
    const result = await API.graphql(
      graphqlOperation(updateCompanySummaryRecord, {
        input,
      })
    );
    return result;
  } catch (error) {
    console.error("Error updating company summary:", error);
    throw new Error("Failed to update company summary.");
  }
};

export const deleteCompanySummary = async (tenantId, companyId) => {
  try {
    const result = await API.graphql(
      graphqlOperation(deleteCompanySummaryRecord, {
        input: {
          tenantId,
          companyId,
        },
      })
    );
    return result;
  } catch (error) {
    console.error("Error deleting company summary:", error);
    throw new Error("Failed to delete company summary.");
  }
};

export const listCompanySummaries = async (tenantId) => {
  try {
    let nextToken = null;
    let allSummaryData = [];

    do {
      const result = await API.graphql(
        graphqlOperation(listCompanySummaryRecords, {
          tenantId: tenantId,
          nextToken: nextToken,
        })
      );
      console.log("Fetching Summary Record Batch...")
      allSummaryData = allSummaryData.concat(result.data.listCompanySummaryRecords.items);
      nextToken = result.data.listCompanySummaryRecords.nextToken;
    } while (nextToken);
    allSummaryData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    const finalSummaries = allSummaryData.map(summary => {
      return JSON.parse(summary.summaryData);
    });
    const normalisedData = normalizeRowData(finalSummaries);

    console.log("All Summaries:", normalisedData);
    return normalisedData;
  } catch (error) {
    console.error("Error listing company summaries:", error);
    throw new Error("Failed to list company summaries.");
  }
};

export const listCompanySummaryData = async (tenantId) => {
  console.time('listCompanySummaryData:total');
  try {
    let nextToken = null;
    let allSummaryData = [];

    console.time('listCompanySummaryData:fetching');
    do {
      console.time('listCompanySummaryData:batchFetch');
      const result = await API.graphql(
        graphqlOperation(listCompanySummaryRecords, {
          tenantId: tenantId,
          nextToken: nextToken,
          limit: 1000
        })
      );
      console.timeEnd('listCompanySummaryData:batchFetch');

      console.time('listCompanySummaryData:batchProcess');
      console.log("Fetching Summary Record Batch...", result.data.listCompanySummaryRecords.items.length);
      allSummaryData = allSummaryData.concat(result.data.listCompanySummaryRecords.items);
      nextToken = result.data.listCompanySummaryRecords.nextToken;
      console.timeEnd('listCompanySummaryData:batchProcess');
    } while (nextToken);
    console.timeEnd('listCompanySummaryData:fetching');

    console.time('listCompanySummaryData:sorting');
    allSummaryData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    console.timeEnd('listCompanySummaryData:sorting');

    console.log("All Summaries:", allSummaryData);
    console.timeEnd('listCompanySummaryData:total');
    return allSummaryData;
  } catch (error) {
    console.error("Error listing company summaries:", error);
    console.timeEnd('listCompanySummaryData:total');
    throw new Error("Failed to list company summaries.");
  }
};

export const MitigationOptions=[
  { key: "Financial Statement", value: "Financial Statement" },
  { key: "Comfort Letter", value: "Comfort Letter" },
  { key: "Parent Company Commitment and Insurance", value: "Parent Commitment" },
  { key: "Owners' guarantee", value: "Owners' guarantee" },
  { key: "Government guarantee", value: "Government guarantee" },
  { key: "Collateral", value: "Collateral" }
]

export const extractYearlyRevenue = (organization) => {
  const top = organization?.financials ?? [];
  const globalUltimate = organization?.globalUltimate?.financials ?? [];
  const domesticUltimate = organization?.domesticUltimate?.financials ?? [];

  const financialsList =
    top.length > 0 ? top : globalUltimate.concat(domesticUltimate);

  if (financialsList.length === 0) {
    return undefined;
  }

  let yearlyRevenue = 0;
  for (let i = 0; i < financialsList.length; i++) {
      for (let y = 0; y < financialsList[i].yearlyRevenue.length; y++) {
          const yearlyData = financialsList[i].yearlyRevenue[y];
          if (yearlyData.currency === "USD") {
              yearlyRevenue += yearlyData.value;
          }
      }
  }
  return yearlyRevenue;
};

export function extractNumberOfEmployees(organization) {
  if(organization){

    let employeesList = organization?.numberOfEmployees ?? organization?.globalUltimate?.numberOfEmployees;
    
    let employee = employeesList.find(emp => emp.informationScopeDescription === 'Consolidated');
    
    return employee?.value;
  }
}


export function parseCurrencyField(field) {
  console.log("Parsing Revenue:", field);

  if (!field) {
    return undefined;
  }

  const regex = /^[^\d]*(US\$|\$|US\s*\$?|USD)?\s*(\d+(\.\d+)?)[ ]*(million|billion|trillion)?[ ]*(\(?(FY\s*)?(\d{4})\)?)?/i;

  const match = field.match(regex);
  if (match) {
    const currency = match[1] ? match[1].replace(/\s+/g, '') : ""; // Normalize currency (USD, US$, US $, or $)
    let value = parseFloat(match[2]); // Extract numeric value
    const unit = match[4] || ""; // Extract unit (million, billion, trillion)
    const year = match[7] ? parseInt(match[7], 10) : null; // Extract year as number

      if (unit.toLowerCase() === "million") {
      value *= 1e6;
      } else if (unit.toLowerCase() === "billion") {
      value *= 1e9;
      } else if (unit.toLowerCase() === "trillion") {
      value *= 1e12;
    }

    if (field.toLowerCase().includes("minus")) {
      value = -value;
    }

    return {
      currency: currency || "", // Default to USD if no currency symbol is found
      value,
      year,
    };
  }
  return undefined;
}
export const parseWikiEmployee = (employeeString) => {
  if(!employeeString) {
    return undefined;
  }
  // Regular expression to match the employee number (digits and commas) before any parentheses
  const employeeMatch = employeeString.match(/^([\d,]+)/);
  
  if (employeeMatch) {
      // Remove commas from the matched employee number and convert to an integer
      const employeeCount = parseInt(employeeMatch[1].replace(/,/g, ''), 10);
      return employeeCount;
  }

  // If no employee count is found, return null
  return undefined;
}
// ----------------------------------------------------- ----------------------------------------------------- -----------------------------------------------------

export const getTotalSegmentedOnRNOrCN = (data, segment) => {
  const creditLimitTotals = {
    totalApprovedCL: 0,
    totalUsedCL: 0,
    totalApprovedCustomer: 0,
    avgApprovedLimit: 0,
    avgUsedLimit: 0,
    utilization: 0,

    // fields for segmentation based on Recommended Net
    totalRN30: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },

    totalRN45: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalRN60: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalRN90: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalRN120: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalRN180: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    other: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
  };

  data.forEach((item) => {
    creditLimitTotals.totalApprovedCL += item["Recommended Limit"];
    creditLimitTotals.totalUsedCL += item["Total AR Balance"];

    if (item["Recommended Limit"] > 0) {
      creditLimitTotals.totalApprovedCustomer += 1;
    }

    if (item[segment] === "Net 30") {
      creditLimitTotals.totalRN30.count += 1;
      creditLimitTotals.totalRN30.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.totalRN30.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRN30.totalApprovedCustomer += 1;
      }
    } else if (item[segment] === "Net 45") {
      creditLimitTotals.totalRN45.count += 1;
      creditLimitTotals.totalRN45.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.totalRN45.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRN45.totalApprovedCustomer += 1;
      }
    } else if (item[segment] === "Net 60") {
      creditLimitTotals.totalRN60.count += 1;
      creditLimitTotals.totalRN60.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.totalRN60.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRN60.totalApprovedCustomer += 1;
      }
    } else if (item[segment] === "Net 90") {
      creditLimitTotals.totalRN90.count += 1;
      creditLimitTotals.totalRN90.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.totalRN90.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRN90.totalApprovedCustomer += 1;
      }
    } else if (item[segment] === "Net 120") {
      creditLimitTotals.totalRN120.count += 1;
      creditLimitTotals.totalRN120.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.totalRN120.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRN120.totalApprovedCustomer += 1;
      }
    } else if (item[segment] === "Net 180") {
      creditLimitTotals.totalRN180.count += 1;
      creditLimitTotals.totalRN180.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.totalRN180.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRN180.totalApprovedCustomer += 1;
      }
    } else {
      creditLimitTotals.other.count += 1;
      creditLimitTotals.other.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.other.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.other.totalApprovedCustomer += 1;
      }
    }
  });

  creditLimitTotals.avgApprovedLimit =
    creditLimitTotals.totalApprovedCL / data.length;

  creditLimitTotals.totalRN30.avgApprovedLimit =
    creditLimitTotals.totalRN30.totalApprovedCL /
    creditLimitTotals.totalRN30.count;

  creditLimitTotals.totalRN45.avgApprovedLimit =
    creditLimitTotals.totalRN45.totalApprovedCL /
    creditLimitTotals.totalRN45.count;

  creditLimitTotals.totalRN60.avgApprovedLimit =
    creditLimitTotals.totalRN60.totalApprovedCL /
    creditLimitTotals.totalRN60.count;

  creditLimitTotals.totalRN90.avgApprovedLimit =
    creditLimitTotals.totalRN90.totalApprovedCL /
    creditLimitTotals.totalRN90.count;

  creditLimitTotals.totalRN120.avgApprovedLimit =
    creditLimitTotals.totalRN120.totalApprovedCL /
    creditLimitTotals.totalRN120.count;

  creditLimitTotals.totalRN180.avgApprovedLimit =
    creditLimitTotals.totalRN180.totalApprovedCL /
    creditLimitTotals.totalRN180.count;

  creditLimitTotals.other.avgApprovedLimit =
    creditLimitTotals.other.totalApprovedCL / creditLimitTotals.other.count;

  creditLimitTotals.avgUsedLimit = creditLimitTotals.totalUsedCL / data.length;

  creditLimitTotals.totalRN30.avgUsedLimit =
    creditLimitTotals.totalRN30.totalUsedCL / creditLimitTotals.totalRN30.count;

  creditLimitTotals.totalRN45.avgUsedLimit =
    creditLimitTotals.totalRN45.totalUsedCL / creditLimitTotals.totalRN45.count;

  creditLimitTotals.totalRN60.avgUsedLimit =
    creditLimitTotals.totalRN60.totalUsedCL / creditLimitTotals.totalRN60.count;

  creditLimitTotals.totalRN90.avgUsedLimit =
    creditLimitTotals.totalRN90.totalUsedCL / creditLimitTotals.totalRN90.count;

  creditLimitTotals.totalRN120.avgUsedLimit =
    creditLimitTotals.totalRN120.totalUsedCL /
    creditLimitTotals.totalRN120.count;

  creditLimitTotals.totalRN180.avgUsedLimit =
    creditLimitTotals.totalRN180.totalUsedCL /
    creditLimitTotals.totalRN180.count;

  creditLimitTotals.other.avgUsedLimit =
    creditLimitTotals.other.totalUsedCL / creditLimitTotals.other.count;

  creditLimitTotals.utilization =
    (creditLimitTotals.totalUsedCL / creditLimitTotals.totalApprovedCL) * 100;

  creditLimitTotals.totalRN30.utilization =
    (creditLimitTotals.totalRN30.totalUsedCL /
      creditLimitTotals.totalRN30.totalApprovedCL) *
    100;

  creditLimitTotals.totalRN45.utilization =
    (creditLimitTotals.totalRN45.totalUsedCL /
      creditLimitTotals.totalRN45.totalApprovedCL) *
    100;

  creditLimitTotals.totalRN60.utilization =
    (creditLimitTotals.totalRN60.totalUsedCL /
      creditLimitTotals.totalRN60.totalApprovedCL) *
    100;

  creditLimitTotals.totalRN90.utilization =
    (creditLimitTotals.totalRN90.totalUsedCL /
      creditLimitTotals.totalRN90.totalApprovedCL) *
    100;

  creditLimitTotals.totalRN120.utilization =
    (creditLimitTotals.totalRN120.totalUsedCL /
      creditLimitTotals.totalRN120.totalApprovedCL) *
    100;

  creditLimitTotals.totalRN180.utilization =
    (creditLimitTotals.totalRN180.totalUsedCL /
      creditLimitTotals.totalRN180.totalApprovedCL) *
    100;

  creditLimitTotals.other.utilization =
    (creditLimitTotals.other.totalUsedCL /
      creditLimitTotals.other.totalApprovedCL) *
    100;

  const rowData = [
    {
      id: 1,
      metric: "Total $ value of approved credit limit",
      total: formatNumber(creditLimitTotals.totalApprovedCL),
      totalRN30: formatNumber(creditLimitTotals.totalRN30.totalApprovedCL),
      totalRN45: formatNumber(creditLimitTotals.totalRN45.totalApprovedCL),
      totalRN60: formatNumber(creditLimitTotals.totalRN60.totalApprovedCL),
      totalRN90: formatNumber(creditLimitTotals.totalRN90.totalApprovedCL),
      totalRN120: formatNumber(creditLimitTotals.totalRN120.totalApprovedCL),
      totalRN180: formatNumber(creditLimitTotals.totalRN180.totalApprovedCL),
      other: formatNumber(creditLimitTotals.other.totalApprovedCL),
    },
    {
      id: 2,
      metric: "Total $ value of used credit limit",
      total: formatNumber(creditLimitTotals.totalUsedCL),
      totalRN30: formatNumber(creditLimitTotals.totalRN30.totalUsedCL),
      totalRN45: formatNumber(creditLimitTotals.totalRN45.totalUsedCL),
      totalRN60: formatNumber(creditLimitTotals.totalRN60.totalUsedCL),
      totalRN90: formatNumber(creditLimitTotals.totalRN90.totalUsedCL),
      totalRN120: formatNumber(creditLimitTotals.totalRN120.totalUsedCL),
      totalRN180: formatNumber(creditLimitTotals.totalRN180.totalUsedCL),
      other: formatNumber(creditLimitTotals.other.totalUsedCL),
    },
    {
      id: 3,
      metric: "Utilization %",
      total: formatNumber(creditLimitTotals.utilization),
      totalRN30: formatNumber(creditLimitTotals.totalRN30.utilization),
      totalRN45: formatNumber(creditLimitTotals.totalRN45.utilization),
      totalRN60: formatNumber(creditLimitTotals.totalRN60.utilization),
      totalRN90: formatNumber(creditLimitTotals.totalRN90.utilization),
      totalRN120: formatNumber(creditLimitTotals.totalRN120.utilization),
      totalRN180: formatNumber(creditLimitTotals.totalRN180.utilization),
      other: formatNumber(creditLimitTotals.other.utilization),
    },
    {
      id: 4,
      metric: "Number of Approved Customers",
      total: formatNumber(creditLimitTotals.totalApprovedCustomer),
      totalRN30: formatNumber(
        creditLimitTotals.totalRN30.totalApprovedCustomer
      ),
      totalRN45: formatNumber(
        creditLimitTotals.totalRN45.totalApprovedCustomer
      ),
      totalRN60: formatNumber(
        creditLimitTotals.totalRN60.totalApprovedCustomer
      ),
      totalRN90: formatNumber(
        creditLimitTotals.totalRN90.totalApprovedCustomer
      ),
      totalRN120: formatNumber(
        creditLimitTotals.totalRN120.totalApprovedCustomer
      ),
      totalRN180: formatNumber(
        creditLimitTotals.totalRN180.totalApprovedCustomer
      ),
      other: formatNumber(creditLimitTotals.other.totalApprovedCustomer),
    },
    {
      id: 5,
      metric: "Average Approved Limit",
      total: formatNumber(creditLimitTotals.avgApprovedLimit),
      totalRN30: formatNumber(creditLimitTotals.totalRN30.avgApprovedLimit),
      totalRN45: formatNumber(creditLimitTotals.totalRN45.avgApprovedLimit),
      totalRN60: formatNumber(creditLimitTotals.totalRN60.avgApprovedLimit),
      totalRN90: formatNumber(creditLimitTotals.totalRN90.avgApprovedLimit),
      totalRN120: formatNumber(creditLimitTotals.totalRN120.avgApprovedLimit),
      totalRN180: formatNumber(creditLimitTotals.totalRN180.avgApprovedLimit),
      other: formatNumber(creditLimitTotals.other.avgApprovedLimit),
    },
    {
      id: 6,
      metric: "Average Used Limit",
      total: formatNumber(creditLimitTotals.avgUsedLimit),
      totalRN30: formatNumber(creditLimitTotals.totalRN30.avgUsedLimit),
      totalRN45: formatNumber(creditLimitTotals.totalRN45.avgUsedLimit),
      totalRN60: formatNumber(creditLimitTotals.totalRN60.avgUsedLimit),
      totalRN90: formatNumber(creditLimitTotals.totalRN90.avgUsedLimit),
      totalRN120: formatNumber(creditLimitTotals.totalRN120.avgUsedLimit),
      totalRN180: formatNumber(creditLimitTotals.totalRN180.avgUsedLimit),
      other: formatNumber(creditLimitTotals.other.avgUsedLimit),
    },
  ];

  return rowData;
};

// Func to get total segmented upon - Alternative Score

export const getTotalSegmentedOnScore = (data, segment) => {
  const creditLimitTotals = {
    totalApprovedCL: 0,
    totalUsedCL: 0,
    totalApprovedCustomer: 0,
    avgApprovedLimit: 0,
    avgUsedLimit: 0,
    utilization: 0,

    // fields for segmentation based on Different Scores
    // LTE- Less Than Equal TO
    totalScoreLTE1: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },

    totalScoreLTE2: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalScoreLTE3: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalScoreLTE4: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalScoreLTE5: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalScoreLTE6: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalScoreLTE7: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalScoreGT7: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalOther: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
  };

  data.forEach((item) => {
    creditLimitTotals.totalApprovedCL += item["Recommended Limit"];
    creditLimitTotals.totalUsedCL += item["Total AR Balance"];

    if (item["Recommended Limit"] > 0) {
      creditLimitTotals.totalApprovedCustomer += 1;
    }

    if (item[segment] <= 1 && item[segment] > 0) {
      creditLimitTotals.totalScoreLTE1.count += 1;
      creditLimitTotals.totalScoreLTE1.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalScoreLTE1.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalScoreLTE1.totalApprovedCustomer += 1;
      }
    } else if (item[segment] <= 2 && item[segment] > 1) {
      creditLimitTotals.totalScoreLTE2.count += 1;
      creditLimitTotals.totalScoreLTE2.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalScoreLTE2.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalScoreLTE2.totalApprovedCustomer += 1;
      }
    } else if (item[segment] <= 3 && item[segment] > 2) {
      creditLimitTotals.totalScoreLTE3.count += 1;
      creditLimitTotals.totalScoreLTE3.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalScoreLTE3.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalScoreLTE3.totalApprovedCustomer += 1;
      }
    } else if (item[segment] <= 4 && item[segment] > 3) {
      creditLimitTotals.totalScoreLTE4.count += 1;
      creditLimitTotals.totalScoreLTE4.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalScoreLTE4.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalScoreLTE4.totalApprovedCustomer += 1;
      }
    } else if (item[segment] <= 5 && item[segment] > 4) {
      creditLimitTotals.totalScoreLTE5.count += 1;
      creditLimitTotals.totalScoreLTE5.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalScoreLTE5.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalScoreLTE5.totalApprovedCustomer += 1;
      }
    } else if (item[segment] <= 6 && item[segment] > 5) {
      creditLimitTotals.totalScoreLTE6.count += 1;
      creditLimitTotals.totalScoreLTE6.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalScoreLTE6.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalScoreLTE6.totalApprovedCustomer += 1;
      }
    } else if (item[segment] <= 7 && item[segment] > 6) {
      creditLimitTotals.totalScoreLTE7.count += 1;
      creditLimitTotals.totalScoreLTE7.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalScoreLTE7.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalScoreLTE7.totalApprovedCustomer += 1;
      }
    } else if (item[segment] > 7) {
      creditLimitTotals.totalScoreGT7.count += 1;
      creditLimitTotals.totalScoreGT7.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalScoreGT7.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalScoreGT7.totalApprovedCustomer += 1;
      }
    } else {
      creditLimitTotals.totalOther.count += 1;
      creditLimitTotals.totalOther.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.totalOther.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalOther.totalApprovedCustomer += 1;
      }
    }
  });

  creditLimitTotals.avgApprovedLimit =
    creditLimitTotals.totalApprovedCL / data.length;

  creditLimitTotals.totalScoreLTE1.avgApprovedLimit =
    creditLimitTotals.totalScoreLTE1.totalApprovedCL /
    creditLimitTotals.totalScoreLTE1.count;

  creditLimitTotals.totalScoreLTE2.avgApprovedLimit =
    creditLimitTotals.totalScoreLTE2.totalApprovedCL /
    creditLimitTotals.totalScoreLTE2.count;

  creditLimitTotals.totalScoreLTE3.avgApprovedLimit =
    creditLimitTotals.totalScoreLTE3.totalApprovedCL /
    creditLimitTotals.totalScoreLTE3.count;

  creditLimitTotals.totalScoreLTE4.avgApprovedLimit =
    creditLimitTotals.totalScoreLTE4.totalApprovedCL /
    creditLimitTotals.totalScoreLTE4.count;

  creditLimitTotals.totalScoreLTE5.avgApprovedLimit =
    creditLimitTotals.totalScoreLTE5.totalApprovedCL /
    creditLimitTotals.totalScoreLTE5.count;

  creditLimitTotals.totalScoreLTE6.avgApprovedLimit =
    creditLimitTotals.totalScoreLTE6.totalApprovedCL /
    creditLimitTotals.totalScoreLTE6.count;

  creditLimitTotals.totalScoreLTE7.avgApprovedLimit =
    creditLimitTotals.totalScoreLTE7.totalApprovedCL /
    creditLimitTotals.totalScoreLTE7.count;

  creditLimitTotals.totalScoreGT7.avgApprovedLimit =
    creditLimitTotals.totalScoreGT7.totalApprovedCL /
    creditLimitTotals.totalScoreGT7.count;

  creditLimitTotals.totalOther.avgApprovedLimit =
    creditLimitTotals.totalOther.totalApprovedCL /
    creditLimitTotals.totalOther.count;

  creditLimitTotals.avgUsedLimit = creditLimitTotals.totalUsedCL / data.length;

  creditLimitTotals.totalScoreLTE1.avgUsedLimit =
    creditLimitTotals.totalScoreLTE1.totalUsedCL /
    creditLimitTotals.totalScoreLTE1.count;

  creditLimitTotals.totalScoreLTE2.avgUsedLimit =
    creditLimitTotals.totalScoreLTE2.totalUsedCL /
    creditLimitTotals.totalScoreLTE2.count;

  creditLimitTotals.totalScoreLTE3.avgUsedLimit =
    creditLimitTotals.totalScoreLTE3.totalUsedCL /
    creditLimitTotals.totalScoreLTE3.count;

  creditLimitTotals.totalScoreLTE4.avgUsedLimit =
    creditLimitTotals.totalScoreLTE4.totalUsedCL /
    creditLimitTotals.totalScoreLTE4.count;

  creditLimitTotals.totalScoreLTE5.avgUsedLimit =
    creditLimitTotals.totalScoreLTE5.totalUsedCL /
    creditLimitTotals.totalScoreLTE5.count;

  creditLimitTotals.totalScoreLTE6.avgUsedLimit =
    creditLimitTotals.totalScoreLTE6.totalUsedCL /
    creditLimitTotals.totalScoreLTE6.count;

  creditLimitTotals.totalScoreLTE7.avgUsedLimit =
    creditLimitTotals.totalScoreLTE7.totalUsedCL /
    creditLimitTotals.totalScoreLTE7.count;

  creditLimitTotals.totalScoreGT7.avgUsedLimit =
    creditLimitTotals.totalScoreGT7.totalUsedCL /
    creditLimitTotals.totalScoreGT7.count;

  creditLimitTotals.totalOther.avgUsedLimit =
    creditLimitTotals.totalOther.totalUsedCL /
    creditLimitTotals.totalOther.count;

  creditLimitTotals.utilization =
    (creditLimitTotals.totalUsedCL / creditLimitTotals.totalApprovedCL) * 100;

  creditLimitTotals.totalScoreLTE1.utilization =
    (creditLimitTotals.totalScoreLTE1.totalUsedCL /
      creditLimitTotals.totalScoreLTE1.totalApprovedCL) *
    100;

  creditLimitTotals.totalScoreLTE2.utilization =
    (creditLimitTotals.totalScoreLTE2.totalUsedCL /
      creditLimitTotals.totalScoreLTE2.totalApprovedCL) *
    100;

  creditLimitTotals.totalScoreLTE3.utilization =
    (creditLimitTotals.totalScoreLTE3.totalUsedCL /
      creditLimitTotals.totalScoreLTE3.totalApprovedCL) *
    100;

  creditLimitTotals.totalScoreLTE4.utilization =
    (creditLimitTotals.totalScoreLTE4.totalUsedCL /
      creditLimitTotals.totalScoreLTE4.totalApprovedCL) *
    100;

  creditLimitTotals.totalScoreLTE5.utilization =
    (creditLimitTotals.totalScoreLTE5.totalUsedCL /
      creditLimitTotals.totalScoreLTE5.totalApprovedCL) *
    100;

  creditLimitTotals.totalScoreLTE6.utilization =
    (creditLimitTotals.totalScoreLTE6.totalUsedCL /
      creditLimitTotals.totalScoreLTE6.totalApprovedCL) *
    100;

  creditLimitTotals.totalScoreLTE7.utilization =
    (creditLimitTotals.totalScoreLTE7.totalUsedCL /
      creditLimitTotals.totalScoreLTE7.totalApprovedCL) *
    100;

  creditLimitTotals.totalScoreGT7.utilization =
    (creditLimitTotals.totalScoreGT7.totalUsedCL /
      creditLimitTotals.totalScoreGT7.totalApprovedCL) *
    100;

  creditLimitTotals.totalOther.utilization =
    (creditLimitTotals.totalOther.totalUsedCL /
      creditLimitTotals.totalOther.totalApprovedCL) *
    100;

  const rowDataCreditLimit = [
    {
      id: 1,
      metric: "Total $ value of approved credit limit",
      total: formatNumber(creditLimitTotals.totalApprovedCL),
      totalScoreLTE1: formatNumber(
        creditLimitTotals.totalScoreLTE1.totalApprovedCL
      ),
      totalScoreLTE2: formatNumber(
        creditLimitTotals.totalScoreLTE2.totalApprovedCL
      ),
      totalScoreLTE3: formatNumber(
        creditLimitTotals.totalScoreLTE3.totalApprovedCL
      ),
      totalScoreLTE4: formatNumber(
        creditLimitTotals.totalScoreLTE4.totalApprovedCL
      ),
      totalScoreLTE5: formatNumber(
        creditLimitTotals.totalScoreLTE5.totalApprovedCL
      ),
      totalScoreLTE6: formatNumber(
        creditLimitTotals.totalScoreLTE6.totalApprovedCL
      ),
      totalScoreLTE7: formatNumber(
        creditLimitTotals.totalScoreLTE7.totalApprovedCL
      ),
      totalScoreGT7: formatNumber(
        creditLimitTotals.totalScoreGT7.totalApprovedCL
      ),
      totalOther: formatNumber(creditLimitTotals.totalOther.totalApprovedCL),
    },
    {
      id: 2,
      metric: "Total $ value of used credit limit",
      total: formatNumber(creditLimitTotals.totalUsedCL),
      totalScoreLTE1: formatNumber(
        creditLimitTotals.totalScoreLTE1.totalUsedCL
      ),
      totalScoreLTE2: formatNumber(
        creditLimitTotals.totalScoreLTE2.totalUsedCL
      ),
      totalScoreLTE3: formatNumber(
        creditLimitTotals.totalScoreLTE3.totalUsedCL
      ),
      totalScoreLTE4: formatNumber(
        creditLimitTotals.totalScoreLTE4.totalUsedCL
      ),
      totalScoreLTE5: formatNumber(
        creditLimitTotals.totalScoreLTE5.totalUsedCL
      ),
      totalScoreLTE6: formatNumber(
        creditLimitTotals.totalScoreLTE6.totalUsedCL
      ),
      totalScoreLTE7: formatNumber(
        creditLimitTotals.totalScoreLTE7.totalUsedCL
      ),
      totalScoreGT7: formatNumber(creditLimitTotals.totalScoreGT7.totalUsedCL),
      totalOther: formatNumber(creditLimitTotals.totalOther.totalUsedCL),
    },
    {
      id: 3,
      metric: "Utilization %",
      total: formatNumber(creditLimitTotals.utilization),
      totalScoreLTE1: formatNumber(
        creditLimitTotals.totalScoreLTE1.utilization
      ),
      totalScoreLTE2: formatNumber(
        creditLimitTotals.totalScoreLTE2.utilization
      ),
      totalScoreLTE3: formatNumber(
        creditLimitTotals.totalScoreLTE3.utilization
      ),
      totalScoreLTE4: formatNumber(
        creditLimitTotals.totalScoreLTE4.utilization
      ),
      totalScoreLTE5: formatNumber(
        creditLimitTotals.totalScoreLTE5.utilization
      ),
      totalScoreLTE6: formatNumber(
        creditLimitTotals.totalScoreLTE6.utilization
      ),
      totalScoreLTE7: formatNumber(
        creditLimitTotals.totalScoreLTE7.utilization
      ),
      totalScoreGT7: formatNumber(creditLimitTotals.totalScoreGT7.utilization),
      totalOther: formatNumber(creditLimitTotals.totalOther.utilization),
    },
    {
      id: 4,
      metric: "Number of Approved Customers",
      total: formatNumber(creditLimitTotals.totalApprovedCustomer),
      totalScoreLTE1: formatNumber(
        creditLimitTotals.totalScoreLTE1.totalApprovedCustomer
      ),
      totalScoreLTE2: formatNumber(
        creditLimitTotals.totalScoreLTE2.totalApprovedCustomer
      ),
      totalScoreLTE3: formatNumber(
        creditLimitTotals.totalScoreLTE3.totalApprovedCustomer
      ),
      totalScoreLTE4: formatNumber(
        creditLimitTotals.totalScoreLTE4.totalApprovedCustomer
      ),
      totalScoreLTE5: formatNumber(
        creditLimitTotals.totalScoreLTE5.totalApprovedCustomer
      ),
      totalScoreLTE6: formatNumber(
        creditLimitTotals.totalScoreLTE6.totalApprovedCustomer
      ),
      totalScoreLTE7: formatNumber(
        creditLimitTotals.totalScoreLTE7.totalApprovedCustomer
      ),
      totalScoreGT7: formatNumber(
        creditLimitTotals.totalScoreGT7.totalApprovedCustomer
      ),
      totalOther: formatNumber(
        creditLimitTotals.totalOther.totalApprovedCustomer
      ),
    },
    {
      id: 5,
      metric: "Average Approved Limit",
      total: formatNumber(creditLimitTotals.avgApprovedLimit),
      totalScoreLTE1: formatNumber(
        creditLimitTotals.totalScoreLTE1.avgApprovedLimit
      ),
      totalScoreLTE2: formatNumber(
        creditLimitTotals.totalScoreLTE2.avgApprovedLimit
      ),
      totalScoreLTE3: formatNumber(
        creditLimitTotals.totalScoreLTE3.avgApprovedLimit
      ),
      totalScoreLTE4: formatNumber(
        creditLimitTotals.totalScoreLTE4.avgApprovedLimit
      ),
      totalScoreLTE5: formatNumber(
        creditLimitTotals.totalScoreLTE5.avgApprovedLimit
      ),
      totalScoreLTE6: formatNumber(
        creditLimitTotals.totalScoreLTE6.avgApprovedLimit
      ),
      totalScoreLTE7: formatNumber(
        creditLimitTotals.totalScoreLTE7.avgApprovedLimit
      ),
      totalScoreGT7: formatNumber(
        creditLimitTotals.totalScoreGT7.avgApprovedLimit
      ),
      totalOther: formatNumber(creditLimitTotals.totalOther.avgApprovedLimit),
    },
    {
      id: 6,
      metric: "Average Used Limit",
      total: formatNumber(creditLimitTotals.avgUsedLimit),
      totalScoreLTE1: formatNumber(
        creditLimitTotals.totalScoreLTE1.avgUsedLimit
      ),
      totalScoreLTE2: formatNumber(
        creditLimitTotals.totalScoreLTE2.avgUsedLimit
      ),
      totalScoreLTE3: formatNumber(
        creditLimitTotals.totalScoreLTE3.avgUsedLimit
      ),
      totalScoreLTE4: formatNumber(
        creditLimitTotals.totalScoreLTE4.avgUsedLimit
      ),
      totalScoreLTE5: formatNumber(
        creditLimitTotals.totalScoreLTE5.avgUsedLimit
      ),
      totalScoreLTE6: formatNumber(
        creditLimitTotals.totalScoreLTE6.avgUsedLimit
      ),
      totalScoreLTE7: formatNumber(
        creditLimitTotals.totalScoreLTE7.avgUsedLimit
      ),
      totalScoreGT7: formatNumber(creditLimitTotals.totalScoreGT7.avgUsedLimit),
      totalOther: formatNumber(creditLimitTotals.totalOther.avgUsedLimit),
    },
  ];

  return rowDataCreditLimit;
};

export const getTotalSegmentedOnUnderwriting = (data) => {
  const creditLimitTotals = {
    totalApprovedCL: 0,
    totalUsedCL: 0,
    totalApprovedCustomer: 0,
    avgApprovedLimit: 0,
    avgUsedLimit: 0,
    utilization: 0,

    // fields for segmentation based on Underwriting
    totalManual: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },

    totalRejected: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalApproved: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    other: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
  };

  data.forEach((item) => {
    creditLimitTotals.totalApprovedCL += item["Recommended Limit"];
    creditLimitTotals.totalUsedCL += item["Total AR Balance"];

    if (item["Recommended Limit"] > 0) {
      creditLimitTotals.totalApprovedCustomer += 1;
    }

    if (item["Underwriting"] === "Manual") {
      creditLimitTotals.totalManual.count += 1;
      creditLimitTotals.totalManual.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalManual.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalManual.totalApprovedCustomer += 1;
      }
    } else if (item["Underwriting"] === "Rejected") {
      creditLimitTotals.totalRejected.count += 1;
      creditLimitTotals.totalRejected.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalRejected.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRejected.totalApprovedCustomer += 1;
      }
    } else if (item["Underwriting"] === "Approved") {
      creditLimitTotals.totalApproved.count += 1;
      creditLimitTotals.totalApproved.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalApproved.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalApproved.totalApprovedCustomer += 1;
      }
    } else {
      creditLimitTotals.other.count += 1;
      creditLimitTotals.other.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.other.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.other.totalApprovedCustomer += 1;
      }
    }
  });

  creditLimitTotals.avgApprovedLimit =
    creditLimitTotals.totalApprovedCL / data.length;

  creditLimitTotals.totalManual.avgApprovedLimit =
    creditLimitTotals.totalManual.totalApprovedCL /
    creditLimitTotals.totalManual.count;

  creditLimitTotals.totalRejected.avgApprovedLimit =
    creditLimitTotals.totalRejected.totalApprovedCL /
    creditLimitTotals.totalRejected.count;

  creditLimitTotals.totalApproved.avgApprovedLimit =
    creditLimitTotals.totalApproved.totalApprovedCL /
    creditLimitTotals.totalApproved.count;

  creditLimitTotals.other.avgApprovedLimit =
    creditLimitTotals.other.totalApprovedCL / creditLimitTotals.other.count;

  creditLimitTotals.avgUsedLimit = creditLimitTotals.totalUsedCL / data.length;

  creditLimitTotals.totalManual.avgUsedLimit =
    creditLimitTotals.totalManual.totalUsedCL /
    creditLimitTotals.totalManual.count;

  creditLimitTotals.totalRejected.avgUsedLimit =
    creditLimitTotals.totalRejected.totalUsedCL /
    creditLimitTotals.totalRejected.count;

  creditLimitTotals.totalApproved.avgUsedLimit =
    creditLimitTotals.totalApproved.totalUsedCL /
    creditLimitTotals.totalApproved.count;

  creditLimitTotals.other.avgUsedLimit =
    creditLimitTotals.other.totalUsedCL / creditLimitTotals.other.count;

  creditLimitTotals.utilization =
    (creditLimitTotals.totalUsedCL / creditLimitTotals.totalApprovedCL) * 100;

  creditLimitTotals.totalManual.utilization =
    (creditLimitTotals.totalManual.totalUsedCL /
      creditLimitTotals.totalManual.totalApprovedCL) *
    100;

  creditLimitTotals.totalRejected.utilization =
    (creditLimitTotals.totalRejected.totalUsedCL /
      creditLimitTotals.totalRejected.totalApprovedCL) *
    100;

  creditLimitTotals.totalApproved.utilization =
    (creditLimitTotals.totalApproved.totalUsedCL /
      creditLimitTotals.totalApproved.totalApprovedCL) *
    100;

  creditLimitTotals.other.utilization =
    (creditLimitTotals.other.totalUsedCL /
      creditLimitTotals.other.totalApprovedCL) *
    100;

  const rowData = [
    {
      id: 1,
      metric: "Total $ value of approved credit limit",
      total: formatNumber(creditLimitTotals.totalApprovedCL),
      manual: formatNumber(creditLimitTotals.totalManual.totalApprovedCL),
      rejected: formatNumber(creditLimitTotals.totalRejected.totalApprovedCL),
      approved: formatNumber(creditLimitTotals.totalApproved.totalApprovedCL),
      other: formatNumber(creditLimitTotals.other.totalApprovedCL),
    },
    {
      id: 2,
      metric: "Total $ value of used credit limit",
      total: formatNumber(creditLimitTotals.totalUsedCL),
      manual: formatNumber(creditLimitTotals.totalManual.totalUsedCL),
      rejected: formatNumber(creditLimitTotals.totalRejected.totalUsedCL),
      approved: formatNumber(creditLimitTotals.totalApproved.totalUsedCL),
      other: formatNumber(creditLimitTotals.other.totalUsedCL),
    },
    {
      id: 3,
      metric: "Utilization %",
      total: formatNumber(creditLimitTotals.utilization),
      manual: formatNumber(creditLimitTotals.totalManual.utilization),
      rejected: formatNumber(creditLimitTotals.totalRejected.utilization),
      approved: formatNumber(creditLimitTotals.totalApproved.utilization),
      other: formatNumber(creditLimitTotals.other.utilization),
    },
    {
      id: 4,
      metric: "Number of Approved Customers",
      total: formatNumber(creditLimitTotals.totalApprovedCustomer),
      manual: formatNumber(creditLimitTotals.totalManual.totalApprovedCustomer),
      rejected: formatNumber(
        creditLimitTotals.totalRejected.totalApprovedCustomer
      ),
      approved: formatNumber(
        creditLimitTotals.totalApproved.totalApprovedCustomer
      ),
      other: formatNumber(creditLimitTotals.other.totalApprovedCustomer),
    },
    {
      id: 5,
      metric: "Average Approved Limit",
      total: formatNumber(creditLimitTotals.avgApprovedLimit),
      manual: formatNumber(creditLimitTotals.totalManual.avgApprovedLimit),
      rejected: formatNumber(creditLimitTotals.totalRejected.avgApprovedLimit),
      approved: formatNumber(creditLimitTotals.totalApproved.avgApprovedLimit),
      other: formatNumber(creditLimitTotals.other.avgApprovedLimit),
    },
    {
      id: 6,
      metric: "Average Used Limit",
      total: formatNumber(creditLimitTotals.avgUsedLimit),
      manual: formatNumber(creditLimitTotals.totalManual.avgUsedLimit),
      rejected: formatNumber(creditLimitTotals.totalRejected.avgUsedLimit),
      approved: formatNumber(creditLimitTotals.totalApproved.avgUsedLimit),
      other: formatNumber(creditLimitTotals.other.avgUsedLimit),
    },
  ];

  return rowData;
};

export const getTotalSegmentedOnRecommendedLimit = (data) => {
  const creditLimitTotals = {
    totalApprovedCL: 0,
    totalUsedCL: 0,
    totalApprovedCustomer: 0,
    avgApprovedLimit: 0,
    avgUsedLimit: 0,
    utilization: 0,

    // fields for segmentation based on Alternative Score
    totalRL0: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },

    totalRL50k: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalRL100k: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalRL150k: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalRL200k: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalRL250k: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalRL300k: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
  };

  data.forEach((item) => {
    creditLimitTotals.totalApprovedCL += item["Recommended Limit"];
    creditLimitTotals.totalUsedCL += item["Total AR Balance"];

    if (item["Recommended Limit"] > 0) {
      creditLimitTotals.totalApprovedCustomer += 1;
    }

    if (item["Recommended Limit"] < 50000 && item["Recommended Limit"] >= 0) {
      creditLimitTotals.totalRL0.count += 1;
      creditLimitTotals.totalRL0.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.totalRL0.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRL0.totalApprovedCustomer += 1;
      }
    }

    if (
      item["Recommended Limit"] < 100000 &&
      item["Recommended Limit"] >= 50000
    ) {
      creditLimitTotals.totalRL50k.count += 1;
      creditLimitTotals.totalRL50k.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.totalRL50k.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRL50k.totalApprovedCustomer += 1;
      }
    }

    if (
      item["Recommended Limit"] < 150000 &&
      item["Recommended Limit"] >= 100000
    ) {
      creditLimitTotals.totalRL100k.count += 1;
      creditLimitTotals.totalRL100k.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalRL100k.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRL100k.totalApprovedCustomer += 1;
      }
    }

    if (
      item["Recommended Limit"] < 200000 &&
      item["Recommended Limit"] >= 150000
    ) {
      creditLimitTotals.totalRL150k.count += 1;
      creditLimitTotals.totalRL150k.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalRL150k.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRL150k.totalApprovedCustomer += 1;
      }
    }

    if (
      item["Recommended Limit"] < 250000 &&
      item["Recommended Limit"] >= 200000
    ) {
      creditLimitTotals.totalRL200k.count += 1;
      creditLimitTotals.totalRL200k.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalRL200k.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRL200k.totalApprovedCustomer += 1;
      }
    }

    if (
      item["Recommended Limit"] < 300000 &&
      item["Recommended Limit"] >= 250000
    ) {
      creditLimitTotals.totalRL250k.count += 1;
      creditLimitTotals.totalRL250k.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalRL250k.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRL250k.totalApprovedCustomer += 1;
      }
    }

    if (item["Recommended Limit"] >= 300000) {
      creditLimitTotals.totalRL300k.count += 1;
      creditLimitTotals.totalRL300k.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalRL300k.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRL300k.totalApprovedCustomer += 1;
      }
    }
  });

  creditLimitTotals.avgApprovedLimit =
    creditLimitTotals.totalApprovedCL / data.length;

  creditLimitTotals.totalRL0.avgApprovedLimit =
    creditLimitTotals.totalRL0.totalApprovedCL /
    creditLimitTotals.totalRL0.count;

  creditLimitTotals.totalRL50k.avgApprovedLimit =
    creditLimitTotals.totalRL50k.totalApprovedCL /
    creditLimitTotals.totalRL50k.count;

  creditLimitTotals.totalRL100k.avgApprovedLimit =
    creditLimitTotals.totalRL100k.totalApprovedCL /
    creditLimitTotals.totalRL100k.count;

  creditLimitTotals.totalRL150k.avgApprovedLimit =
    creditLimitTotals.totalRL150k.totalApprovedCL /
    creditLimitTotals.totalRL150k.count;

  creditLimitTotals.totalRL200k.avgApprovedLimit =
    creditLimitTotals.totalRL200k.totalApprovedCL /
    creditLimitTotals.totalRL200k.count;

  creditLimitTotals.totalRL250k.avgApprovedLimit =
    creditLimitTotals.totalRL250k.totalApprovedCL /
    creditLimitTotals.totalRL250k.count;

  creditLimitTotals.totalRL300k.avgApprovedLimit =
    creditLimitTotals.totalRL300k.totalApprovedCL /
    creditLimitTotals.totalRL300k.count;
  //
  creditLimitTotals.avgUsedLimit = creditLimitTotals.totalUsedCL / data.length;

  creditLimitTotals.totalRL0.avgUsedLimit =
    creditLimitTotals.totalRL0.totalUsedCL / creditLimitTotals.totalRL0.count;

  creditLimitTotals.totalRL50k.avgUsedLimit =
    creditLimitTotals.totalRL50k.totalUsedCL /
    creditLimitTotals.totalRL50k.count;

  creditLimitTotals.totalRL100k.avgUsedLimit =
    creditLimitTotals.totalRL100k.totalUsedCL /
    creditLimitTotals.totalRL100k.count;

  creditLimitTotals.totalRL150k.avgUsedLimit =
    creditLimitTotals.totalRL150k.totalUsedCL /
    creditLimitTotals.totalRL150k.count;

  creditLimitTotals.totalRL200k.avgUsedLimit =
    creditLimitTotals.totalRL200k.totalUsedCL /
    creditLimitTotals.totalRL200k.count;

  creditLimitTotals.totalRL250k.avgUsedLimit =
    creditLimitTotals.totalRL250k.totalUsedCL /
    creditLimitTotals.totalRL250k.count;

  creditLimitTotals.totalRL300k.avgUsedLimit =
    creditLimitTotals.totalRL300k.totalUsedCL /
    creditLimitTotals.totalRL300k.count;
  //
  creditLimitTotals.utilization =
    (creditLimitTotals.totalUsedCL / creditLimitTotals.totalApprovedCL) * 100;

  creditLimitTotals.totalRL0.utilization =
    (creditLimitTotals.totalRL0.totalUsedCL /
      creditLimitTotals.totalRL0.totalApprovedCL) *
    100;

  creditLimitTotals.totalRL50k.utilization =
    (creditLimitTotals.totalRL50k.totalUsedCL /
      creditLimitTotals.totalRL50k.totalApprovedCL) *
    100;

  creditLimitTotals.totalRL100k.utilization =
    (creditLimitTotals.totalRL100k.totalUsedCL /
      creditLimitTotals.totalRL100k.totalApprovedCL) *
    100;

  creditLimitTotals.totalRL150k.utilization =
    (creditLimitTotals.totalRL150k.totalUsedCL /
      creditLimitTotals.totalRL150k.totalApprovedCL) *
    100;

  creditLimitTotals.totalRL200k.utilization =
    (creditLimitTotals.totalRL200k.totalUsedCL /
      creditLimitTotals.totalRL200k.totalApprovedCL) *
    100;

  creditLimitTotals.totalRL250k.utilization =
    (creditLimitTotals.totalRL250k.totalUsedCL /
      creditLimitTotals.totalRL250k.totalApprovedCL) *
    100;

  creditLimitTotals.totalRL300k.utilization =
    (creditLimitTotals.totalRL300k.totalUsedCL /
      creditLimitTotals.totalRL300k.totalApprovedCL) *
    100;

  const rowDataCreditLimit = [
    {
      id: 1,
      metric: "Total $ value of approved credit limit",
      total: formatNumber(creditLimitTotals.totalApprovedCL),
      totalRL0: formatNumber(creditLimitTotals.totalRL0.totalApprovedCL),
      totalRL50k: formatNumber(creditLimitTotals.totalRL50k.totalApprovedCL),
      totalRL100k: formatNumber(creditLimitTotals.totalRL100k.totalApprovedCL),
      totalRL150k: formatNumber(creditLimitTotals.totalRL150k.totalApprovedCL),
      totalRL200k: formatNumber(creditLimitTotals.totalRL200k.totalApprovedCL),
      totalRL250k: formatNumber(creditLimitTotals.totalRL250k.totalApprovedCL),
      totalRL300k: formatNumber(creditLimitTotals.totalRL300k.totalApprovedCL),
    },
    {
      id: 2,
      metric: "Total $ value of used credit limit",
      total: formatNumber(creditLimitTotals.totalUsedCL),
      totalRL0: formatNumber(creditLimitTotals.totalRL0.totalUsedCL),
      totalRL50k: formatNumber(creditLimitTotals.totalRL50k.totalUsedCL),
      totalRL100k: formatNumber(creditLimitTotals.totalRL100k.totalUsedCL),
      totalRL150k: formatNumber(creditLimitTotals.totalRL150k.totalUsedCL),
      totalRL200k: formatNumber(creditLimitTotals.totalRL200k.totalUsedCL),
      totalRL250k: formatNumber(creditLimitTotals.totalRL250k.totalUsedCL),
      totalRL300k: formatNumber(creditLimitTotals.totalRL300k.totalUsedCL),
    },
    {
      id: 3,
      metric: "Utilization %",
      total: formatNumber(creditLimitTotals.utilization),
      totalRL0: formatNumber(creditLimitTotals.totalRL0.utilization),
      totalRL50k: formatNumber(creditLimitTotals.totalRL50k.utilization),
      totalRL100k: formatNumber(creditLimitTotals.totalRL100k.utilization),
      totalRL150k: formatNumber(creditLimitTotals.totalRL150k.utilization),
      totalRL200k: formatNumber(creditLimitTotals.totalRL200k.utilization),
      totalRL250k: formatNumber(creditLimitTotals.totalRL250k.utilization),
      totalRL300k: formatNumber(creditLimitTotals.totalRL300k.utilization),
    },
    {
      id: 4,
      metric: "Number of Approved Customers",
      total: formatNumber(creditLimitTotals.totalApprovedCustomer),
      totalRL0: formatNumber(creditLimitTotals.totalRL0.totalApprovedCustomer),
      totalRL50k: formatNumber(
        creditLimitTotals.totalRL50k.totalApprovedCustomer
      ),
      totalRL100k: formatNumber(
        creditLimitTotals.totalRL100k.totalApprovedCustomer
      ),
      totalRL150k: formatNumber(
        creditLimitTotals.totalRL150k.totalApprovedCustomer
      ),
      totalRL200k: formatNumber(
        creditLimitTotals.totalRL200k.totalApprovedCustomer
      ),
      totalRL250k: formatNumber(
        creditLimitTotals.totalRL250k.totalApprovedCustomer
      ),
      totalRL300k: formatNumber(
        creditLimitTotals.totalRL300k.totalApprovedCustomer
      ),
    },
    {
      id: 5,
      metric: "Average Approved Limit",
      total: formatNumber(creditLimitTotals.avgApprovedLimit),
      totalRL0: formatNumber(creditLimitTotals.totalRL0.avgApprovedLimit),
      totalRL50k: formatNumber(creditLimitTotals.totalRL50k.avgApprovedLimit),
      totalRL100k: formatNumber(creditLimitTotals.totalRL100k.avgApprovedLimit),
      totalRL150k: formatNumber(creditLimitTotals.totalRL150k.avgApprovedLimit),
      totalRL200k: formatNumber(creditLimitTotals.totalRL200k.avgApprovedLimit),
      totalRL250k: formatNumber(creditLimitTotals.totalRL250k.avgApprovedLimit),
      totalRL300k: formatNumber(creditLimitTotals.totalRL300k.avgApprovedLimit),
    },
    {
      id: 6,
      metric: "Average Used Limit",
      total: formatNumber(creditLimitTotals.avgUsedLimit),
      totalRL0: formatNumber(creditLimitTotals.totalRL0.avgUsedLimit),
      totalRL50k: formatNumber(creditLimitTotals.totalRL50k.avgUsedLimit),
      totalRL100k: formatNumber(creditLimitTotals.totalRL100k.avgUsedLimit),
      totalRL150k: formatNumber(creditLimitTotals.totalRL150k.avgUsedLimit),
      totalRL200k: formatNumber(creditLimitTotals.totalRL200k.avgUsedLimit),
      totalRL250k: formatNumber(creditLimitTotals.totalRL250k.avgUsedLimit),
      totalRL300k: formatNumber(creditLimitTotals.totalRL300k.avgUsedLimit),
    },
  ];

  return rowDataCreditLimit;
};

export const getTotalCombinedAgeCL = (data, segment) => {
  const creditLimitTotals = {
    totalApprovedCL: 0,
    totalUsedCL: 0,
    totalApprovedCustomer: 0,
    avgApprovedLimit: 0,
    avgUsedLimit: 0,
    utilization: 0,

    // fields for segmentation based on Combined Age
    lessThanEqualZero: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },

    greaterThanZero: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
  };

  data.forEach((item) => {
    creditLimitTotals.totalApprovedCL += item["Recommended Limit"];
    creditLimitTotals.totalUsedCL += item["Total AR Balance"];

    if (item["Recommended Limit"] > 0) {
      creditLimitTotals.totalApprovedCustomer += 1;
    }

    let comparisonVal;

    if (segment === "combined") {
      comparisonVal =
        checkFormat(item["AR Balance Aging 30"]) +
        checkFormat(item["AR Balance Aging 60"]) +
        checkFormat(item["AR Balance Aging 90"]) +
        checkFormat(item["AR Balance Aging 90+"]);
    } else {
      comparisonVal = item[segment];
    }

    if (comparisonVal <= 0) {
      creditLimitTotals.lessThanEqualZero.count += 1;
      creditLimitTotals.lessThanEqualZero.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.lessThanEqualZero.totalUsedCL +=
        item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.lessThanEqualZero.totalApprovedCustomer += 1;
      }
    } else if (comparisonVal > 0) {
      creditLimitTotals.greaterThanZero.count += 1;
      creditLimitTotals.greaterThanZero.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.greaterThanZero.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.greaterThanZero.totalApprovedCustomer += 1;
      }
    }
  });

  creditLimitTotals.avgApprovedLimit =
    creditLimitTotals.totalApprovedCL / data.length;

  creditLimitTotals.lessThanEqualZero.avgApprovedLimit =
    creditLimitTotals.lessThanEqualZero.totalApprovedCL /
    creditLimitTotals.lessThanEqualZero.count;

  creditLimitTotals.greaterThanZero.avgApprovedLimit =
    creditLimitTotals.greaterThanZero.totalApprovedCL /
    creditLimitTotals.greaterThanZero.count;

  creditLimitTotals.avgUsedLimit = creditLimitTotals.totalUsedCL / data.length;

  creditLimitTotals.lessThanEqualZero.avgUsedLimit =
    creditLimitTotals.lessThanEqualZero.totalUsedCL /
    creditLimitTotals.lessThanEqualZero.count;

  creditLimitTotals.greaterThanZero.avgUsedLimit =
    creditLimitTotals.greaterThanZero.totalUsedCL /
    creditLimitTotals.greaterThanZero.count;

  creditLimitTotals.utilization =
    (creditLimitTotals.totalUsedCL / creditLimitTotals.totalApprovedCL) * 100;

  creditLimitTotals.lessThanEqualZero.utilization =
    (creditLimitTotals.lessThanEqualZero.totalUsedCL /
      creditLimitTotals.lessThanEqualZero.totalApprovedCL) *
    100;

  creditLimitTotals.greaterThanZero.utilization =
    (creditLimitTotals.greaterThanZero.totalUsedCL /
      creditLimitTotals.greaterThanZero.totalApprovedCL) *
    100;

  const rowData = [
    {
      id: 1,
      metric: "Total $ value of approved credit limit",
      total: formatNumber(creditLimitTotals.totalApprovedCL),
      lessThanEqualZero: formatNumber(
        creditLimitTotals.lessThanEqualZero.totalApprovedCL
      ),
      greaterThanZero: formatNumber(
        creditLimitTotals.greaterThanZero.totalApprovedCL
      ),
    },
    {
      id: 2,
      metric: "Total $ value of used credit limit",
      total: formatNumber(creditLimitTotals.totalUsedCL),
      lessThanEqualZero: formatNumber(
        creditLimitTotals.lessThanEqualZero.totalUsedCL
      ),
      greaterThanZero: formatNumber(
        creditLimitTotals.greaterThanZero.totalUsedCL
      ),
    },
    {
      id: 3,
      metric: "Utilization %",
      total: formatNumber(creditLimitTotals.utilization),
      lessThanEqualZero: formatNumber(
        creditLimitTotals.lessThanEqualZero.utilization
      ),
      greaterThanZero: formatNumber(
        creditLimitTotals.greaterThanZero.utilization
      ),
    },
    {
      id: 4,
      metric: "Number of Approved Customers",
      total: formatNumber(creditLimitTotals.totalApprovedCustomer),
      lessThanEqualZero: formatNumber(
        creditLimitTotals.lessThanEqualZero.totalApprovedCustomer
      ),
      greaterThanZero: formatNumber(
        creditLimitTotals.greaterThanZero.totalApprovedCustomer
      ),
    },
    {
      id: 5,
      metric: "Average Approved Limit",
      total: formatNumber(creditLimitTotals.avgApprovedLimit),
      lessThanEqualZero: formatNumber(
        creditLimitTotals.lessThanEqualZero.avgApprovedLimit
      ),
      greaterThanZero: formatNumber(
        creditLimitTotals.greaterThanZero.avgApprovedLimit
      ),
    },
    {
      id: 6,
      metric: "Average Used Limit",
      total: formatNumber(creditLimitTotals.avgUsedLimit),
      lessThanEqualZero: formatNumber(
        creditLimitTotals.lessThanEqualZero.avgUsedLimit
      ),
      greaterThanZero: formatNumber(
        creditLimitTotals.greaterThanZero.avgUsedLimit
      ),
    },
  ];

  return rowData;
};

// Func to get total segmented upon - Risk Segment

export const getTotalSegmentedOnRS = (data) => {
  const creditLimitTotals = {
    totalApprovedCL: 0,
    totalUsedCL: 0,
    totalApprovedCustomer: 0,
    avgApprovedLimit: 0,
    avgUsedLimit: 0,
    utilization: 0,

    // fields for segmentation based on Risk Segment
    totalRS2Point5: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },

    totalRS5: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalRS7Point5: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalRS7Point5Greater: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
  };

  data.forEach((item) => {
    creditLimitTotals.totalApprovedCL += item["Recommended Limit"];
    creditLimitTotals.totalUsedCL += item["Total AR Balance"];

    if (item["Recommended Limit"] > 0) {
      creditLimitTotals.totalApprovedCustomer += 1;
    }

    if (item["Risk Segment"] <= 2.5 && item["Risk Segment"] >= 0) {
      creditLimitTotals.totalRS2Point5.count += 1;
      creditLimitTotals.totalRS2Point5.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalRS2Point5.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRS2Point5.totalApprovedCustomer += 1;
      }
    }

    if (item["Risk Segment"] <= 5 && item["Risk Segment"] > 2.5) {
      creditLimitTotals.totalRS5.count += 1;
      creditLimitTotals.totalRS5.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.totalRS5.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRS5.totalApprovedCustomer += 1;
      }
    }

    if (item["Risk Segment"] <= 7.5 && item["Risk Segment"] > 5) {
      creditLimitTotals.totalRS7Point5.count += 1;
      creditLimitTotals.totalRS7Point5.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalRS7Point5.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRS7Point5.totalApprovedCustomer += 1;
      }
    }

    if (item["Risk Segment"] > 7.5) {
      creditLimitTotals.totalRS7Point5Greater.count += 1;
      creditLimitTotals.totalRS7Point5Greater.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalRS7Point5Greater.totalUsedCL +=
        item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalRS7Point5Greater.totalApprovedCustomer += 1;
      }
    }
  });

  creditLimitTotals.avgApprovedLimit =
    creditLimitTotals.totalApprovedCL / data.length;

  creditLimitTotals.totalRS2Point5.avgApprovedLimit =
    creditLimitTotals.totalRS2Point5.totalApprovedCL /
    creditLimitTotals.totalRS2Point5.count;

  creditLimitTotals.totalRS5.avgApprovedLimit =
    creditLimitTotals.totalRS5.totalApprovedCL /
    creditLimitTotals.totalRS5.count;

  creditLimitTotals.totalRS7Point5.avgApprovedLimit =
    creditLimitTotals.totalRS7Point5.totalApprovedCL /
    creditLimitTotals.totalRS7Point5.count;

  creditLimitTotals.totalRS7Point5Greater.avgApprovedLimit =
    creditLimitTotals.totalRS7Point5Greater.totalApprovedCL /
    creditLimitTotals.totalRS7Point5Greater.count;

  creditLimitTotals.avgUsedLimit = creditLimitTotals.totalUsedCL / data.length;

  creditLimitTotals.totalRS2Point5.avgUsedLimit =
    creditLimitTotals.totalRS2Point5.totalUsedCL /
    creditLimitTotals.totalRS2Point5.count;

  creditLimitTotals.totalRS5.avgUsedLimit =
    creditLimitTotals.totalRS5.totalUsedCL / creditLimitTotals.totalRS5.count;

  creditLimitTotals.totalRS7Point5.avgUsedLimit =
    creditLimitTotals.totalRS7Point5.totalUsedCL /
    creditLimitTotals.totalRS7Point5.count;

  creditLimitTotals.totalRS7Point5Greater.avgUsedLimit =
    creditLimitTotals.totalRS7Point5Greater.totalUsedCL /
    creditLimitTotals.totalRS7Point5Greater.count;

  creditLimitTotals.utilization =
    (creditLimitTotals.totalUsedCL / creditLimitTotals.totalApprovedCL) * 100;

  creditLimitTotals.totalRS2Point5.utilization =
    (creditLimitTotals.totalRS2Point5.totalUsedCL /
      creditLimitTotals.totalRS2Point5.totalApprovedCL) *
    100;

  creditLimitTotals.totalRS5.utilization =
    (creditLimitTotals.totalRS5.totalUsedCL /
      creditLimitTotals.totalRS5.totalApprovedCL) *
    100;

  creditLimitTotals.totalRS7Point5.utilization =
    (creditLimitTotals.totalRS7Point5.totalUsedCL /
      creditLimitTotals.totalRS7Point5.totalApprovedCL) *
    100;

  creditLimitTotals.totalRS7Point5Greater.utilization =
    (creditLimitTotals.totalRS7Point5Greater.totalUsedCL /
      creditLimitTotals.totalRS7Point5Greater.totalApprovedCL) *
    100;

  const rowDataCreditLimit = [
    {
      id: 1,
      metric: "Total $ value of approved credit limit",
      total: formatNumber(creditLimitTotals.totalApprovedCL),
      totalRS2Point5: formatNumber(
        creditLimitTotals.totalRS2Point5.totalApprovedCL
      ),
      totalRS5: formatNumber(creditLimitTotals.totalRS5.totalApprovedCL),
      totalRS7Point5: formatNumber(
        creditLimitTotals.totalRS7Point5.totalApprovedCL
      ),
      totalRS7Point5Greater: formatNumber(
        creditLimitTotals.totalRS7Point5Greater.totalApprovedCL
      ),
    },
    {
      id: 2,
      metric: "Total $ value of used credit limit",
      total: formatNumber(creditLimitTotals.totalUsedCL),
      totalRS2Point5: formatNumber(
        creditLimitTotals.totalRS2Point5.totalUsedCL
      ),
      totalRS5: formatNumber(creditLimitTotals.totalRS5.totalUsedCL),
      totalRS7Point5: formatNumber(
        creditLimitTotals.totalRS7Point5.totalUsedCL
      ),
      totalRS7Point5Greater: formatNumber(
        creditLimitTotals.totalRS7Point5Greater.totalUsedCL
      ),
    },
    {
      id: 3,
      metric: "Utilization %",
      total: formatNumber(creditLimitTotals.utilization),
      totalRS2Point5: formatNumber(
        creditLimitTotals.totalRS2Point5.utilization
      ),
      totalRS5: formatNumber(creditLimitTotals.totalRS5.utilization),
      totalRS7Point5: formatNumber(
        creditLimitTotals.totalRS7Point5.utilization
      ),
      totalRS7Point5Greater: formatNumber(
        creditLimitTotals.totalRS7Point5Greater.utilization
      ),
    },
    {
      id: 4,
      metric: "Number of Approved Customers",
      total: formatNumber(creditLimitTotals.totalApprovedCustomer),
      totalRS2Point5: formatNumber(
        creditLimitTotals.totalRS2Point5.totalApprovedCustomer
      ),
      totalRS5: formatNumber(creditLimitTotals.totalRS5.totalApprovedCustomer),
      totalRS7Point5: formatNumber(
        creditLimitTotals.totalRS7Point5.totalApprovedCustomer
      ),
      totalRS7Point5Greater: formatNumber(
        creditLimitTotals.totalRS7Point5Greater.totalApprovedCustomer
      ),
    },
    {
      id: 5,
      metric: "Average Approved Limit",
      total: formatNumber(creditLimitTotals.avgApprovedLimit),
      totalRS2Point5: formatNumber(
        creditLimitTotals.totalRS2Point5.avgApprovedLimit
      ),
      totalRS5: formatNumber(creditLimitTotals.totalRS5.avgApprovedLimit),
      totalRS7Point5: formatNumber(
        creditLimitTotals.totalRS7Point5.avgApprovedLimit
      ),
      totalRS7Point5Greater: formatNumber(
        creditLimitTotals.totalRS7Point5Greater.avgApprovedLimit
      ),
    },
    {
      id: 6,
      metric: "Average Used Limit",
      total: formatNumber(creditLimitTotals.avgUsedLimit),
      totalRS2Point5: formatNumber(
        creditLimitTotals.totalRS2Point5.avgUsedLimit
      ),
      totalRS5: formatNumber(creditLimitTotals.totalRS5.avgUsedLimit),
      totalRS7Point5: formatNumber(
        creditLimitTotals.totalRS7Point5.avgUsedLimit
      ),
      totalRS7Point5Greater: formatNumber(
        creditLimitTotals.totalRS7Point5Greater.avgUsedLimit
      ),
    },
  ];

  return rowDataCreditLimit;
};

// Func to get total segmented upon - Current AR Balance Or Current Limit

export const getTotalSegmentedOnCARBOrCL = (data, segment) => {
  const creditLimitTotals = {
    totalApprovedCL: 0,
    totalUsedCL: 0,
    totalApprovedCustomer: 0,
    avgApprovedLimit: 0,
    avgUsedLimit: 0,
    utilization: 0,

    // fields for segmentation based on Current AR Balance or Current Limit
    totalCARB20K: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },

    totalCARB50K: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalCARB150K: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalCARB250K: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalCARB500K: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    totalCARB500KGreater: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
  };

  data.forEach((item) => {
    creditLimitTotals.totalApprovedCL += item["Recommended Limit"];
    creditLimitTotals.totalUsedCL += item["Total AR Balance"];

    if (item["Recommended Limit"] > 0) {
      creditLimitTotals.totalApprovedCustomer += 1;
    }

    if (item[segment] < 20000 && item[segment] >= 0) {
      creditLimitTotals.totalCARB20K.count += 1;
      creditLimitTotals.totalCARB20K.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalCARB20K.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalCARB20K.totalApprovedCustomer += 1;
      }
    }

    if (item[segment] < 50000 && item[segment] >= 20000) {
      creditLimitTotals.totalCARB50K.count += 1;
      creditLimitTotals.totalCARB50K.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalCARB50K.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalCARB50K.totalApprovedCustomer += 1;
      }
    }

    if (item[segment] < 150000 && item[segment] >= 50000) {
      creditLimitTotals.totalCARB150K.count += 1;
      creditLimitTotals.totalCARB150K.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalCARB150K.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalCARB150K.totalApprovedCustomer += 1;
      }
    }

    if (item[segment] < 250000 && item[segment] >= 150000) {
      creditLimitTotals.totalCARB250K.count += 1;
      creditLimitTotals.totalCARB250K.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalCARB250K.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalCARB250K.totalApprovedCustomer += 1;
      }
    }

    if (item[segment] < 500000 && item[segment] >= 250000) {
      creditLimitTotals.totalCARB500K.count += 1;
      creditLimitTotals.totalCARB500K.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalCARB500K.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalCARB500K.totalApprovedCustomer += 1;
      }
    }

    if (item[segment] > 500000) {
      creditLimitTotals.totalCARB500KGreater.count += 1;
      creditLimitTotals.totalCARB500KGreater.totalApprovedCL +=
        item["Recommended Limit"];
      creditLimitTotals.totalCARB500KGreater.totalUsedCL +=
        item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.totalCARB500KGreater.totalApprovedCustomer += 1;
      }
    }
  });

  creditLimitTotals.avgApprovedLimit =
    creditLimitTotals.totalApprovedCL / data.length;

  creditLimitTotals.totalCARB20K.avgApprovedLimit =
    creditLimitTotals.totalCARB20K.totalApprovedCL /
    creditLimitTotals.totalCARB20K.count;

  creditLimitTotals.totalCARB50K.avgApprovedLimit =
    creditLimitTotals.totalCARB50K.totalApprovedCL /
    creditLimitTotals.totalCARB50K.count;

  creditLimitTotals.totalCARB150K.avgApprovedLimit =
    creditLimitTotals.totalCARB150K.totalApprovedCL /
    creditLimitTotals.totalCARB150K.count;

  creditLimitTotals.totalCARB250K.avgApprovedLimit =
    creditLimitTotals.totalCARB250K.totalApprovedCL /
    creditLimitTotals.totalCARB250K.count;

  creditLimitTotals.totalCARB500K.avgApprovedLimit =
    creditLimitTotals.totalCARB500K.totalApprovedCL /
    creditLimitTotals.totalCARB500K.count;

  creditLimitTotals.totalCARB500KGreater.avgApprovedLimit =
    creditLimitTotals.totalCARB500KGreater.totalApprovedCL /
    creditLimitTotals.totalCARB500KGreater.count;

  creditLimitTotals.avgUsedLimit = creditLimitTotals.totalUsedCL / data.length;

  creditLimitTotals.totalCARB20K.avgUsedLimit =
    creditLimitTotals.totalCARB20K.totalUsedCL /
    creditLimitTotals.totalCARB20K.count;

  creditLimitTotals.totalCARB50K.avgUsedLimit =
    creditLimitTotals.totalCARB50K.totalUsedCL /
    creditLimitTotals.totalCARB50K.count;

  creditLimitTotals.totalCARB150K.avgUsedLimit =
    creditLimitTotals.totalCARB150K.totalUsedCL /
    creditLimitTotals.totalCARB150K.count;

  creditLimitTotals.totalCARB250K.avgUsedLimit =
    creditLimitTotals.totalCARB250K.totalUsedCL /
    creditLimitTotals.totalCARB250K.count;

  creditLimitTotals.totalCARB500K.avgUsedLimit =
    creditLimitTotals.totalCARB500K.totalUsedCL /
    creditLimitTotals.totalCARB500K.count;

  creditLimitTotals.totalCARB500KGreater.avgUsedLimit =
    creditLimitTotals.totalCARB500KGreater.totalUsedCL /
    creditLimitTotals.totalCARB500KGreater.count;

  creditLimitTotals.utilization =
    (creditLimitTotals.totalUsedCL / creditLimitTotals.totalApprovedCL) * 100;

  creditLimitTotals.totalCARB20K.utilization =
    (creditLimitTotals.totalCARB20K.totalUsedCL /
      creditLimitTotals.totalCARB20K.totalApprovedCL) *
    100;

  creditLimitTotals.totalCARB50K.utilization =
    (creditLimitTotals.totalCARB50K.totalUsedCL /
      creditLimitTotals.totalCARB50K.totalApprovedCL) *
    100;

  creditLimitTotals.totalCARB150K.utilization =
    (creditLimitTotals.totalCARB150K.totalUsedCL /
      creditLimitTotals.totalCARB150K.totalApprovedCL) *
    100;

  creditLimitTotals.totalCARB250K.utilization =
    (creditLimitTotals.totalCARB250K.totalUsedCL /
      creditLimitTotals.totalCARB250K.totalApprovedCL) *
    100;

  creditLimitTotals.totalCARB500K.utilization =
    (creditLimitTotals.totalCARB500K.totalUsedCL /
      creditLimitTotals.totalCARB500K.totalApprovedCL) *
    100;

  creditLimitTotals.totalCARB500KGreater.utilization =
    (creditLimitTotals.totalCARB500KGreater.totalUsedCL /
      creditLimitTotals.totalCARB500KGreater.totalApprovedCL) *
    100;

  const rowDataCreditLimit = [
    {
      id: 1,
      metric: "Total $ value of approved credit limit",
      total: formatNumber(creditLimitTotals.totalApprovedCL),
      totalCARB20K: formatNumber(
        creditLimitTotals.totalCARB20K.totalApprovedCL
      ),
      totalCARB50K: formatNumber(
        creditLimitTotals.totalCARB50K.totalApprovedCL
      ),
      totalCARB150K: formatNumber(
        creditLimitTotals.totalCARB150K.totalApprovedCL
      ),
      totalCARB250K: formatNumber(
        creditLimitTotals.totalCARB250K.totalApprovedCL
      ),
      totalCARB500K: formatNumber(
        creditLimitTotals.totalCARB500K.totalApprovedCL
      ),
      totalCARB500KGreater: formatNumber(
        creditLimitTotals.totalCARB500KGreater.totalApprovedCL
      ),
    },
    {
      id: 2,
      metric: "Total $ value of used credit limit",
      total: formatNumber(creditLimitTotals.totalUsedCL),
      totalCARB20K: formatNumber(creditLimitTotals.totalCARB20K.totalUsedCL),
      totalCARB50K: formatNumber(creditLimitTotals.totalCARB50K.totalUsedCL),
      totalCARB150K: formatNumber(creditLimitTotals.totalCARB150K.totalUsedCL),
      totalCARB250K: formatNumber(creditLimitTotals.totalCARB250K.totalUsedCL),
      totalCARB500K: formatNumber(creditLimitTotals.totalCARB500K.totalUsedCL),
      totalCARB500KGreater: formatNumber(
        creditLimitTotals.totalCARB500KGreater.totalUsedCL
      ),
    },
    {
      id: 3,
      metric: "Utilization %",
      total: formatNumber(creditLimitTotals.utilization),
      totalCARB20K: formatNumber(creditLimitTotals.totalCARB20K.utilization),
      totalCARB50K: formatNumber(creditLimitTotals.totalCARB50K.utilization),
      totalCARB150K: formatNumber(creditLimitTotals.totalCARB150K.utilization),
      totalCARB250K: formatNumber(creditLimitTotals.totalCARB250K.utilization),
      totalCARB500K: formatNumber(creditLimitTotals.totalCARB500K.utilization),
      totalCARB500KGreater: formatNumber(
        creditLimitTotals.totalCARB500KGreater.utilization
      ),
    },
    {
      id: 4,
      metric: "Number of Approved Customers",
      total: formatNumber(creditLimitTotals.totalApprovedCustomer),
      totalCARB20K: formatNumber(
        creditLimitTotals.totalCARB20K.totalApprovedCustomer
      ),
      totalCARB50K: formatNumber(
        creditLimitTotals.totalCARB50K.totalApprovedCustomer
      ),
      totalCARB150K: formatNumber(
        creditLimitTotals.totalCARB150K.totalApprovedCustomer
      ),
      totalCARB250K: formatNumber(
        creditLimitTotals.totalCARB250K.totalApprovedCustomer
      ),
      totalCARB500K: formatNumber(
        creditLimitTotals.totalCARB500K.totalApprovedCustomer
      ),
      totalCARB500KGreater: formatNumber(
        creditLimitTotals.totalCARB500KGreater.totalApprovedCustomer
      ),
    },
    {
      id: 5,
      metric: "Average Approved Limit",
      total: formatNumber(creditLimitTotals.avgApprovedLimit),
      totalCARB20K: formatNumber(
        creditLimitTotals.totalCARB20K.avgApprovedLimit
      ),
      totalCARB50K: formatNumber(
        creditLimitTotals.totalCARB50K.avgApprovedLimit
      ),
      totalCARB150K: formatNumber(
        creditLimitTotals.totalCARB150K.avgApprovedLimit
      ),
      totalCARB250K: formatNumber(
        creditLimitTotals.totalCARB250K.avgApprovedLimit
      ),
      totalCARB500K: formatNumber(
        creditLimitTotals.totalCARB500K.avgApprovedLimit
      ),
      totalCARB500KGreater: formatNumber(
        creditLimitTotals.totalCARB500KGreater.avgApprovedLimit
      ),
    },
    {
      id: 6,
      metric: "Average Used Limit",
      total: formatNumber(creditLimitTotals.avgUsedLimit),
      totalCARB20K: formatNumber(creditLimitTotals.totalCARB20K.avgUsedLimit),
      totalCARB50K: formatNumber(creditLimitTotals.totalCARB50K.avgUsedLimit),
      totalCARB150K: formatNumber(creditLimitTotals.totalCARB150K.avgUsedLimit),
      totalCARB250K: formatNumber(creditLimitTotals.totalCARB250K.avgUsedLimit),
      totalCARB500K: formatNumber(creditLimitTotals.totalCARB500K.avgUsedLimit),
      totalCARB500KGreater: formatNumber(
        creditLimitTotals.totalCARB500KGreater.avgUsedLimit
      ),
    },
  ];

  return rowDataCreditLimit;
};

// Func to get total segmented upon - Country Score or Underwriting Reason

export const getTotalSegmentedOnUWROrCS = (data, segment, comparisonVal) => {
  const creditLimitTotals = {
    totalApprovedCL: 0,
    totalUsedCL: 0,
    totalApprovedCustomer: 0,
    avgApprovedLimit: 0,
    avgUsedLimit: 0,
    utilization: 0,

    // fields for segmentation based on Country Score or Underwriting Reason
    total1: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },

    total2: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    total3: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    total4: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    total5: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    total6: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    total7: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
    total8: {
      count: 0,
      totalApprovedCL: 0,
      totalUsedCL: 0,
      totalApprovedCustomer: 0,
      avgApprovedLimit: 0,
      avgUsedLimit: 0,
      utilization: 0,
    },
  };

  data.forEach((item) => {
    creditLimitTotals.totalApprovedCL += item["Recommended Limit"];
    creditLimitTotals.totalUsedCL += item["Total AR Balance"];

    if (item["Recommended Limit"] > 0) {
      creditLimitTotals.totalApprovedCustomer += 1;
    }

    if (item[segment] === comparisonVal.val1) {
      creditLimitTotals.total1.count += 1;
      creditLimitTotals.total1.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.total1.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.total1.totalApprovedCustomer += 1;
      }
    } else if (item[segment] === comparisonVal.val2) {
      creditLimitTotals.total2.count += 1;
      creditLimitTotals.total2.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.total2.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.total2.totalApprovedCustomer += 1;
      }
    } else if (item[segment] === comparisonVal.val3) {
      creditLimitTotals.total3.count += 1;
      creditLimitTotals.total3.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.total3.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.total3.totalApprovedCustomer += 1;
      }
    } else if (item[segment] === comparisonVal.val4) {
      creditLimitTotals.total4.count += 1;
      creditLimitTotals.total4.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.total4.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.total4.totalApprovedCustomer += 1;
      }
    } else if (item[segment] === comparisonVal.val5) {
      creditLimitTotals.total5.count += 1;
      creditLimitTotals.total5.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.total5.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.total5.totalApprovedCustomer += 1;
      }
    } else if (item[segment] === comparisonVal.val6) {
      creditLimitTotals.total6.count += 1;
      creditLimitTotals.total6.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.total6.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.total6.totalApprovedCustomer += 1;
      }
    } else if (item[segment] === comparisonVal.val7) {
      creditLimitTotals.total7.count += 1;
      creditLimitTotals.total7.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.total7.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.total7.totalApprovedCustomer += 1;
      }
    } else if (
      (segment === "Country Score" && item[segment] > 7) ||
      segment === "Underwriting Reason"
    ) {
      creditLimitTotals.total8.count += 1;
      creditLimitTotals.total8.totalApprovedCL += item["Recommended Limit"];
      creditLimitTotals.total8.totalUsedCL += item["Total AR Balance"];

      if (item["Recommended Limit"] > 0) {
        creditLimitTotals.total8.totalApprovedCustomer += 1;
      }
    }
  });

  creditLimitTotals.avgApprovedLimit =
    creditLimitTotals.totalApprovedCL / data.length;

  creditLimitTotals.total1.avgApprovedLimit =
    creditLimitTotals.total1.totalApprovedCL / creditLimitTotals.total1.count;

  creditLimitTotals.total2.avgApprovedLimit =
    creditLimitTotals.total2.totalApprovedCL / creditLimitTotals.total2.count;

  creditLimitTotals.total3.avgApprovedLimit =
    creditLimitTotals.total3.totalApprovedCL / creditLimitTotals.total3.count;

  creditLimitTotals.total4.avgApprovedLimit =
    creditLimitTotals.total4.totalApprovedCL / creditLimitTotals.total4.count;

  creditLimitTotals.total5.avgApprovedLimit =
    creditLimitTotals.total5.totalApprovedCL / creditLimitTotals.total5.count;

  creditLimitTotals.total6.avgApprovedLimit =
    creditLimitTotals.total6.totalApprovedCL / creditLimitTotals.total6.count;

  creditLimitTotals.total7.avgApprovedLimit =
    creditLimitTotals.total7.totalApprovedCL / creditLimitTotals.total7.count;

  creditLimitTotals.total8.avgApprovedLimit =
    creditLimitTotals.total8.totalApprovedCL / creditLimitTotals.total8.count;

  creditLimitTotals.avgUsedLimit = creditLimitTotals.totalUsedCL / data.length;

  creditLimitTotals.total1.avgUsedLimit =
    creditLimitTotals.total1.totalUsedCL / creditLimitTotals.total1.count;

  creditLimitTotals.total2.avgUsedLimit =
    creditLimitTotals.total2.totalUsedCL / creditLimitTotals.total2.count;

  creditLimitTotals.total3.avgUsedLimit =
    creditLimitTotals.total3.totalUsedCL / creditLimitTotals.total3.count;

  creditLimitTotals.total4.avgUsedLimit =
    creditLimitTotals.total4.totalUsedCL / creditLimitTotals.total4.count;

  creditLimitTotals.total5.avgUsedLimit =
    creditLimitTotals.total5.totalUsedCL / creditLimitTotals.total5.count;

  creditLimitTotals.total6.avgUsedLimit =
    creditLimitTotals.total6.totalUsedCL / creditLimitTotals.total6.count;

  creditLimitTotals.total7.avgUsedLimit =
    creditLimitTotals.total7.totalUsedCL / creditLimitTotals.total7.count;

  creditLimitTotals.total8.avgUsedLimit =
    creditLimitTotals.total8.totalUsedCL / creditLimitTotals.total8.count;

  creditLimitTotals.utilization =
    (creditLimitTotals.totalUsedCL / creditLimitTotals.totalApprovedCL) * 100;

  creditLimitTotals.total1.utilization =
    (creditLimitTotals.total1.totalUsedCL /
      creditLimitTotals.total1.totalApprovedCL) *
    100;

  creditLimitTotals.total2.utilization =
    (creditLimitTotals.total2.totalUsedCL /
      creditLimitTotals.total2.totalApprovedCL) *
    100;

  creditLimitTotals.total3.utilization =
    (creditLimitTotals.total3.totalUsedCL /
      creditLimitTotals.total3.totalApprovedCL) *
    100;

  creditLimitTotals.total4.utilization =
    (creditLimitTotals.total4.totalUsedCL /
      creditLimitTotals.total4.totalApprovedCL) *
    100;

  creditLimitTotals.total5.utilization =
    (creditLimitTotals.total5.totalUsedCL /
      creditLimitTotals.total5.totalApprovedCL) *
    100;

  creditLimitTotals.total6.utilization =
    (creditLimitTotals.total6.totalUsedCL /
      creditLimitTotals.total6.totalApprovedCL) *
    100;

  creditLimitTotals.total7.utilization =
    (creditLimitTotals.total7.totalUsedCL /
      creditLimitTotals.total7.totalApprovedCL) *
    100;

  creditLimitTotals.total8.utilization =
    (creditLimitTotals.total8.totalUsedCL /
      creditLimitTotals.total8.totalApprovedCL) *
    100;

  const rowData = [
    {
      id: 1,
      metric: "Total $ value of approved credit limit",
      total: formatNumber(creditLimitTotals.totalApprovedCL),
      total1: formatNumber(creditLimitTotals.total1.totalApprovedCL),
      total2: formatNumber(creditLimitTotals.total2.totalApprovedCL),
      total3: formatNumber(creditLimitTotals.total3.totalApprovedCL),
      total4: formatNumber(creditLimitTotals.total4.totalApprovedCL),
      total5: formatNumber(creditLimitTotals.total5.totalApprovedCL),
      total6: formatNumber(creditLimitTotals.total6.totalApprovedCL),
      total7: formatNumber(creditLimitTotals.total7.totalApprovedCL),
      total8: formatNumber(creditLimitTotals.total8.totalApprovedCL),
    },
    {
      id: 2,
      metric: "Total $ value of used credit limit",
      total: formatNumber(creditLimitTotals.totalUsedCL),
      total1: formatNumber(creditLimitTotals.total1.totalUsedCL),
      total2: formatNumber(creditLimitTotals.total2.totalUsedCL),
      total3: formatNumber(creditLimitTotals.total3.totalUsedCL),
      total4: formatNumber(creditLimitTotals.total4.totalUsedCL),
      total5: formatNumber(creditLimitTotals.total5.totalUsedCL),
      total6: formatNumber(creditLimitTotals.total6.totalUsedCL),
      total7: formatNumber(creditLimitTotals.total7.totalUsedCL),
      total8: formatNumber(creditLimitTotals.total8.totalUsedCL),
    },
    {
      id: 3,
      metric: "Utilization %",
      total: formatNumber(creditLimitTotals.utilization),
      total1: formatNumber(creditLimitTotals.total1.utilization),
      total2: formatNumber(creditLimitTotals.total2.utilization),
      total3: formatNumber(creditLimitTotals.total3.utilization),
      total4: formatNumber(creditLimitTotals.total4.utilization),
      total5: formatNumber(creditLimitTotals.total5.utilization),
      total6: formatNumber(creditLimitTotals.total6.utilization),
      total7: formatNumber(creditLimitTotals.total7.utilization),
      total8: formatNumber(creditLimitTotals.total8.utilization),
    },
    {
      id: 4,
      metric: "Number of Approved Customers",
      total: formatNumber(creditLimitTotals.totalApprovedCustomer),
      total1: formatNumber(creditLimitTotals.total1.totalApprovedCustomer),
      total2: formatNumber(creditLimitTotals.total2.totalApprovedCustomer),
      total3: formatNumber(creditLimitTotals.total3.totalApprovedCustomer),
      total4: formatNumber(creditLimitTotals.total4.totalApprovedCustomer),
      total5: formatNumber(creditLimitTotals.total5.totalApprovedCustomer),
      total6: formatNumber(creditLimitTotals.total6.totalApprovedCustomer),
      total7: formatNumber(creditLimitTotals.total7.totalApprovedCustomer),
      total8: formatNumber(creditLimitTotals.total8.totalApprovedCustomer),
    },
    {
      id: 5,
      metric: "Average Approved Limit",
      total: formatNumber(creditLimitTotals.avgApprovedLimit),
      total1: formatNumber(creditLimitTotals.total1.avgApprovedLimit),
      total2: formatNumber(creditLimitTotals.total2.avgApprovedLimit),
      total3: formatNumber(creditLimitTotals.total3.avgApprovedLimit),
      total4: formatNumber(creditLimitTotals.total4.avgApprovedLimit),
      total5: formatNumber(creditLimitTotals.total5.avgApprovedLimit),
      total6: formatNumber(creditLimitTotals.total6.avgApprovedLimit),
      total7: formatNumber(creditLimitTotals.total7.avgApprovedLimit),
      total8: formatNumber(creditLimitTotals.total8.avgApprovedLimit),
    },
    {
      id: 6,
      metric: "Average Used Limit",
      total: formatNumber(creditLimitTotals.avgUsedLimit),
      total1: formatNumber(creditLimitTotals.total1.avgUsedLimit),
      total2: formatNumber(creditLimitTotals.total2.avgUsedLimit),
      total3: formatNumber(creditLimitTotals.total3.avgUsedLimit),
      total4: formatNumber(creditLimitTotals.total4.avgUsedLimit),
      total5: formatNumber(creditLimitTotals.total5.avgUsedLimit),
      total6: formatNumber(creditLimitTotals.total6.avgUsedLimit),
      total7: formatNumber(creditLimitTotals.total7.avgUsedLimit),
      total8: formatNumber(creditLimitTotals.total8.avgUsedLimit),
    },
  ];

  return rowData;
};
