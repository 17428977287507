import { listCompanySummaryData } from "./utils/Helper";
import countryMapping from './utils/countries';

export const safeJSONParse = (data) => {
  try {
    if (typeof data === "object") return data;
    let parsed = data;
    while (typeof parsed === "string") {
      try {
        parsed = JSON.parse(parsed);
      } catch {
        break;
      }
    }
    return parsed;
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null;
  }
};

export const checkCompanies = async (data, tenantId) => {
  console.log("checkCompanies",data)
  const companiesToCheck = [
    ...(data.parent_company?.name ? [data.parent_company] : []),
    ...(data.subsidiaries || []),
    ...(data.related_companies || []),
    ...(data.competitors || [])
  ];

  if (companiesToCheck.length === 0) return {};
  console.log("companiesToCheck",companiesToCheck)
  try {
    const allCompanies = await listCompanySummaryData(tenantId);
    
    const results = {};
    for (const company of companiesToCheck) {
      const fullCountryNames = countryMapping[company.country] || [];
      
      const matchingCompany = allCompanies.find(c => 
        c.companyName.toLowerCase() === company.name.toLowerCase()
      );

      if (matchingCompany) {
        const summaryData = JSON.parse(matchingCompany.summaryData);
        results[company.name] = { combinedScore: summaryData["Combined Score"] };
      }
    }
    
    return results;
  } catch (error) {
    console.error('Error fetching companies:', error);
    return {};
  }
};

export const getCompanyTreeData = (treeData) => {
  let data = safeJSONParse(treeData);

  if (!data) {
    return {
      data: null,
      treeNotExist: true,
      hasKeyStaff: false,
      hasParentCompany: false,
      hasSubsidiaries: false,
      hasRelatedCompanies: false,
      hasCompetitors: false,
      hasRelatedPeople: false,
    };
  }

  const hasKeyStaff =
    data.key_staff?.length > 0 &&
    data.key_staff.some((staff) => staff.full_name || staff.position);

  const hasParentCompany = Object.values(data.parent_company || {}).some(
    (value) => value && value !== ""
  );

  const hasSubsidiaries =
    data.subsidiaries?.length > 0 &&
    data.subsidiaries.some(
      (sub) =>
        sub.name ||
        sub.country ||
        sub.industry ||
        sub.ownership_percentage ||
        sub.revenue ||
        sub.number_of_employees
    );

  const hasRelatedCompanies =
    data.related_companies?.length > 0 &&
    data.related_companies.some(
      (company) => company.name || company.country || company.relationship_type
    );

  const hasCompetitors =
    data.competitors?.length > 0 &&
    data.competitors.some((comp) => comp.name || comp.country);

  const hasRelatedPeople =
    data.related_people?.length > 0 &&
    data.related_people.some(
      (person) => person.full_name || person.relationship_type
    );

  const hasAnyData =
    hasKeyStaff ||
    hasParentCompany ||
    hasSubsidiaries ||
    hasRelatedCompanies ||
    hasCompetitors ||
    hasRelatedPeople;

    const treeNotExist = !data || !hasAnyData

  return {
    data,
    treeNotExist,
    hasKeyStaff,
    hasParentCompany,
    hasSubsidiaries,
    hasRelatedCompanies,
    hasCompetitors,
    hasRelatedPeople,
  };
};
