import React, { useCallback, useEffect, useRef, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { companiesByName, getDashboardPreferences } from "../graphql/queries";
import { createCompany } from "../graphql/mutations";
import getTenantIdFromURL from "../utils/getTenantIdFromURL";
import { useSelector } from "react-redux";

import {
  Box,
  Button,
  Chip,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DataGridPremium,
  GridFooterContainer,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridSelector,
  gridFilteredTopLevelRowCountSelector,
  gridDensityValueSelector
} from "@mui/x-data-grid-premium";
import "./RecommendationTable.css";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { LocalStyle } from "./helper";
import { ColorEnum } from "../Assets/Colors/ColorsEnum";
import { PieChart, Pie } from "recharts";
import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Green from "../../src/Assets/Images/smiley_green.png";
import Red from "../../src/Assets/Images/smiley_red.png";
import Yellow from "../../src/Assets/Images/smiley_yellow.png";
import { CSVLink } from "react-csv";
import {
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridFilterModelSelector,
} from "@mui/x-data-grid-premium";
import DownloadIcon from "@mui/icons-material/Download";
import newSearchIcon from "../../src/Assets/Images/searchIconNew.svg";
import { useProspectsAR } from "../context/prospectsARContext";
import SendRequestModal from "../SendRequestModal";
import { constrainValue, formatCurrency, formatCurrencyValue, formatArcScore, normalizePaydex, processAgingData, MitigationOptions, getCompanyData } from "../utils/Helper";
import { useUserPreferences } from "../context/userPreferencesContext";
// Legend component
const Legend = () => (
  <Box display="flex" alignItems="center" padding={1}>
    <CircleIcon sx={{ color: "#3097D2", fontSize: 12, mr: 2 }} />
    <Typography>Public Company</Typography>
  </Box>
);

// DoughnutChart component
const DoughnutChart = ({ row }) => {
  const data = [
    { name: "Country Score", value: row["Country Score"], fill: "#2F3D63" },
    { name: "Legal Score", value: row["Legal Score"], fill: "#3AA623" },
    {
      name: "Deliquency Score",
      value: row["Deliquency Score"],
      fill: "#F25A48",
    },
    { name: "Failure Score", value: row["Failure Score"], fill: "#E7BF3C" },
    { name: "Altman Z Score", value: row["Altman Z Score"], fill: "#F248B4" },
    {
      name: "Alternative Score",
      value: row["Alternative Score"],
      fill: "#5186EC",
    },
    { name: "Sector Score", value: row["Sector Score"], fill: "#3AA629" },
  ];

  return (
    <PieChart height={50} width={50}>
      <Pie
        data={data}
        spacing={2}
        // cx={60}
        // cy={100}
        innerRadius={15}
        outerRadius={25}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="value"
      ></Pie>
    </PieChart>
  );
};
const CustomFooter = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const { paginationModel, onPaginationModelChange, density } = props;
  const apiRef = useGridApiContext();
  const filteredRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector);

  const pageSizeForDensity = {
    comfortable: 5,
    standard: 7,
    compact: 10,
  };

  const pageSize = pageSizeForDensity[density] ?? paginationModel?.pageSize ?? 10;


  const handlePageChange = (event, newPage) => {
    if (onPaginationModelChange) {
      onPaginationModelChange({ ...paginationModel, page: newPage - 1 });
    }
  };

  const handleDensityChange = (newDensity) => {
    const newPageSize = pageSizeForDensity[newDensity];
    if (onPaginationModelChange) {
      onPaginationModelChange({ pageSize: newPageSize, page: 0 });
    }
  };

  React.useEffect(() => {
    handleDensityChange(density);
  }, [density]);

  const pageCount = Math.ceil(filteredRowCount / pageSize);

  return (
  <GridFooterContainer ref={ref}>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={1}
      sx={{ borderTop: `1px solid ${theme.palette.divider}`, width: '100%' }}
    >
      <Legend />
      <Box display="flex" alignItems="center">
        <Typography variant="body2" sx={{ marginRight: 2 }}>
          Total Companies: {filteredRowCount}
        </Typography>
          <Pagination
            count={pageCount}
            page={paginationModel?.page + 1}
            onChange={handlePageChange}
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: () => (
                    <Box
                      sx={{
                        display: "flex",
                        "&:hover .hoverText": { textDecoration: "underline" },
                      }}
                    >
                      <KeyboardArrowLeftIcon />
                      <Typography className="hoverText">Previous</Typography>
                    </Box>
                  ),
                  next: () => (
                    <Box
                      sx={{
                        display: "flex",
                        "&:hover .hoverText": { textDecoration: "underline" },
                      }}
                    >
                      <Typography className="hoverText">Next</Typography>
                      <KeyboardArrowRightIcon />
                    </Box>
                  ),
                }}
                {...item}
              />
            )}
            sx={{
              fontFamily: "rubik",
              "& .MuiPaginationItem-root": {
                color: "#1A2A56",
              },
              ".MuiPaginationItem-page.Mui-selected": {
                backgroundColor: "#1A2A56",
                ":hover": { backgroundColor: "#1A2A56" },
                color: "white",
              },
            }}
          />
      </Box>
    </Box>
  </GridFooterContainer>
  );
});

// Drop Down Menu
const ActionsMenu = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(row)
  const [fileType, setFileType] = useState(null)
  
  const handleOpen = () => setOpen(true);
  const handleCloseSR = () => setOpen(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    console.log(`Action: ${action}, Row ID: ${row.id}`);
    setFileType(action)
    setOpen(true);
    handleClose();
  };

  const availableMitigations = Array.isArray(selectedCompany?.["Recommended Action"])
  ? MitigationOptions.filter((item) =>
      selectedCompany["Recommended Action"].some(
        (action) => action.toLowerCase() === item.key?.toLowerCase()
      )
    )
  : []; 

  return (
    <>
      {availableMitigations.length === 1 ? (
            <Button
              variant="contained"
              color="primary"
              disabled={false}
              sx={{
                background: "#5186EC 0% 0% no-repeat padding-box",
                borderRadius: "6px",
                backgroundColor: "#5186EC",
                fontSize: "13px",
                textTransform: "none",
                fontWeight: "400",
                lineHeight: "1", 
                margin: "0 auto",
                padding: "8px", 
                textAlign: "center", 
                whiteSpace: "normal", 
                wordBreak: "break-word", 
              }}
              onClick={() => handleMenuItemClick(availableMitigations[0].key)}
            >
              {availableMitigations[0].value}
            </Button>
          ) : availableMitigations.length > 1 ? (
        <Button
        sx={{
          borderRadius: "8px",
          textTransform: "none",
          fontSize: "12px",
          height: "30px",
          backgroundColor: "#5186EC",
          "&:hover": {
            bgcolor: "#5186EC",
          },
          width: "100%",
          maxWidth: "135px",
          margin: "0 auto",
        }}
        variant="contained"
        color="primary"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Mitigations
      </Button>):null}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "8px",
          },
        }}
      >
      {availableMitigations.length > 0 ? (
        availableMitigations.map((item, index) => (
          <div key={index}>
            <MenuItem
              onClick={() => handleMenuItemClick(item.key)}
              sx={{ fontFamily: "Rubik, sans-serif" }}
            >
              {item.value}
            </MenuItem>
            {availableMitigations.length - 1 !== index && <Divider />}
          </div>
        ))
      ) : null}
    </Menu>
    {
        
      open && (
        <SendRequestModal
          open={open}
          onClose={handleCloseSR}
        companyInfo={{address:"",domain:"",name:selectedCompany["Company Name"] ,id:""}}       
          fileType={fileType}
          navigationFrom={'table'}
        />
      )}
     
    </>
  );
};

const columns = [
  {
    field: "Company Name",
    headerName: "Company Name",
    type: "string",
    flex: 1.2,
    sortable: true,
    headerAlign: "left",
    align: "left",
    renderCell: (params) => (
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        sx={{ cursor: "pointer" }}
      >
        {params.row.isPublic && (
          <CircleIcon sx={{ color: "#3097D2", fontSize: 9, mr: 2 }} />
        )}
        <Typography
          noWrap
          sx={{
            fontFamily: "rubik",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "Paydex",
    type: "number",
    headerName: "Payment Index",
    flex: 0.5,
    align: "left",
    headerAlign: "left",
    sortable: true,
    renderHeader: (params) => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          sx={{
            whiteSpace: "normal",
            lineHeight: "1.5",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "rubik",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {params.colDef.headerName}
        </Typography>
        <Tooltip
          title="An indicator that measures the timeliness of a company's payments based on its historical payment behaviour. A higher score suggests delays or late payments while a lower score indicates prompt payments."
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#5186EC",
                color: "white",
                fontSize: "12px",
              },
            },
          }}
          placement="top"
        >
          <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
        </Tooltip>
      </Box>
    ),
    renderCell: (params) => {
      const paydex = params.row["Paydex"];
      
      const displayValue =
          paydex != null && parseFloat(params.row["Paydex"]) != 0
            ? normalizePaydex(params.row["Paydex"])
            : "-";
            let backgroundColor;
            let color;
            let smiley;
            if (displayValue >= 1 && displayValue < 4) {
              backgroundColor = "#60AC5033";
              color = "#3AA623";
              smiley = Green;
            } else if (displayValue >= 4 && displayValue < 8) {
              backgroundColor = "#FFB30033";
              color = "#9E6F00";
              smiley = Yellow;
            } else if (displayValue >= 8) {
              backgroundColor = "#F25A4833";
              color = "#F25A48";
              smiley = Red;
            } else {
              backgroundColor = "#f0f0f0";
            }
            
        return <Chip 
          label={displayValue} 
          style={{ 
              cursor: "default", 
              backgroundColor, 
              color, 
              borderRadius: "8px",
              width: "fit-content",
              height: "30px",
            }}
          />;
    },
  },
  {
    field: "Aging Arc score",
    type: "number",
    headerName: "Arc Score",
    flex: 0.5,
    align: "left",
    headerAlign: "left",
    sortable: true,
    renderHeader: (params) => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          sx={{
            whiteSpace: "normal",
            lineHeight: "1.5",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "rubik",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {params.colDef.headerName}
        </Typography>
        <Tooltip
          title="An indicator that measures the timeliness of a company's payments based on its Arc and Paydex scores. A higher score suggests delays or late payments while a lower score indicates prompt payments."
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#5186EC",
                color: "white",
                fontSize: "12px",
              },
            },
          }}
          placement="top"
        >
          <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
        </Tooltip>
      </Box>
    ),
    renderCell: (params) => {
      const paydex = params.row["Aging Arc score"];
      
      const displayValue =
          paydex != null && formatArcScore(params.row["Aging Arc score"]) != 0
            ? formatArcScore(params.row["Aging Arc score"])
            : "-";

            let backgroundColor;
            let color;
            let smiley;
            if (displayValue >= 1 && displayValue < 4) {
              backgroundColor = "#60AC5033";
              color = "#3AA623";
              smiley = Green;
            } else if (displayValue >= 4 && displayValue < 8) {
              backgroundColor = "#FFB30033";
              color = "#9E6F00";
              smiley = Yellow;
            } else if (displayValue >= 8) {
              backgroundColor = "#F25A4833";
              color = "#F25A48";
              smiley = Red;
            } else {
              backgroundColor = "#f0f0f0";
            }
            
        return <Chip 
          label={displayValue} 
          style={{ 
              cursor: "default", 
              backgroundColor, 
              color, 
              borderRadius: "8px",
              width: "fit-content",
              height: "30px",
            }}
          />;
    },
  },
  {
    field: "Recommended Limit",
    headerName: "Recommended Limit",
    type: "number",
    flex: 1,
    headerAlign: "left",
    align: "left",
    sortable: true,
    renderHeader: (params) => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          sx={{
            whiteSpace: "normal",
            lineHeight: "1.5",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "rubik",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {params.colDef.headerName}
        </Typography>
        <Tooltip
          title="Maximum Recommended Credit Limit for each company. Recommendations may change overtime or based on new information"
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#5186EC",
                color: "white",
                fontSize: "12px",
              },
            },
          }}
          placement="top"
        >
          <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
        </Tooltip>
      </Box>
    ),
    renderCell: (params) => (
      <Chip
        label={
          formatCurrency(params.row["Recommended Limit"])!==0
            ? formatCurrency(params.row["Recommended Limit"])
            : "-"
        }
        sx={{
          color: "#1A2A56",
          backgroundColor: "#ffffff",
          // backgroundColor: "#3753A033",
          // borderRadius: "8px",
          width: "fit-content",
        }}
      />
    ),
  },

  {
    field: "Current",
    headerName: "Current",
    type: "number",
    flex: 1,
    headerAlign: "left",
    align: "left",
    sortable: true,
    valueGetter: (params) => {
      const currentValue = params.row["Current AR Balance"];
      return currentValue ? formatCurrencyValue(currentValue) : 0;
    },
    renderHeader: (params) => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          sx={{
            whiteSpace: "normal",
            lineHeight: "1.5",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "rubik",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {params.colDef.headerName}
        </Typography>
        <Tooltip
          title="The portion of the Accounts Receivable (AR) that is still within the payment terms."
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#5186EC",
                color: "white",
                fontSize: "12px",
              },
            }}
          }
          placement="top"
        >
          <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
        </Tooltip>
      </Box>
    ),
    renderCell: (params) => {
      <div style={{cursor: "default"}}>{ formatCurrency(params.row["Current AR Balance"]) }</div>
    },
  },  
  {
    field: "AR in arrear",
    headerName: "AR in arrear",
    type: "number",
    flex: 1,
    headerAlign: "left",
    align: "left",
    sortable: true,
    valueGetter: (params) => {
      let arBalance15Value = (params.row["AR Balance Aging 15"]) || 0;
      let arBalance30Value = (params.row["AR Balance Aging 30"]) || 0;
      let arBalance45Value = (params.row["AR Balance Aging 45"]) || 0;
      let arBalance60Value = (params.row["AR Balance Aging 60"]) || 0;
      let arBalance60PlusValue = (params.row["AR Balance Aging 60+"]) || 0;
      let arBalance90Value = (params.row["AR Balance Aging 90"]) || 0;
      let arBalance90PlusValue = (params.row["AR Balance Aging 90+"]) || 0;
      let arBalance120Value = (params.row["AR Balance Aging 120"]) || 0;
      let arBalance120PlusValue = (params.row["AR Balance Aging 120+"]) || 0;
      let arBalance180Value = (params.row["AR Balance Aging 180"]) || 0;
      let arBalance180PlusValue = (params.row["AR Balance Aging 180+"]) || 0;
      let arBalance365Value = (params.row["AR Balance Aging 365"]) || 0;
      let arBalance365PlusValue = (params.row["AR Balance Aging 365+"]) || 0;
      const finalValue =
        parseFloat(arBalance15Value) +
        parseFloat(arBalance30Value) +
        parseFloat(arBalance45Value) +
        parseFloat(arBalance60Value) +
        parseFloat(arBalance60PlusValue) +
        parseFloat(arBalance90Value) +
        parseFloat(arBalance90PlusValue) +
        parseFloat(arBalance120Value) +
        parseFloat(arBalance120PlusValue) +
        parseFloat(arBalance180Value) +
        parseFloat(arBalance180PlusValue) +
        parseFloat(arBalance365Value) +
        parseFloat(arBalance365PlusValue);
      return finalValue;
    },
  
    renderHeader: (params) => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          sx={{
            whiteSpace: "normal",
            lineHeight: "1.5",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "rubik",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {params.colDef.headerName}
        </Typography>
        <Tooltip
          title="The Sum of receivables that are overdue"
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#5186EC",
                color: "white",
                fontSize: "12px",
              },
            }}
          }
          placement="top"
        >
          <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
        </Tooltip>
      </Box>
    ),
    renderCell: (params) => {
      return <div style={{ cursor: "default" }}>{formatCurrency(formatCurrencyValue(params.value))}</div>;
    },
  },  
  {
    field: "Total AR Balance",
    headerName: "Total AR Balance",
    type: "number",
    flex: 1,
    headerAlign: "left",
    align: "left",
    sortable: true,
    renderHeader: (params) => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          sx={{
            whiteSpace: "normal",
            lineHeight: "1.5",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "rubik",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {params.colDef.headerName}
        </Typography>
        <Tooltip
          title="The total amount of money that have been invoiced but not yet paid."
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#5186EC",
                color: "white",
                fontSize: "12px",
              },
            },
          }}
          placement="top"
        >
          <InfoRoundedIcon sx={{ fontSize: 16, mt: 0.4 }} />
        </Tooltip>
      </Box>
    ),
    renderCell: (params) => {
      return (
      <div style={{cursor: "default"}}>{ formatCurrency(params.row["Total AR Balance"]) }</div>
    )
    },
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    flex: 0,
    type: "date",
    headerAlign: "left",
    align: "left",
    filterable: true,
    hideable: true,
    hide: true,
    valueGetter: (params) => {
      if (!params.row.createdAt) return null;

      const date = new Date(params.row.createdAt);

      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },
    valueFormatter: (params) => {
      if (!params.value) return "";

      return params.value.toLocaleDateString();
    },
    renderCell: (params) => params.row.createdAtFormatted,
  },
  // {
  //   field: 'Rationale',
  //   headerName: 'Rationale',
  //   flex: 1.5,
  //   headerAlign: 'left',
  //   align: 'left',
  //   sortable: false,
  //   renderCell: (params) => {
  //     let reason = "";
  //     if (params.row['Recommended Limit'] == 2500000) {
  //       reason = "Limited based on Net Income from Continuing Operations Trend";
  //     } else {
  //       reason = params.row['Underwriting Reason'];
  //     }
  //     return (
  //       <div
  //       style={{
  //         whiteSpace: 'normal',
  //         overflowX: 'auto',
  //         overflowY: 'hidden',
  //         width: '100%',
  //         wordWrap: 'break-word',
  //         fontFamily: 'Rubik',
  //         fontStyle: 'normal',
  //         fontSize: '14px',
  //         padding: '1px 5px',
  //         lineHeight:"13.5px",
  //         cursor:"default"
  //       }}
  //       className="custom-scrollbar"
  //     >
  //         {reason}
  //       </div>
  //     );
  //   },
  // },
  // {
  //   field: 'actions',
  //   headerName: 'Recommended Actions',
  //   headerAlign: 'left',
  //   align: 'left',
  //   flex: 1.3,
  //   sortable: false,
  //   renderCell: (params) => <ActionsMenu row={params.row} />,
  // },
];

const prospectsColumns = [
  {
    field: "Company Name",
    headerName: "Company Name",
    type: "string",
    flex: 1.2,
    sortable: true,
    headerAlign: "left",
    align: "left",
    renderCell: (params) => (
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        sx={{ cursor: "pointer" }}
      >
        {params.row.isPublic && (
          <CircleIcon sx={{ color: "#3097D2", fontSize: 9, mr: 2 }} />
        )}
        <Typography
          noWrap
          sx={{
            fontFamily: "rubik",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          {params.value}
        </Typography>
      </Box>
    ),
  },
  {
    field: "Requested Limit",
    type: "number",
    headerName: "Amount Requested",
    flex: 0.8,
    align: "left",
    headerAlign: "left",
    sortable: true,
    valueGetter: (params) => {
      const amountRequested = params.row["Requested Limit"];
      return amountRequested != null ? parseFloat(amountRequested) : null;
    },
    renderCell: (params) => {
      return <div style={{ cursor: "default" }}>{formatCurrency(params.row["Requested Limit"])!=="$0"?formatCurrency(params.row["Requested Limit"]):"-"}</div>;
    },
  },
  {
    field: "Recommended Limit",
    headerName: "Recommended Limit",
    type: "number",
    flex: 1,
    headerAlign: "left",
    align: "left",
    sortable: true,
    renderHeader: (params) => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          sx={{
            whiteSpace: "normal",
            lineHeight: "1.5",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "rubik",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {params.colDef.headerName}
        </Typography>
        <Tooltip
          title="Maximum Recommended Credit Limit for each company. Recommendations may change overtime or based on new information"
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#5186EC",
                color: "white",
                fontSize: "12px",
              },
            },
          }}
          placement="top"
        >
          <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
        </Tooltip>
      </Box>
    ),
    renderCell: (params) => (
      <Chip
        label={
          formatCurrency(params.row["Recommended Limit"])!=="$0"?formatCurrency(params.row["Recommended Limit"]):"-"
        }
        sx={{
          color: "#1A2A56",
          backgroundColor: "#3753A033",
          borderRadius: "8px",
          width: "fit-content",
        }}
      />
    ),
  },
  {
    field: "Combined Score",
    headerName: "Arc Score",
    flex: 0.8,
    headerAlign: "left",
    type: "number",
    align: "left",
    sortable: true,
    valueGetter: (params) => {
      const combinedScore = params.row["Aging Arc score"] ? params.row["Aging Arc score"] : params.row["Combined Score"];
      const displayScore =
        !isNaN(combinedScore) && isFinite(combinedScore)
          ? formatArcScore(combinedScore)
          : 0;
      return displayScore;
    },
    renderHeader: (params) => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          sx={{
            whiteSpace: "normal",
            lineHeight: "1.5",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "rubik",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {params.colDef.headerName}
        </Typography>
        <Tooltip
          title="Arc Score represents the overall credit risk of a company. Like all other scores on our platform, it is scaled from 1 - 10 (1 being the safest score)."
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#5186EC",
                color: "white",
                fontSize: "12px",
              },
            },
          }}
          placement="top"
        >
          <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
        </Tooltip>
      </Box>
    ),
    renderCell: (params) => {
      const combinedScore = params.row["Aging Arc score"] ? params.row["Aging Arc score"] : params.row["Combined Score"];
      const displayScore =
        !isNaN(combinedScore) && isFinite(combinedScore)
          ? formatArcScore(combinedScore)
          : "-";
      let backgroundColor;
      let color;
      let smiley;
      if (displayScore >= 1 && displayScore < 4) {
        backgroundColor = "#60AC5033";
        color = "#3AA623";
        smiley = Green;
      } else if (displayScore >= 4 && displayScore < 8) {
        backgroundColor = "#FFB30033";
        color = "#9E6F00";
        smiley = Yellow;
      } else if (displayScore >= 8) {
        backgroundColor = "#F25A4833";
        color = "#F25A48";
        smiley = Red;
      } else {
        backgroundColor = "#f0f0f0";
      }

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            margin: "0 auto",
          }}
        >
          <Chip
            label={displayScore}
            sx={{
              color: color,
              backgroundColor: backgroundColor,
              borderRadius: "8px",
              width: "fit-content",
              height: "30px",
            }}
          />

          <img src={smiley} style={{ width: "15px" }} alt="" />
        </div>
      );
    },
  },
  {
    field: "Underwriting Reason",
    headerName: "Rationale",
    flex: 1.5,
    headerAlign: "left",
    align: "left",
    sortable: false,
    renderCell: (params) => {
      console.log("params", params);
      let reason = "";
      if (params.row["Recommended Limit"] == 2500000) {
        reason = "Limited based on Net Income from Continuing Operations Trend";
      } else {
        reason = params.row["Underwriting Reason"];
      }
      return (
        <div
          style={{
            whiteSpace: "normal",
            overflowX: "auto",
            overflowY: "hidden",
            width: "100%",
            wordWrap: "break-word",
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontSize: "14px",
            padding: "1px 5px",
            lineHeight: "13.5px",
            cursor: "default",
          }}
          className="custom-scrollbar"
        >
          {reason}
        </div>
      );
    },
  },
  {
    field: "actions",
    headerName: "Recommended Actions",
    headerAlign: "left",
    align: "left",
    flex: 1.3,
    sortable: false,
    filterable: false,
    renderCell: (params) => <ActionsMenu row={params.row} />,
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    flex: 0,
    type: "date",
    headerAlign: "left",
    align: "left",
    filterable: true,
    hideable: true,
    hide: true,
    valueGetter: (params) => {
      if (!params.row.createdAt) return null;

      const date = new Date(params.row.createdAt);

      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },
    valueFormatter: (params) => {
      if (!params.value) return "";

      return params.value.toLocaleDateString();
    },
    renderCell: (params) => params.row.createdAtFormatted,
  },
];

const preprocessData = (row) => {
  if (row && row.startsWith("Net ")) {
    row = row.substring(4);
  }
  return parseInt(row) || "-";
};

const generateCSVRows = (rows, columns, isProspects, selectedTab, agingDays) => {
  let selectedColumns;

  if (isProspects) {
    selectedColumns = [
      "Company Name",
      "Requested Limit",
      "Recommended Limit",
      "Combined Score", 
      "Underwriting Reason", 
      "createdAt"
    ];
  } else if (selectedTab === 0) {
    selectedColumns = [
      "Company Name",
      "Paydex", 
      "Aging Arc score", 
      "Recommended Limit",
      "Underwriting Reason", 
      "Current AR Balance", 
      "AR in arrear",
      "Total AR Balance",
      "createdAt"
    ];
  } else {
    selectedColumns = [
      "Company Name",
      "Paydex", 
      "Aging Arc score",
      "Total AR Balance",
      "Current AR Balance", 
      `AR Balance Aging ${agingDays.age1}`,
      `AR Balance Aging ${agingDays.age2}`,
      `AR Balance Aging ${agingDays.age3}`,
      "Underwriting Reason", 
      "createdAt"
    ];
  }

  const csvRows = rows.map(row => {
    return selectedColumns.map(colName => {
      let value = row[colName];
      
      switch (colName) {
        case "createdAt":
          return value ? new Date(value).toLocaleDateString() : "";
        case "Requested Limit":
        case "Recommended Limit":
        case "Total AR Balance":
        case "Current AR Balance":
          return formatCurrency(value);
        case "Paydex":
        case "Aging Arc score":
          return value ? formatArcScore(value) : "-";
        case "AR in arrear":
          let total = 0;
          ["15", "30", "45", "60", "60+", "90", "90+", "120", "120+", "180", "180+", "365", "365+"].forEach(age => {
            total += formatCurrencyValue(row[`AR Balance Aging ${age}`]) || 0;
          });
          return formatCurrency(total);
        case `AR Balance Aging ${agingDays.age1}`:
        case `AR Balance Aging ${agingDays.age2}`:
        case `AR Balance Aging ${agingDays.age3}`:
          const processedData = processAgingData(row, agingDays);
          const agingKey = colName.replace('AR Balance Aging ', '');
          return formatCurrency(processedData[agingKey] || 0);
        default:
          return value;
      }
    });
  });

  const headers = selectedColumns.map(colName => {
    switch (colName) {
      case "Paydex":
        return "Payment Index";
      case "Aging Arc score":
        return "ARC Score";
      case "Current AR Balance":
        return "Current";
      case "Underwriting Reason":
        return "Rationale";
      case "createdAt":
        return "Created Date";
      case "Combined Score":
        return "ARC Score";
      case "Requested Limit":
        return "Amount Requested";
      case `AR Balance Aging ${agingDays.age1}`:
        return `Aging ${agingDays.age1}`;
      case `AR Balance Aging ${agingDays.age2}`:
        return `Aging ${agingDays.age2}`;
      case `AR Balance Aging ${agingDays.age3}`:
        return `Aging ${agingDays.age3}`;
      default:
        return colName;
    }
  });

  return [headers, ...csvRows];
};

const CustomExportButton = ({ rows, columns, isProspects, selectedTab, agingDays }) => {
  const [csvData, setCsvData] = useState([]);
  const apiRef = useGridApiContext();

  const handleExport = () => {
    const filteredRowIds = gridFilteredSortedRowIdsSelector(apiRef.current.state);
    const filtermodel = gridFilterModelSelector(apiRef.current.state);
    const filteredRows = filteredRowIds.map((id) =>
      rows.find((row) => row.id === id)
    );
    const exportData = filtermodel.items.length ? filteredRows : rows;
    setCsvData(generateCSVRows(exportData, columns, isProspects, selectedTab, agingDays));
  };

  return (

      <CSVLink
        data={csvData}
        filename="CrediArc.csv"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Button onClick={handleExport} startIcon={<DownloadIcon />}>
          Export
        </Button>
      </CSVLink>
  );
};

const CustomToolbar = ({
  searchQuery,
  handleInputChange,
  handleSearch,
  rows,
  handleDensityChange,
  handleFilterModelChange,
  setGridRef,
  paginationModel,
  isProspects,
  selectedTab,
  agingDays
}) => {
  const textFieldRef = useRef(null);
  const apiRef = useGridApiContext();
  const density = useGridSelector(apiRef, gridDensityValueSelector);

  setGridRef(apiRef);

  useEffect(() => {
    console.log("apiref", apiRef);
  }, [apiRef]);
  useEffect(() => {
    if (searchQuery && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [searchQuery]);
  useEffect(() => {
    handleDensityChange(density);
  }, [density, handleDensityChange, paginationModel]);

  useEffect(() => {
    handleFilterModelChange(apiRef.current.state.filter.filterModel.items);
  }, [apiRef.current.state.filter]);

  const searchStyle = {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "8px",
    opacity: 1,
    border: "none",
    width: "220px",
    height: "36px",
    paddingLeft: "10px",
    color: "#2F3D63",
    outline: "none",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "& .MuiInputLabel-root": {
        transform: "translate(0, -1.5rem) scale(0.75)",
      },
    },
  };

  return (
    <GridToolbarContainer
      sx={{ display: "flex", mb: 2, justifyContent: "space-between" }}
    >
      <Box>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <CustomExportButton 
          rows={rows} 
          columns={columns} 
          isProspects={isProspects} 
          selectedTab={selectedTab}
          agingDays={agingDays}
        />
      </Box>
      <TextField
        label="Search"
        // variant="outlined"
        size="small"
        sx={searchStyle}
        inputRef={textFieldRef}
        value={searchQuery}
        // useCallback((event) => handleInputChange(event), []
        onChange={(event) => handleInputChange(event)}
        style={{ marginBottom: 16 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearch}>
                <img
                  src={newSearchIcon}
                  alt="search icon"
                  width="28"
                  height="28"
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </GridToolbarContainer>
  );
};

const RecommendationTable = ({
  rows,
  riskyRows,
  showAtRisk,
  setShowAtRisk,
  filteredSummary,
  filteredProspectsValue,
  tableName,
  tenantIdp,
}) => {
  const [page, setPage] = useState(1);
  const [riskyPage, setRiskyPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [searchRiskyPage, setSearchRiskyPage] = useState(1);
  const [pageSize, setPageSize] = useState(7);
  const [pageSizeRisky, setPageSizeRisky] = useState(5);
  const [paginationModel, setPaginationModel] = useState({
    pageSize,
    page: 0,
  });
  const [riskyPaginationModel, setRiskyPaginationModel] = useState({
    pageSizeRisky,
    page: 0,
  });
  const [displayedRows, setDisplayedRows] = React.useState([]);
  const [displayedRiskyRows, setDisplayedRiskyRows] = React.useState([]);
  const [totalRows, setTotalRows] = useState(rows);
  const [totalRiskyRows, setTotalRiskyRows] = useState(riskyRows);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchRiskyQuery, setSearchRiskyQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [density, setDensity] = useState("standard");
  const [filters, setfilters] = useState([]);
  const { isProspects, setProspects } = useProspectsAR();
  const [isCollectionAtRisk, setCollectionAtRisk] = useState(false);
  const [agingDays, setAgingDays] = useState({
    age1: "30",
    age2: "60",
    age3: "90",
  });
  const [tenantId, setTenantId] = useState();
  const [gridRef, setGridRef] = useState({});
  const isAdmin = useSelector((state) => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenant = useSelector((state) => state.userInfo.tenantId);
  const selectTenantId = () => {
    if (isAdmin) setTenantId(URLTenant);
    else setTenantId(currentTenant);
  };
  const { userPreferences, updateUserPreferences, loading } = useUserPreferences();

  useEffect(() => {
    selectTenantId();
    if(!loading){
      const agingPreferences = userPreferences?.agingDays
      if(agingPreferences) setAgingDays(agingPreferences)
    }
    if (filteredSummary) {
      setProspects(filteredProspectsValue);
    }
  }, [loading, filteredSummary]);

  useEffect(() => {
    if (selectedTab === 1) {
      setCollectionAtRisk(true);
    } else {
      setCollectionAtRisk(false);
    }
  }, [selectedTab]);

  useEffect(() => {
    console.log("rows in recco", rows);
    setTotalRows(rows);
    gridRef?.current?.setFilterModel({ items: [] });
  }, [isProspects]);

  // useEffect(() => {
  //    riskyRows.sort((a, b) => b["Combined Score"] - a["Combined Score"]);

  // }, [riskyRows]);

  const handleInputChange = (event) => {
    console.log(event.target.value, "85858");
    setSearchQuery(event.target.value);
  };

  React.useEffect(() => {
    if (showAtRisk) {
      setSelectedTab(1);
    }
  }, [showAtRisk]);

  const pageSizeForDensity = {
    comfortable: 5,
    standard: 7,
    compact: 10,
  };

  const handleRiskyInputChange = (event) => {
    console.log(event.target.value, "85858");
    setSearchRiskyQuery(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    if (newValue == 0) setShowAtRisk(false);
    setSelectedTab(newValue);
  };

  const handleCellClick = async (params) => {
    if (params.field === "Company Name") {
      let company = await getCompanyData(params.row, tenantId);
  
      if (company) {
        if (isAdmin) {
          window.open(
            `/tenant-dashboard/${tenantId}/company/${company.id}`,
            "_blank"
          );
        } else {
          window.open(`/company/${company.id}`, "_blank");
        }
      } else {
        console.log("No company found with provided Account ID or Company Name.");
      }
    }
  };

  const handleDensityChange = useCallback((density) => {
    console.log("Density Changed -->", density);
    const newPageSize = pageSizeForDensity[density];
    setPageSize(newPageSize);
    setPageSizeRisky(newPageSize);
    if(newPageSize!==pageSize){
      setPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: newPageSize,
        page: 0,
      }));
      setRiskyPaginationModel((prevModel) => ({
        ...prevModel,
        pageSize: newPageSize,
        page: 0,
      }));
    }
    setDensity(density)
  }, [pageSize, pageSizeForDensity]);

  React.useEffect(() => {
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    setDisplayedRows(rows.slice(start, end));
    console.log("displayes rows", displayedRows);
  }, [page, pageSize, rows, agingDays]);

  React.useEffect(() => {
    const start = (riskyPage - 1) * pageSizeRisky;
    const end = start + pageSizeRisky;
    setDisplayedRiskyRows(riskyRows.slice(start, end));
  }, [riskyPage, pageSizeRisky, riskyRows]);

  const handlePageChange = (event, value) => {
    if (searchQuery) {
      setSearchPage(value);
    } else {
      setPage(value);
    }
  };

  const handleRiskyPageChange = (event, value) => {
    if (searchRiskyQuery) {
      setSearchRiskyPage(value);
    } else {
      setRiskyPage(value);
    }
  };

  const handleSearch = () => {
    if (!searchQuery) {
      // Reset to the original rows if the search query is empty
      setTotalRows(rows);
      const start = (searchPage - 1) * pageSize;
      const end = start + pageSize;
      setDisplayedRows(rows.slice(start, end));
      return;
    }

    const filteredRows = rows.filter((row) => {
      const query = searchQuery.toLowerCase();
      const companyName = row["Company Name"].toString().toLowerCase();
      return companyName.includes(query);
    });

    setTotalRows(filteredRows);

    const start = (searchPage - 1) * pageSize;
    const end = start + pageSize;
    setDisplayedRows(filteredRows.slice(start, end));
  };

  const handleRiskySearch = () => {
    if (!searchRiskyQuery) {
      // Reset to the original riskyRows if the search query is empty
      setTotalRiskyRows(riskyRows);
      const start = (searchRiskyPage - 1) * pageSizeRisky;
      const end = start + pageSizeRisky;
      setDisplayedRiskyRows(riskyRows.slice(start, end));
      return;
    }

    const filteredRows = riskyRows.filter((row) => {
      const query = searchRiskyQuery.toLowerCase();
      const companyName = row["Company Name"].toString().toLowerCase();
      return companyName.includes(query);
    });

    setTotalRiskyRows(filteredRows);

    const start = (searchRiskyPage - 1) * pageSizeRisky;
    const end = start + pageSizeRisky;
    setDisplayedRiskyRows(filteredRows.slice(start, end));
  };
  React.useEffect(() => {
    if (searchQuery) {
      handleSearch();
    } else {
      const end = pageSize;
      setDisplayedRows(rows.slice(0, end));
      setTotalRows(rows);
      setPage(1);
      setSearchPage(1);
    }
  }, [searchPage, searchQuery]);

  React.useEffect(() => {
    if (searchRiskyQuery) {
      handleRiskySearch();
    } else {
      const end = pageSizeRisky;
      setDisplayedRiskyRows(riskyRows.slice(0, end));
      setTotalRiskyRows(riskyRows);
      setRiskyPage(1);
      setSearchRiskyPage(1);
    }
  }, [searchRiskyPage, searchRiskyQuery]);

  const handleFilterModelChange = (value) => {
    console.log("filter value", value);
    setfilters(value);
  };

  const riskyColumns = [
    {
      field: "Company Name",
      headerName: "Company Name",
      type: "string",
      flex: 1.2,
      sortable: true,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          sx={{ cursor: "pointer" }}
        >
          {params.row.isPublic && (
            <CircleIcon sx={{ color: "#3097D2", fontSize: 9, mr: 2 }} />
          )}
          <Typography
            noWrap
            sx={{
              fontFamily: "rubik",
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "Paydex",
      type: "number",
      headerName: "Payment Index",
      flex: 0.8,
      align: "left",
      headerAlign: "left",
      sortable: true,
      renderHeader: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            sx={{
              whiteSpace: "normal",
              lineHeight: "1.5",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "rubik",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {params.colDef.headerName}
          </Typography>
          <Tooltip
            title="An indicator that measures the timeliness of a company's payments based on its historical payment behaviour. A higher score suggests delays or late payments while a lower score indicates prompt payments."
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#5186EC",
                  color: "white",
                  fontSize: "12px",
                },
              },
            }}
            placement="top"
          >
            <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
          </Tooltip>
        </Box>
      ),
      renderCell: (params) => {
        const paydex = params.row["Paydex"];
        const displayValue =
          paydex != null && parseFloat(params.row["Paydex"]) != 0
            ? normalizePaydex(params.row["Paydex"])
            : "-";
            let backgroundColor;
            let color;
            let smiley;
            if (displayValue >= 1 && displayValue < 4) {
              backgroundColor = "#60AC5033";
              color = "#3AA623";
              smiley = Green;
            } else if (displayValue >= 4 && displayValue < 8) {
              backgroundColor = "#FFB30033";
              color = "#9E6F00";
              smiley = Yellow;
            } else if (displayValue >= 8) {
              backgroundColor = "#F25A4833";
              color = "#F25A48";
              smiley = Red;
            } else {
              backgroundColor = "#f0f0f0";
            }
        return <Chip 
          label={displayValue} 
          style={{ 
              cursor: "default", 
              backgroundColor, 
              color, 
              borderRadius: "8px",
              width: "fit-content",
              height: "30px",
            }}
          />;
      },
    },
    {
      field: "Aging Arc score",
      headerName: "Arc Score",
      flex: 0.8,
      headerAlign: "left",
      type: "number",
      align: "left",
      sortable: true,
      valueGetter: (params) => {
        const combinedScore = params.row["Aging Arc score"];
        const displayScore =
          !isNaN(combinedScore) && isFinite(combinedScore)
            ? formatArcScore(combinedScore)
            : 0;
        return displayScore;
      },
      renderHeader: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            sx={{
              whiteSpace: "normal",
              lineHeight: "1.5",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "rubik",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {params.colDef.headerName}
          </Typography>
          <Tooltip
            title="Arc Score represents the overall credit risk of a company. Like all other scores on our platform, it is scaled from 1 - 10 (1 being the safest score)."
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#5186EC",
                  color: "white",
                  fontSize: "12px",
                },
              },
            }}
            placement="top"
          >
            <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
          </Tooltip>
        </Box>
      ),
      renderCell: (params) => {
        const combinedScore = params.row["Aging Arc score"];
        const displayScore =
          !isNaN(combinedScore) && isFinite(combinedScore)
            ? formatArcScore(combinedScore)
            : "-";
        let backgroundColor;
        let color;
        let smiley;
        if (displayScore >= 1 && displayScore < 4) {
          backgroundColor = "#60AC5033";
          color = "#3AA623";
          smiley = Green;
        } else if (displayScore >= 4 && displayScore < 8) {
          backgroundColor = "#FFB30033";
          color = "#9E6F00";
          smiley = Yellow;
        } else if (displayScore >= 8) {
          backgroundColor = "#F25A4833";
          color = "#F25A48";
          smiley = Red;
        } else {
          backgroundColor = "#f0f0f0";
        }

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              margin: "0 auto",
            }}
          >
            <Chip
              label={constrainValue(displayScore, 1, 10)}
              sx={{
                color: color,
                backgroundColor: backgroundColor,
                borderRadius: "8px",
                width: "fit-content",
                height: "30px",
              }}
            />

            <img src={smiley} style={{ width: "15px" }} alt="" />
          </div>
        );
      },
    },
    {
      field: "Total AR Balance",
      headerName: "Total AR Balance",
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: true,
      renderHeader: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            sx={{
              whiteSpace: "normal",
              lineHeight: "1.5",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "rubik",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {params.colDef.headerName}
          </Typography>
          <Tooltip
            title="The total amount of money that have been invoiced but not yet paid."
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#5186EC",
                  color: "white",
                  fontSize: "12px",
                },
              },
            }}
            placement="top"
          >
            <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
          </Tooltip>
        </Box>
      ),
      renderCell: (params) => (
        <Chip
        label={formatCurrency(params.row["Total AR Balance"])}
          sx={{
            color: "#1A2A56",
            backgroundColor: "#ffffff",
            // backgroundColor: "#3753A033",
            // borderRadius: "8px",
            width: "fit-content",
          }}
        />
      ),
    },
    {
      field: "Current AR Balance",
      headerName: "Current",
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: true,
      renderHeader: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            sx={{
              whiteSpace: "normal",
              lineHeight: "1.5",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "rubik",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {params.colDef.headerName}
          </Typography>
          <Tooltip
            title="The portion of the Accounts Receivable (AR) that is still within the payment terms."
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#5186EC",
                  color: "white",
                  fontSize: "12px",
                },
              },
            }}
            placement="top"
          >
            <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
          </Tooltip>
        </Box>
      ),
      renderCell: (params) => (
        <Chip
        label={formatCurrency(params.row["Current AR Balance"])}
          sx={{
            color: "#1A2A56",
            backgroundColor: "#ffffff",
            // backgroundColor: "#3753A033",
            // borderRadius: "8px",
            width: "fit-content",
          }}
        />
      ),
    },
    {
      field: `AR Balance Aging ${agingDays.age1}`,
      headerName: `Aging ${agingDays.age1}`,
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: true,
      valueGetter: (params) => {
        const processedData = processAgingData(params.row, agingDays);
        return processedData[agingDays.age1] || 0;
      },
      renderHeader: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            sx={{
              whiteSpace: "normal",
              lineHeight: "1.5",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "rubik",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {params.colDef.headerName}
          </Typography>
          <Tooltip
            title={`The amount of receivables that are up to ${agingDays.age1} days past the due date.`}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#5186EC",
                  color: "white",
                  fontSize: "12px",
                },
              },
            }}
            placement="top"
          >
            <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
          </Tooltip>
        </Box>
      ),
      renderCell: (params) => {
        const value = params.value || 0;
        return (
        <Chip
        label={formatCurrency(value)}
          sx={{
            color: "#1A2A56",
            backgroundColor: "#ffffff",
            width: "fit-content",
          }}
        />
        )},
    },
    {
      field: `AR Balance Aging ${agingDays.age2}`,
      headerName: `Aging ${agingDays.age2}`,
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: true,
      valueGetter: (params) => {
        const processedData = processAgingData(params.row, agingDays);
        return processedData[agingDays.age2] || 0;
      },
      renderHeader: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            sx={{
              whiteSpace: "normal",
              lineHeight: "1.5",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "rubik",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {params.colDef.headerName}
          </Typography>
          <Tooltip
            title={`The amount of receivables that are ${parseInt(agingDays.age1) +1} to ${agingDays.age2} days past the due date.`}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#5186EC",
                  color: "white",
                  fontSize: "12px",
                },
              },
            }}
            placement="top"
          >
            <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
          </Tooltip>
        </Box>
      ),
      renderCell: (params) => {
        const value = params.value || 0;
        return (
          <Chip
          label={formatCurrency(value)}
            sx={{
              color: "#1A2A56",
              backgroundColor: "#ffffff",
              width: "fit-content",
            }}
          />
        );
      },
    },
    {
      field: `AR Balance Aging ${agingDays.age3}`,
      headerName: `Aging ${agingDays.age3}`,
      type: "number",
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: true,
      valueGetter: (params) => {
        const processedData = processAgingData(params.row, agingDays);
        return processedData[agingDays.age3] || 0;
      },
      renderHeader: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            sx={{
              whiteSpace: "normal",
              lineHeight: "1.5",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "rubik",
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {params.colDef.headerName}
          </Typography>
          <Tooltip
            title={`The amount of receivables that are ${parseInt(agingDays.age2) +1} to ${agingDays.age3} days past the due date.`}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#5186EC",
                  color: "white",
                  fontSize: "12px",
                },
              },
            }}
            placement="top"
          >
            <InfoRoundedIcon sx={{ fontSize: 16, marginLeft:"5px" }} />
          </Tooltip>
        </Box>
      ),
      renderCell: (params) => {
        const value = params.value || 0;
        return (
          <Chip
            label={formatCurrency(value)}
            sx={{
              color: "#1A2A56",
              backgroundColor: "#ffffff",
              width: "fit-content",
            }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Recommended Actions",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => <ActionsMenu row={params.row} />,
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      flex: 0,
      type: "date",
      headerAlign: "left",
      align: "left",
      filterable: true,
      hideable: true,
      hide: true,
      valueGetter: (params) => {
        if (!params.row?.createdAt) return null;

        const date = new Date(params.row?.createdAt);

        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
      },
      valueFormatter: (params) => {
        if (!params.value) return "";

        return params.value.toLocaleDateString();
      },
      renderCell: (params) => params.row.createdAtFormatted,
    },
  ];

  const getDetailPanelHeight = () => (isProspects ? 100 : 200);

  const getDetailPanelContent = (params, isCollectionAtRisk, isProspects) => {
    const { row } = params;

    const isValidScore = (score) => !!score;
    const processedAgingData = processAgingData(row, agingDays);

    return (
      <Box>
        {isProspects ? null : (
          <>
            {/* AR Balance Aging Section */}
            {!isCollectionAtRisk && !isProspects && (
              <Grid container sx={{ mt: 1, paddingLeft: "30px", height: 75 }}>
                <Grid
                  item
                  sm={2}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontSize: "14px",
                        fontWeight: 600,
                        mr: 2,
                      }}
                    >
                      AR Balance
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={10}>
                  <Box
                    container
                    spacing={1}
                    sx={{
                      height: "100%",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                      margin: "auto 0",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    {[
                      {
                        label: `Current`,
                        value: row[`Current AR Balance`],
                      },
                      {
                        label: `Aging ${agingDays.age1}`,
                        value: processedAgingData[agingDays.age1],
                      },
                      {
                        label: `Aging ${agingDays.age2}`,
                        value: processedAgingData[agingDays.age2],
                      },
                      {
                        label: `Aging ${agingDays.age3}`,
                        value: processedAgingData[agingDays.age3],
                      },
                      {
                        label: `Total AR Balance`,
                        value: row[`Total AR Balance`],
                      },
                    ].map(
                      ({ label, value }) =>
                        value != null && (
                          <Box item xs={4} key={label}>
                            <Box display="flex" alignItems="center">
                              <Typography
                                sx={{
                                  fontFamily: "Rubik",
                                  fontStyle: "normal",
                                  fontSize: "14px",
                                  width: "max-content",
                                  marginRight: "5px",
                                }}
                              >
                                {label}:
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "Rubik",
                                  fontStyle: "normal",
                                  fontSize: "14px",
                                  width: "max-content",
                                  marginRight: "5px",
                                }}
                              >
                                {formatCurrency(value)}
                              </Typography>
                            </Box>
                          </Box>
                        )
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
            {/* Recommended Limit Section */}
            {isCollectionAtRisk && !isProspects && (
              <Grid container sx={{ mt: 2, paddingLeft: "30px", height: 75 }}>
                <Grid
                  item
                  sm={2}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        fontSize: "14px",
                        fontWeight: 600,
                        mr: 2,
                      }}
                    >
                      Recommendations
                    </Typography>
                  </Box>
                </Grid>
                {[
                  {
                    label: "Recommended Limit",
                    value: row["Recommended Limit"],
                  },
                  {
                    label: "Payment Terms",
                    value: preprocessData(row["Recommended Net"]),
                  },
                ].map(
                  ({ label, value }) =>
                    value != null && (
                      <Grid item xs={5} key={label}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ height: "100%" }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Rubik",
                              fontStyle: "normal",
                              fontSize: "14px",
                              width: "max-content",
                              marginRight: "5px",
                            }}
                          >
                            {label}:
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Rubik",
                              fontStyle: "normal",
                              fontSize: "14px",
                              width: "max-content",
                              marginRight: "5px",
                            }}
                          >
                            {parseInt(value)
                              ? parseInt(value).toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                  maximumFractionDigits: 0,
                                })
                              : value}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                )}
              </Grid>
            )}
            <Divider />
          </>
        )}
        <Grid container mt={3} sx={{ padding: "0 10px", height: 75 }}>
          {/* Arc Score Section */}
          <Grid
            item
            sm={3}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontFamily: "Rubik",
                  fontStyle: "normal",
                  fontSize: "14px",
                  fontWeight: 600,
                  mr: 2,
                }}
              >
                Arc Score
              </Typography>
              <Box height="60px" width="60px">
                <DoughnutChart row={row} />
              </Box>
            </Box>
          </Grid>
          <Grid item sm={9}>
            <Grid
              container
              spacing={1}
              sx={{ overflowX: "auto", whiteSpace: "nowrap" }}
            >
              {[
                {
                  label: "Risk Segment",
                  color: "#2F3D63",
                  value: row["Risk Segment"],
                },
                {
                  label: "Alternative Score",
                  color: "#5186EC",
                  value: row["Alternative Score"],
                },
                {
                  label: "Deliquency Score",
                  color: "#F25A48",
                  value: row["Deliquency Score"],
                },
                {
                  label: "Legal Score",
                  color: "#3AA623",
                  value: row["Legal Score"],
                },
                {
                  label: "Failure Score",
                  color: "#E7BF3C",
                  value: row["Failure Score"],
                },
                {
                  label: "Sector Score",
                  color: "#3AA629",
                  value: row["Sector Score"],
                },
                {
                  label: "Country Score",
                  color: "#2F3D63",
                  value: row["Country Score"],
                },
                {
                  label: "Altman Z Score",
                  color: "#F248B4",
                  value: row["Altman Z Score"],
                },
              ].map(
                ({ label, color, value }) =>
                  isValidScore(value) &&
                  !isNaN(value) && (
                    <Grid item xs={4} key={label}>
                      <Box display="flex" alignItems="center">
                        <CircleIcon
                          sx={{
                            width: 10,
                            height: 10,
                            position: "relative",
                            right: 5,
                            color,
                          }}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Rubik",
                            fontStyle: "normal",
                            fontSize: "14px",
                            width: "max-content",
                            marginRight: "5px",
                          }}
                        >
                          {label}:
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Rubik",
                            fontStyle: "normal",
                            fontSize: "14px",
                            width: "max-content",
                            marginRight: "5px",
                          }}
                        >
                          {value}
                        </Typography>
                      </Box>
                    </Grid>
                  )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Card
      variant="outlined"
      style={{
        height: "fit-content",
        margin: "15px 0px 0px",
        padding: "10px 10px 10px 10px",
        boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
        position: "relative",
      }}
    >
      <div style={{ height: "100%", width: "100%" }}>
        {filteredSummary ? (
          <Typography
            variant="h5"
            sx={{
              fontWeight: 500,
              color: "#1A2B56",
              margin: "0 auto",
              width: "100%",
            }}
          >
            {tableName}
          </Typography>
        ) : (
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: ColorEnum.DARK_BLUE,
                height: 4,
                borderRadius: "8px",
              },
            }}
            sx={{
              borderBottom: 0.1,
              borderBottomColor: "#dbd9d9",
              mb: 2,
            }}
          >
            <Tab
              label={
                isProspects === false
                  ? "AR Summary"
                  : "Credit Ratings & Recommendations"
              }
              style={{
                textTransform: "capitalize",
                color: selectedTab === 0 ? "#1A2A56" : "grey",
                fontWeight: selectedTab === 0 ? "500" : "400",
                fontSize: "15px",
              }}
            />
            <Tab
              label={
                isProspects === false
                  ? "Collections at Risk"
                  : "Customers at Risk"
              }
              style={{
                textTransform: "capitalize",
                color: selectedTab === 1 ? "#1A2A56" : "grey",
                fontWeight: selectedTab === 1 ? "500" : "400",
                fontSize: "15px",
              }}
            />
          </Tabs>
        )}
        {selectedTab === 0 && (
          <DataGridPremium
            rows={searchQuery ? totalRows : rows}
            columns={isProspects ? prospectsColumns : columns}
            getRowId={(row) => parseInt(row.id)}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={(params) =>
              getDetailPanelContent(
                params,
                isCollectionAtRisk,
                filteredSummary ? filteredProspectsValue : isProspects
              )
            }
            onCellClick={handleCellClick}
            disableColumnMenu={true}
            disableColumnResize={false}
            onFilterModelChange={handleFilterModelChange}
            pagination
            initialState={{
              columns: {
                columnVisibilityModel: {
                  createdAt: false,
                },
                pagination: {
                  paginationModel: { pageSize: 9, page: 0 },
                },
              },
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            slots={{
              toolbar: () => (
                <CustomToolbar
                  searchQuery={searchQuery}
                  handleInputChange={handleInputChange}
                  handleSearch={handleSearch}
                  rows={searchQuery ? totalRows : rows}
                  handleDensityChange={handleDensityChange}
                  handleFilterModelChange={handleFilterModelChange}
                  setGridRef={setGridRef}
                  paginationModel={paginationModel}
                  isProspects={isProspects}
                  selectedTab={selectedTab}
                  agingDays={agingDays}
                />
              ),
              footer: CustomFooter,
            }}
            density={density}
            slotProps={{
              footer: {
                setPaginationModel:setPaginationModel,
                paginationModel:paginationModel,
                onPaginationModelChange:setPaginationModel,
                density:density
              },
            }}
            sx={LocalStyle.dataGrid}
          />
        )}

        {selectedTab === 1 && (
          <DataGridPremium
            rows={searchRiskyQuery ? totalRiskyRows : riskyRows}
            columns={isProspects ? prospectsColumns : riskyColumns}
            getRowId={(row) => parseInt(row.id)}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={(params) =>
              getDetailPanelContent(
                params,
                isCollectionAtRisk,
                filteredSummary ? filteredProspectsValue : isProspects
              )
            }
            onCellClick={handleCellClick}
            disableColumnMenu={true}
            disableColumnResize={false}
            onFilterModelChange={handleFilterModelChange}
            pagination
            initialState={{
              columns: {
                columnVisibilityModel: {
                  createdAt: false,
                },
              },
            }}
            paginationModel={riskyPaginationModel}
            onPaginationModelChange={setRiskyPaginationModel}
            slots={{
              toolbar: () => (
                <CustomToolbar
                  searchQuery={searchRiskyQuery}
                  handleInputChange={handleRiskyInputChange}
                  handleSearch={handleRiskySearch}
                  rows={searchRiskyQuery ? totalRiskyRows : riskyRows}
                  handleDensityChange={handleDensityChange}
                  handleFilterModelChange={handleFilterModelChange}
                  setGridRef={setGridRef}
                  paginationModel={riskyPaginationModel}
                  isProspects={isProspects}
                  selectedTab={selectedTab}
                  agingDays={agingDays}
                />
              ),
              footer: CustomFooter,
            }}
            density={density}
            slotProps={{
              footer: {
                setPaginationModel:setRiskyPaginationModel,
                paginationModel:riskyPaginationModel,
                onPaginationModelChange:setRiskyPaginationModel,
                density:density
              },
            }}
            sx={LocalStyle.dataGrid}
          />
        )}
      </div>
    </Card>
  );
};

export default RecommendationTable;
