import React, { useCallback, useEffect, useState } from "react";
import { API, graphqlOperation, Amplify } from "aws-amplify";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { companiesByName, getDashboardPreferences, getTenant, listLastVisitedCompanies, listTenants, triggerQuestionsAgent, triggerSendEmailGeneral } from "./graphql/queries";
import { getTenantIdAuth0 } from "./authUtils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  createAlert,
  createDashboardPreferences,
  deleteCompany,
  deleteGoogleSearch,
  deleteKnowledgeGraph,
  deleteLastVisitedCompanies,
  deleteWikipedia,
  deleteYahooFinance,
  updateCompany,
  updateDashboardPreferences,
  updateNewCompanyRecord,
  updateTenant,
} from "./graphql/mutations";
import {
  DataGridPremium,
  GridToolbar,
  GridRowModes,
  GridRowEditStopReasons,
  GridActionsCellItem,
} from "@mui/x-data-grid-premium";
import awsExports from "./aws-exports";
import AddCounterModal from "./AddCounterModal";
import { toast } from "react-toastify";
import TenantSchedulerModal from "./TenantSchedulerModal"; 
import AWS from "aws-sdk";
import dayjs from "dayjs";
import { TableEnum } from "./utils/GraphQLHelper/TableEnum";
import { deleteCompanySummary, getCompanyData, listCompanySummaries, updateCompanySummary, uploadFileToS3 } from "./utils/Helper";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { gridFilteredSortedRowIdsSelector, gridFilterModelSelector, GridToolbarContainer,GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, useGridApiContext, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";

Amplify.configure(awsExports);

const CustomExportButton = ({ rows, columns }) => {
  const apiRef = useGridApiContext();
  const [csvData, setCsvData] = useState([]);

  const handleExport = useCallback(() => {
    try {
      const columnVisibilityModel = apiRef.current.state.columns.columnVisibilityModel;

      const filteredRowIds = gridFilteredSortedRowIdsSelector(apiRef.current.state);
      const filterModel = gridFilterModelSelector(apiRef.current.state);

      const exportRows = filterModel.items.length 
        ? filteredRowIds.map((id) => rows.find((row) => row.id === id))
        : rows;

      const transformedRows = exportRows.map((row) => {
        const transformedRow = JSON.parse(JSON.stringify(row));

        if (transformedRow['Recommendation Auto']) {
          Object.entries(transformedRow['Recommendation Auto']).forEach(([key, value]) => {
            transformedRow[`${key} (Auto)`] = value;
          });
          delete transformedRow['Recommendation Auto'];
        }

        if (transformedRow['Recommendation Manual']) {
          Object.entries(transformedRow['Recommendation Manual']).forEach(([key, value]) => {
            transformedRow[`${key} (Manual)`] = value;
          });
          delete transformedRow['Recommendation Manual'];
        }

        return transformedRow;
      });

      const visibleColumns = columns.filter(
        column => columnVisibilityModel[column.field] !== false
      );

      const finalExportData = transformedRows.map(row => {
        const exportRow = {};
        visibleColumns.forEach(column => {
          exportRow[column.headerName || column.field] = row[column.field];
        });
        return exportRow;
      });

      setCsvData(finalExportData);
    } catch (error) {
      console.error('Export error:', error);
    }
  }, [rows, columns, apiRef]);
  
  return (
    <CSVLink
      data={csvData}
      filename="CrediArc.csv"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Button 
        onClick={handleExport} 
        startIcon={<DownloadIcon />}
      >
        Export
      </Button>
    </CSVLink>
  );
};

const CustomToolbar = ({
  rows,
  columns,
  setGridRef,
  onRefresh,
  loadingSummary
}) => {
  const apiRef = useGridApiContext();

  useEffect(() => {
    if (setGridRef) {
      setGridRef(apiRef);
    }
  }, [apiRef, setGridRef]);

  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        mb: 2,
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <CustomExportButton rows={rows} columns={columns} />
        {loadingSummary ? (
          <CircularProgress size={24}/>
        ) : (
          <Button
            variant='outlined'
            size='small'
            onClick={onRefresh}
            disabled={loadingSummary}>
            Refresh
          </Button>
        )}
      </Box>
      <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
};
const ComparisonModal = ({ open, onClose, rowData, onApply, onTriggerUnderwriting }) => {
  if (!rowData) return null; 

  const handleApply = (type) => {
    onApply(type);
    onClose();
  };

  const handleTriggerUnderwriting = () => {
    onTriggerUnderwriting();
    onClose();
  };

  const renderFields = (data) => {
    if (!data) return <Typography>No data available</Typography>; 
  
    return Object.entries(data).map(([key, value]) => (
      <Typography key={key}>
        <strong>{key.replace(/([A-Z])/g, " $1")}: </strong>
        {value || "N/A"}
      </Typography>
    ));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{margin:"0 auto"}}>{rowData?.["Company Name"]}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
        <Grid item xs={6}>
            <Typography variant="h6">Auto Recommendation</Typography>
            {renderFields(rowData["Recommendation Auto"])}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">Manual Recommendation</Typography>
            {renderFields(rowData["Recommendation Manual"])}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleApply("Manual")}
          variant="contained"
          color="primary"
        >
          Apply Manual
        </Button>
        <Button
          onClick={() => handleApply("Auto")}
          variant="contained"
          color="primary"
        >
          Apply Auto
        </Button>
        <Button
          onClick={handleTriggerUnderwriting}
          variant="contained"
        >
          Trigger Underwriting
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function UpdateAgingDaysModal({
  open,
  onClose,
  tenantId
}) {
  const [agingDays, setAgingDays] = useState({ age1: '', age2: '', age3: '' });
  const [loading, setLoading] = useState(false);
  const [isAREnabled, setIsAREnabled] = useState(false);
  const [arThreshold, setArThreshold] = useState('');

  useEffect(() => {
    if (tenantId) {
      fetchAgingDays();
      fetchAREnabledStatus();
    }
  }, [tenantId]);

  const removeText = (value) => {
    return value.startsWith('AR Balance Aging ') 
      ? value.replace('AR Balance Aging ', '') 
      : value;
  }

  const fetchAgingDays = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getDashboardPreferences, {
          tenantId,
          feature: "agingDays",
        })
      );
  
      const preferences = JSON.parse(result.data.getDashboardPreferences.preferences);
      
      const agingDaysFromPrefs = {
        age1:removeText(preferences.age1),
        age2:removeText(preferences.age2),
        age3:removeText(preferences.age3),
      };
      
  
      setAgingDays(agingDaysFromPrefs);
      console.log("Aging days:", agingDaysFromPrefs);
    } catch (error) {
      console.error("Error fetching aging days:", error);
    }
  };

  const fetchAREnabledStatus = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getTenant, {
          id: tenantId,
        })
      );
      const enabled = result.data?.getTenant?.isAREnabled; 
      const threshold = result.data?.getTenant?.arThreshold;
      console.log("enabled", enabled, "threshold", threshold);
      setIsAREnabled(enabled);
      setArThreshold(threshold);
    } catch (error) {
      console.error("Error fetching AR enabled status:", error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleChange = (key) => (event) => {
    setAgingDays({ ...agingDays, [key]: event.target.value });
  };

  const handleThresholdChange = (event) => {
    setArThreshold(event.target.value);
  };

  const handleToggleChange = async (event) => {
    const newStatus = event.target.checked;
    setIsAREnabled(newStatus);
  };

  const handleSubmitAgingDays = async () => {
    try {
      setLoading(true);

      const existingPreferences = await API.graphql(
        graphqlOperation(getDashboardPreferences, {
          tenantId,
          feature: "agingDays",
        })
      );

      console.log("Existing Preferences", existingPreferences.data.getDashboardPreferences);

      let formattedPreferences = {
        age1: agingDays.age1,
        age2: agingDays.age2,
        age3: agingDays.age3,
      };

      if (!agingDays.age1 && !agingDays.age2 && !agingDays.age3) {
        formattedPreferences = {}
      }

      if (!existingPreferences.data.getDashboardPreferences) {
        console.log("Creating New Preferences", formattedPreferences)
        await API.graphql(
          graphqlOperation(createDashboardPreferences, {
            input: {
              tenantId,
              feature: "agingDays",
              preferences: JSON.stringify(formattedPreferences),
            },
          })
        );
      } else {
        console.log("Updating Preferences", formattedPreferences)
        await API.graphql(
          graphqlOperation(updateDashboardPreferences, {
            input: {
              tenantId,
              feature: "agingDays",
              preferences: JSON.stringify(formattedPreferences),
            },
          })
        );
      }

      await API.graphql(
        graphqlOperation(updateTenant, {
          input: {
            id: tenantId,
            isAREnabled,
            arThreshold
          },
        })
      );
      setLoading(false)
      handleClose()
      toast.success("AR Settings Updated Successfully!");
    } catch (error) {
      console.error("Error saving aging days:", error);
      setLoading(false)
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        backdropFilter: "blur(2px)",
        backgroundColor: "rgb(255, 255, 255, 0.5)"
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          border: "none"
        }}
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            handleClose();
          }
        }}
      >
        <Box
          sx={{
            width: 400,
            p: 4,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "20px",
            boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
            display: 'flex',
            flexDirection: "column",
            gap: "10px"
          }}
        >
          <Typography id="modal-title" gutterBottom>
            Age 1
          </Typography>
          <TextField
            label="Age 1"
            variant="outlined"
            value={agingDays.age1}
            onChange={handleChange('age1')}
            fullWidth
          />
          <Typography id="modal-title" gutterBottom>
            Age 2
          </Typography>
          <TextField
            label="Age 2"
            variant="outlined"
            value={agingDays.age2}
            onChange={handleChange('age2')}
            fullWidth
          />
          <Typography id="modal-title" gutterBottom>
            Age 3
          </Typography>
          <TextField
            label="Age 3"
            variant="outlined"
            value={agingDays.age3}
            onChange={handleChange('age3')}
            fullWidth
          />
          <Typography id="modal-title" gutterBottom>
            AR Threshold
          </Typography>
          <TextField
            label="AR Threshold"
            variant="outlined"
            value={arThreshold}
            onChange={handleThresholdChange}
            fullWidth
          />
          <Typography id="modal-title" gutterBottom>
            Enable AR
          </Typography>
          <Switch
            checked={isAREnabled}
            onChange={handleToggleChange}
          />
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleSubmitAgingDays}
            disabled={loading}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

function AdminPage() {
  const [rows, setRows] = useState([]);
  const [comparisonModalOpen, setComparisonModalOpenn] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [columnsArray, setColumnsArray] = useState([]);
  const [tenant, setTenant] = useState();
  const [tenantId, setTenantId] = useState();
  const [tenantsArray, setTenantsArray] = useState([]);
  const [showDataGrid, setShowDataGrid] = useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [recommendedLimitClicked, setRecommendedLimitClicked] = useState(false);
  const [combinedScoreClicked, setCombinedScoreClicked] = useState(false);
  const [alternativeScoreClicked, setAlternativeScoreClicked] = useState(false);
  const [underwritingReasonClicked, setUnderwritingReasonClicked] =
    useState(false);
  const [prevRecommendedLimit, setPrevRecommendedLimit] = useState("");
  const [prevCombinedScore, setPrevCombinedScore] = useState("");
  const [prevAlternativeScore, setPrevAlternativeScore] = useState("");
  const [prevUnderwritingReason, setPrevUnderwritingReason] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSchedulerModalOpen, setIsSchedulerModal] = useState(false);
  const [isUpdateAgingDaysModalOpen, setIsUpdateAgingDaysModalOpen] = useState(false);
  const [counterValue, setCounterValue] = useState("");
  const [currentCounterValue, setCurrentCounterValue] = useState("");
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    "Company Name": true, 
    "Requested Limit": true, 
    "Risk Segment": true, 
    "Failure Score": true, 
    "Alternative Score": true, 
    "Combined Score": true, 
    "Last Update Time": true, 
    "Recommended Limit": true, 
    "Current Recommendation Type": true,
    "# Questions":true, 
    "Underwriting Reason":true, 
  });

  let columnDefinition = [];
  let data = [];
  let columns;

  const getData = async () => {
    try {
      const summary = await listCompanySummaries(tenantId);
      return { summary };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const fetchDataForGrid = async () => {
    try {
      setLoadingSummary(true);
      data = await getData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    const dataWithIds = data.summary?.map((obj, index) => {
      const { id, ...rest } = obj;

      return {
        id: index + 1,
        ...rest,
      };
    });
    console.log("Table Refreshed")
    setRows(dataWithIds);
    setLoadingSummary(false);
  };

  const actionsColumnDefinition = {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    hide: false,
    width: 100,
    cellClassName: "actions",
    getActions: ({ id, row }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<ChangeCircleOutlinedIcon />}
          label="Type Change"
          onClick={() => handleRecommendationTypeChange(id)}
          color="inherit"
          disabled={
            !(row["Recommendation Manual"] || !row["Recommendation Auto"])
          }
        />,
      ];
    },
  };

  const CreateColumnHeader = (headerTitle) => {
    return (
      <Typography variant="caption" fontWeight="bold">
        {headerTitle}
      </Typography>
    );
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleTenantDashboardClick = async () => {
    window.open(`/tenant-dashboard/${tenantId}`, "_blank");
  };
  const handleUpdateAgingDays = async () => {
    setIsUpdateAgingDaysModalOpen(true)
  };

  const syncCompanyRecord = async (companyId, row) => {
    let result = await API.graphql(
      graphqlOperation(updateNewCompanyRecord, {
        input: {
          tenantId: tenantId,
          id: companyId,
          summary: JSON.stringify(row),
        },
      })
    );

    console.log("Company Record Updated", result);
  };

  const handleSave = async (row) => {
    try {
      
      let company = await getCompanyData(row, tenantId);
      let requestedCreditLimit = row["Requested Limit"];
      await syncCompanyRecord(company.id, row);

      await API.graphql(
        graphqlOperation(updateCompany, {
          input: {
            tenantId: tenantId,
            id: company.id,
            requestedCreditLimit: parseFloat(requestedCreditLimit),
            view: 1,
          },
        })
      );
      console.log("updated");
    } catch (error) {
      console.error("error while saving file", error);
    }
  };

  const handleSaveClick = (id) => () => {
    const jsonData = rows;
    const foundItem = rows.find((item) => item.id === id);
    handleSave(foundItem);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const CreateAlertItem = async (message, type, companyName) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createAlert, {
          input: {
            tenantId: tenantId,
            message: message,
            isRead: false,
            type: type,
            companyInfo: JSON.stringify({ companyName: companyName }),
          },
        })
      );
      console.log("created data alert!");
    } catch (error) {
      console.error("Error creating alert", error);
    }
  };

  const handleCellClick = (id) => {
    if (id.field === "Recommended Limit") {
      setRecommendedLimitClicked(true);
      setPrevRecommendedLimit(id.value);
    }
    if (id.field === "Underwriting Reason") {
      setUnderwritingReasonClicked(true);
      setPrevUnderwritingReason(id.value);
    }
    if (id.field === "Combined Score") {
      setCombinedScoreClicked(true);
      setPrevCombinedScore(id.value);
    }
    if (id.field === "Alternative Score") {
      setAlternativeScoreClicked(true);
      setPrevAlternativeScore(id.value);
    }
  };
  const processRowUpdate = async (newRow) => {
    const formattedDate = dayjs(newRow["Last Update Time"]).format("D/MM/YYYY");
    if (recommendedLimitClicked) {
      let message =
        "Recommended Limit updated from " +
        prevRecommendedLimit +
        " to " +
        newRow["Recommended Limit"] +
        " for " +
        newRow["Company Name"];
        message += ` on ${formattedDate}`;
        if (prevRecommendedLimit !== newRow["Recommended Limit"]) {
          CreateAlertItem(message, "Status Change", newRow["Company Name"]);
          const response = await API.graphql(
            graphqlOperation(triggerSendEmailGeneral, {
              tenantId: tenantId,
              subject: "Notification - Status Change",
              message: message,
            })
          );
          console.log("triggerSendEmailGeneral Response",response)
        }
      newRow["Evaluation Status"] = "Revised";
      setRecommendedLimitClicked(false);
      setPrevRecommendedLimit(null);
    }

    if (combinedScoreClicked) {
      let message =
        "Arc Score updated from " +
        prevCombinedScore +
        " to " +
        newRow["Combined Score"] +
        " for " +
        newRow["Company Name"];
        message += ` on ${formattedDate}`;
        if (prevCombinedScore !== newRow["Combined Score"]) {
          CreateAlertItem(message, "Status Change", newRow["Company Name"]);
          const response = await API.graphql(
            graphqlOperation(triggerSendEmailGeneral, {
              tenantId: tenantId,
              subject: "Notification - Status Change",
              message: message,
      
            })
          );
          console.log("triggerSendEmailGeneral Response",response)
        }
      setPrevCombinedScore(null);
      setCombinedScoreClicked(false);
    }

    if (alternativeScoreClicked) {
      let message =
        "Alternative Score updated from " +
        prevAlternativeScore +
        " to " +
        newRow["Alternative Score"] +
        " for " +
        newRow["Company Name"];
        message += ` on ${formattedDate}`;
        if (prevAlternativeScore !== newRow["Alternative Score"]) {
          CreateAlertItem(message, "Status Change", newRow["Company Name"]);
          const response = await API.graphql(
            graphqlOperation(triggerSendEmailGeneral, {
              tenantId: tenantId,
              subject: "Notification - Status Change",
              message: message,

            })
          );
          console.log("triggerSendEmailGeneral Response",response)
        }
      setPrevAlternativeScore(null);
      setAlternativeScoreClicked(false);
    }

    if (underwritingReasonClicked) {
      let message =
        "Underwriting Reason updated from " +
        prevUnderwritingReason +
        " to " +
        newRow["Underwriting Reason"] +
        " for " +
        newRow["Company Name"];
        message += ` on ${formattedDate}`;
        if (prevUnderwritingReason !== newRow["Underwriting Reason"]) {
          CreateAlertItem(message, "Update", newRow["Company Name"]);
          const response = await API.graphql(
            graphqlOperation(triggerSendEmailGeneral, {
              tenantId: tenantId,
              subject: "Notification - Status Change",
              message: message,
            })
          );
          console.log("triggerSendEmailGeneral Response",response)
        }
      setPrevUnderwritingReason(null);
      setUnderwritingReasonClicked(false);
    }

    const manualObj = {
      "Type Created At": dayjs().format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
      "Recommended Net": newRow["Recommended Net"] || null,
      "Recommended Limit": newRow["Recommended Limit"] || null,
      "Underwriting Reason": newRow["Underwriting Reason"] || null,
      "Combined Score": newRow["Combined Score"] ?? "",
      "Sector Score": newRow["Sector Score"] ?? "",
      "Country Score": newRow["Country Score"] ?? "",
      "Legal Score": newRow["Legal Score"] ?? "",
      "General Risk Score": newRow["General Risk Score"] ?? "",
      Impact: newRow["Impact"] ?? "",
      "Risk Segment": newRow["Risk Segment"] ?? "",
      "Failure Score": newRow["Failure Score"] ?? "",
      "Delinquency Score": newRow["Delinquency Score"] ?? "",
      "Alternative Score": newRow["Alternative Score"] ?? "",
    };

    const updatedRow = {
      ...newRow,
      "Last Update Time": dayjs().format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
      "Current Recommendation Type": "Manual",
      "Recommendation Manual": manualObj,
      "Type Last Updated At": dayjs().format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
    };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    getCompanyAndUpdateSummary(updatedRow, "update")
    console.log("Process Row Update", updatedRow);
    handleSave(updatedRow);
    return updatedRow;
  };
  
  const handleDeleteClick = (id) => () => {
    const isConfirmed = window.confirm("Are you sure you want to delete?");
    if (isConfirmed) {
      const rowToDelete = rows.find((row) => row.id === id);
      getCompanyAndUpdateSummary(rowToDelete, "delete");
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRecommendationTypeChange = (id) => {
    const rowData = rows.find((item) => item.id === id);
    setSelectedRow(rowData);
    setComparisonModalOpenn(true); 
  };

  const handleApplyRecommendation = (type) => {
    const recommendationKey = `Recommendation ${type}`;
    const recommendationData = selectedRow[recommendationKey];
  
    const updatedRow = {
      ...selectedRow,
      "Current Recommendation Type": type,
      ...Object.keys(recommendationData).reduce((acc, key) => {
        acc[key] = recommendationData[key] || 0; 
        return acc;
      }, {}),
      "Type Last Updated At": dayjs().format("ddd, DD MMM YYYY HH:mm:ss [GMT]"),
    };
  
    const updatedList = rows.map((item) =>
      item.id === selectedRow.id ? updatedRow : item
    );
  
    setRows(updatedList);
    getCompanyAndUpdateSummary(selectedRow, "update")
    setComparisonModalOpenn(false);
  };

  const handleTriggerUnderwriting = async () => {
    if (!selectedRow) {
      return;
    }
  
    const updatedRow = {
      ...selectedRow,
      mockUnderwriting: true,
    };
  
    const updatedList = rows.map((item) =>
      item.id === selectedRow.id ? updatedRow : item
    );

    await getCompanyAndUpdateSummary(updatedRow, "update")
  
    const companyName = selectedRow["Company Name"];
    if (!companyName) {
      toast.error("Company Name is missing from the selected row!");
      return;
    }
  
    try {
      const company = await getCompanyData(selectedRow, tenantId)
      if (!company) {
        toast.error(`No company with name "${companyName}" found in the database!`);
        return;
      }

      setRows(updatedList);
  
      console.log("company found in DB", company);
      const result = await API.graphql(
        graphqlOperation(triggerQuestionsAgent, {
          tenantId: tenantId,
          id: company.id,
        })
      );
  
      toast.success("Underwriting triggered successfully!");
    } catch (error) {
      console.error("Error triggering underwriting:", error);

      if (error.errors && error.errors.length > 0) {
        toast.error(error.errors[0].message || "An error occurred.");
      } else {
        toast.error("Failed to trigger underwriting. Please try again.");
      }
    }
  };

  async function getCompanyAndUpdateSummary(row, action) {
    try {
      const company = await getCompanyData(row, tenantId);
      if (!company) {
        console.error(`No company found: ${row["Company Name"]}`);
        return;
      }

      if (action === "update") {
        const companySummaryUpdateInput = {
          tenantId: tenantId,
          companyId: company.id,
          summaryData: JSON.stringify(row),
        };

        await updateCompanySummary(companySummaryUpdateInput);
        console.log("Company summary updated successfully");
      } else if (action === "delete") {
        await deleteCompanySummary(tenantId, company.id); 
        console.log("Company summary deleted...");
        await handleDeleteCompanyFromDB(company);
        console.log("Company deleted successfully");
      }
    } catch (error) {
      console.error("Error in getting Company And Updating Summary:", error);
    }
  }

  const handleDeleteCompanyFromDB = async (company) => {
    console.log("Starting deletion process for company:", company);
    try {
      const companyInput = {
        tenantId: company.tenantId,
        id: company.id,
      };
      console.log("Company input for deletion:", companyInput);

      const result = await API.graphql(
        graphqlOperation(listLastVisitedCompanies, {
          tenantId: tenantId,
        })
      );
      console.log("Fetched last visited companies:", result.data.listLastVisitedCompanies.items);

      const lastVisitedCompany =
        result.data.listLastVisitedCompanies.items.filter(
          (elem) => elem.companyId === company.id
        );
      console.log("Last visited company found:", lastVisitedCompany);

      if (lastVisitedCompany[0]?.lastOpenedAt) {
        console.log("Deleting last visited company with lastOpenedAt:", lastVisitedCompany[0].lastOpenedAt);
        await API.graphql(
          graphqlOperation(deleteLastVisitedCompanies, {
            input: {
              tenantId: company.tenantId,
              lastOpenedAt: lastVisitedCompany[0]?.lastOpenedAt,
            },
          })
        );
      }

      console.log("Deleting company:", companyInput);
      await API.graphql(
        graphqlOperation(deleteCompany, {
          input: companyInput,
        })
      );

      if (
        company.companyGoogleSearchId &&
        company.companyGoogleSearchTenantId
      ) {
        console.log("Deleting Google Search data for company:", company.companyGoogleSearchId);
        await API.graphql(
          graphqlOperation(deleteGoogleSearch, {
            input: {
              id: company.companyGoogleSearchId,
              tenantId: company.companyGoogleSearchTenantId,
            },
          })
        );

        if (
          company.googleSearch.googleSearchKnowledgeGraphId &&
          company.googleSearch.googleSearchKnowledgeGraphTenantId
        ) {
          console.log("Deleting Knowledge Graph data for company:", company.googleSearch.googleSearchKnowledgeGraphId);
          await API.graphql(
            graphqlOperation(deleteKnowledgeGraph, {
              input: {
                id: company.googleSearch.googleSearchKnowledgeGraphId,
                tenantId:
                  company.googleSearch.googleSearchKnowledgeGraphTenantId,
              },
            })
          );
        }
      }

      if (company.companyWikipediaId && company.companyWikipediaTenantId) {
        console.log("Deleting Wikipedia data for company:", company.companyWikipediaId);
        await API.graphql(
          graphqlOperation(deleteWikipedia, {
            input: {
              id: company.companyWikipediaId,
              tenantId: company.companyWikipediaTenantId,
            },
          })
        );
      }

      if (
        company.companyYahooFinanceId &&
        company.companyYahooFinanceTenantId
      ) {
        console.log("Deleting Yahoo Finance data for company:", company.companyYahooFinanceId);
        await API.graphql(
          graphqlOperation(deleteYahooFinance, {
            input: {
              id: company.companyYahooFinanceId,
              tenantId: company.companyYahooFinanceTenantId,
            },
          })
        );
      }
      console.log("Successfully deleted company and associated data.");
    } catch (error) {
      console.error("Error deleting company:", error);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function getUniqueKeys(arrayOfObjects) {
    let uniqueKeys = new Set();
    arrayOfObjects?.forEach((obj) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          uniqueKeys.add(key);
        }
      }
    });
    return Array.from(uniqueKeys);
  }

  const handleChange = (newValue) => {
    const selectedTenant = newValue ? newValue : undefined;
    setTenant(newValue);
    if (selectedTenant !== undefined) {
      setTenantId(newValue.id);
      setCurrentCounterValue(newValue.counter);
    } else {
      setShowDataGrid(false);
      setTenantId(undefined);
      console.error("Selected tenant not found");
    }
  };

  useEffect(() => {
    if (tenantId !== undefined) {
      fetchDataForGrid();
    }
  }, [tenantId]);

  const getTenant = async () => {
    try {
      let nextToken = null;
      let allTenants = [];
      do {
        let result = await API.graphql(
          graphqlOperation(listTenants, { nextToken: nextToken })
        );
        allTenants = allTenants.concat(result.data.listTenants.items);
        nextToken = result.data.listTenants.nextToken;
      } while (nextToken);

      let adminTenant = await getTenantIdAuth0();
      const tenantNames = {};
      let filteredTenants = allTenants.filter((tenant) => {
        tenantNames[tenant.id] = tenant.name;
        return tenant.id !== adminTenant;
      });
      const stringNames = JSON.stringify(tenantNames);
      localStorage.setItem("tenantNames", stringNames);
      setTenantsArray(filteredTenants);
    } catch (error) {
      console.error("Error fetching tenants:", error);
    }
  };

  useEffect(() => {
    getTenant();
  }, []);

  useEffect(() => {
    if (rows && rows.length > 0) {
      columns = getUniqueKeys(rows).filter(
        (column) =>
          column !== "" &&
          column !== "id" &&
          column !== "Recommendation Manual" &&
          column !== "Recommendation Auto"
      );
      const nestedFields = [
        "Recommended Limit",
        "Underwriting Reason",
        "Paydex",
        "Combined Score",
        "Country Score",
        "Legal Score",
        "General Risk Score",
        "Risk Segment",
        "Failure Score",
        "Delinquency Score",
        "Alternative Score",
      ];
  
      const additionalColumns = [
        ...nestedFields.map(field => `${field} (Auto)`),
        ...nestedFields.map(field => `${field} (Manual)`)
      ];
  
      columns = [...columns, ...additionalColumns];
  
      columns.forEach((column) => {
        const definition = {
          field: column,
          headerName: column === "Combined Score" ? "Arc Score" : column,
          flex: 1,
          hide: false,
          editable: true,
          renderHeader: (params) => {
            return CreateColumnHeader(params.colDef.headerName);
          },
          renderCell: (params) => {
            if (column.includes("(Auto)")) {
              const originalField = column.replace(" (Auto)", "");
              if (originalField.includes("Reason")){
                return params.row["Recommendation Auto"]?.[originalField];
              }
              return params.row["Recommendation Auto"]?.[originalField] || 0;
            }
            if (column.includes("(Manual)")) {
              const originalField = column.replace(" (Manual)", "");
              if (originalField.includes("Reason")){
                return params.row["Recommendation Manual"]?.[originalField];
              }
              return params.row["Recommendation Manual"]?.[originalField] || 0;
            }
            if (column === "Company Name") {
              return (
                <div style={{ cursor: "pointer" }}>
                  {params?.row["Company Name"]}
                </div>
              );
            }
          },
          valueGetter: (params) => {
            const isNaNOrInvalid = (value) => isNaN(value) || value === undefined || value === null || value === "N/A";
            if (column.includes("(Auto)")) {
              const originalField = column.replace(" (Auto)", "");
              const value = params.row["Recommendation Auto"]?.[originalField];
              if (originalField.includes("Reason")){
                return value;
              }
              return isNaNOrInvalid(value) ? 0 : value;
            }
            if (column.includes("(Manual)")) {
              const originalField = column.replace(" (Manual)", "");
              const value = params.row["Recommendation Manual"]?.[originalField];
              if (originalField.includes("Reason")){
                return value;
              }
              return isNaNOrInvalid(value) ? 0 : value;
            }
            const value = params.row[column];
            return value;
          }
        };
        if (typeof rows[0][column] === "number") {
          definition.type = "number";
        }

        columnDefinition.push(definition);
        if (!columnVisibilityModel.hasOwnProperty(column)) {
          setColumnVisibilityModel((prevModel) => ({
            ...prevModel,
            [column]: false, 
          }));
        }
      });
      columnDefinition.push(actionsColumnDefinition);
      setColumnsArray(columnDefinition);
      setShowDataGrid(true);
    }
  }, [rows, rowModesModel]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleOpenSchedulerModal = () => {
    setIsSchedulerModal(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseUpdateAgingDaysModal = () => {
    setIsUpdateAgingDaysModalOpen(false);
  };

  const handleCloseSchedulerModal = () => {
    setIsSchedulerModal(false);
  };

  const handleSaveCounter = async () => {
    try {
      const input = { id: tenantId, counter: counterValue };
      const res = await API.graphql(graphqlOperation(updateTenant, { input }));
      setCurrentCounterValue(res.data.updateTenant.counter);
      getTenant();
      toast.success("Counter Reset Successful!");
    } catch (error) {
      console.error("Error updating tenant counter:", error);
    }
    console.log("Counter value saved:", counterValue);
    setIsModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormControl>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Autocomplete
            size="small"
            sx={{ width: 250, my: 5 }}
            value={tenant}
            onChange={(event, newValue) => handleChange(newValue)}
            options={tenantsArray}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selected Tenant"
                variant="outlined"
              />
            )}
          />
          <Button
            disabled={!tenant}
            onClick={handleTenantDashboardClick}
            variant="contained"
            sx={{ marginLeft: 1 }}
          >
            Tenant Dashboard <OpenInNewIcon sx={{ marginLeft: 0.5 }} />
          </Button>
          <Button
            disabled={!tenant}
            onClick={handleUpdateAgingDays}
            variant="contained"
            sx={{ marginLeft: 1 }}
          >
            Update AR Settings
          </Button>
          <Button
            disabled={!tenant}
            onClick={handleOpenSchedulerModal} // Define handleOpenModal function
            variant="contained"
            sx={{ marginLeft: 1 }} // Add some left margin for spacing
          >
            Schedule Trigger
          </Button>
          <Box>
            <Button
              disabled={!tenant}
              onClick={handleOpenModal} // Define handleOpenModal function
              variant="contained"
              sx={{ marginLeft: 1 }} // Add some left margin for spacing
            >
              Reset Counter
            </Button>
            {tenant ? (
              <Typography variant="body1" mt={1} textAlign={"center"}>
                {currentCounterValue > 0
                  ? `Current Counter Value: ${currentCounterValue}`
                  : "Exhausted"}
              </Typography>
            ) : null}
          </Box>
        </Box>
      </FormControl>

      {showDataGrid && (
        <div
          style={{
            width: "100%",
            height: "85vh",
            marginTop: 80,
          }}
        >
          <Typography variant="h5" mb={5}>
            Summary & Recommendations
          </Typography>
          <DataGridPremium
            sx={{
              ".Mui-hovered": {
                cursor: "pointer",
              },
            }}
            rows={rows}
            columns={columnsArray}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            onCellClick={handleCellClick}
            processRowUpdate={processRowUpdate}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              },
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            pageSizeOptions={[5, 10, 50]}
            slots={{
              toolbar: () => (
                <CustomToolbar rows={rows} columns={columnsArray} onRefresh={fetchDataForGrid} loadingSummary={loadingSummary}/>
              ),
            }}
            slotProps={{
              toolbar: {
                rows,
                columns,
              }
            }}
          />
        </div>
      )}
      <UpdateAgingDaysModal
      open={isUpdateAgingDaysModalOpen}
      onClose={handleCloseUpdateAgingDaysModal}
      tenantId={tenantId}
      />
      <AddCounterModal
        open={isModalOpen}
        onClose={handleCloseModal}
        counterValue={counterValue}
        setCounterValue={setCounterValue}
        handleSaveCounter={handleSaveCounter}
      />
      <TenantSchedulerModal
        open={isSchedulerModalOpen}
        onClose={handleCloseSchedulerModal}
        tenantId={tenantId}
      />
      <ComparisonModal
        open={comparisonModalOpen}
        onClose={() => setComparisonModalOpenn(false)}
        rowData={selectedRow}
        onApply={handleApplyRecommendation}
        onTriggerUnderwriting={handleTriggerUnderwriting}
      />
    </Box>
  );
}
export default AdminPage;
