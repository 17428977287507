import React, { useState, useMemo,useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { API, graphqlOperation } from 'aws-amplify';
import { listQuestionTemplates, triggerQuestionsAgent } from './graphql/queries';
import { createQuestionTemplate, deleteQuestionTemplate, updateQuestionTemplate } from './graphql/mutations';
import getTenantIdFromURL from './utils/getTenantIdFromURL';
import { useSelector } from 'react-redux';
import { Box, TextField, Button, Typography, Modal, Checkbox } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { red } from '@mui/material/colors';

export default function QuestionsPage({}) {
  const [rowData, setRowData] = useState([]);
  const [tenantId,setTenantId] = useState(undefined);
  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenant  = useSelector(state => state.userInfo.tenantId);
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');

  useEffect(() => {
    const selectTenantId =()=>{
      if(isAdmin) setTenantId(URLTenant);
      else setTenantId(currentTenant);
    };
    selectTenantId();
  }, [isAdmin, URLTenant, currentTenant]);

  useEffect(() => {
    const questionsLoader = async () => {
      if (tenantId) {
        const result = await API.graphql(graphqlOperation(listQuestionTemplates, { tenantId, limit: 1000 }));
        const questions = result.data.listQuestionTemplates.items;
        questions.sort((a, b) => a.position - b.position);
        setRowData(questions);
      }
    };
    questionsLoader();
  }, [tenantId]);

  const handleAddQuestionClick = async () => {
    if (rowData.length >= 100) {
      alert("You cannot add more than 100 questions.");
      return;
    }
    try {
      const lastPosition = rowData.length ? Math.max(...rowData.map(q => q.position)) : 0;
      const result = await API.graphql(graphqlOperation(createQuestionTemplate, {
        input: {
          tenantId,
          question: textFieldValue,
          position: lastPosition + 1
        }
      }));
      setTextFieldValue("");
      const question = result.data.createQuestionTemplate;
      setRowData([...rowData, question]);
    } catch (error) {
      console.error("Error adding question:", error);
    }
  };

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const handleDeleteQuestion = async (data) => {
    try {
      const result = await API.graphql(graphqlOperation(deleteQuestionTemplate, { input: { id: data.id, tenantId: tenantId } }));
      const question = result.data.deleteQuestionTemplate;
      const updatedData = rowData.filter((item) => item.id !== question.id);
      let newPosition = question.position;
      for (const item of updatedData) {
        if (item.position > question.position) {
          item.position = newPosition;
          newPosition++;
          await API.graphql(graphqlOperation(updateQuestionTemplate, { input: { id: item.id, tenantId: item.tenantId, position: item.position } }));
        }
      }
      updatedData.sort((a, b) => a.position - b.position);
      setRowData(updatedData);
    } catch (error) {
      console.error("Error deleting question and updating positions:", error);
    }
  };

  const handleRowOrderChange = async ({ rowIds, oldIndex, targetIndex }) => {
    try {
      const questions = [...rowData];
      const [removed] = questions.splice(oldIndex, 1);
      questions.splice(targetIndex, 0, removed);

      for (let i = 0; i < questions.length; i++) {
        questions[i].position = i + 1;
      }

      for (const item of questions) {
        await API.graphql(graphqlOperation(updateQuestionTemplate, {
          input: { id: item.id, tenantId: item.tenantId, position: item.position }
        }));
      }

      setRowData(questions);
    } catch (error) {
      console.error("Error updating positions on row drag:", error);
    }
  };

  const columns = [
    {
      field: 'inRisk',
      headerName: 'In risk?',
      renderCell: (params) => (
        <Checkbox
          checked={params.row.inRisk}
          onChange={async (event) => {
            const updatedQuestion = await API.graphql(graphqlOperation(updateQuestionTemplate, {
              input: { id: params.row.id, tenantId: params.row.tenantId, inRisk: event.target.checked }
            }));
            setRowData(rowData.map(item => item.id === params.row.id ? updatedQuestion.data.updateQuestionTemplate : item));
          }}
          sx={{ color: red[800], '&.Mui-checked': { color: red[600] } }}
        />
      ),
      width: 130
    },
    { field: 'question', headerName: 'Question', width: 750 },
    {
      field: 'actions',
      headerName: '',
      renderCell: (params) => (
        <button onClick={() => handleDeleteQuestion(params.row)}>Delete <DeleteForeverIcon/></button>
      ),
      width: 100
    }
  ];

  return (
    <div style={{display:"flex",justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
      <Typography variant="h6" style={{ margin: '20px 0' }}>
        Total Questions: {rowData.length} / 100
      </Typography>
      <Box>
        <TextField
          style={{ width: 500 }}
          label="Insert a new question"
          variant="standard"
          value={textFieldValue}
          onChange={handleTextFieldChange}
        />
        <Button onClick={handleAddQuestionClick}>Add question</Button>
        <Button
          onClick={async () => {
            setDisabled(true);
            await API.graphql(graphqlOperation(triggerQuestionsAgent, { tenantId }));
            setOpen(true);
            setDisabled(false);
          }}
          disabled={disabled}
        >
          Trigger questions agent
        </Button>
      </Box>
      <div style={{ height: 800, width: '80%' }}>
        <DataGridPro
          rows={rowData}
          columns={columns}
          // loading={!rowData.length}
          rowReordering
          onRowOrderChange={handleRowOrderChange}
        />
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Agent initiated
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            All configured questions are being answered, visit the company page for answers
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}
