import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { 
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography
} from "@mui/material";

// GraphQL mutation
import { updateTenant } from "./graphql/mutations";
import { getTenant } from "./graphql/queries";
import { toast } from "react-toastify";

const FREQUENCY_OPTIONS = [
  { id: "DAILY", label: "Daily" },
  { id: "WEEKLY", label: "Weekly" },
  { id: "MONTHLY", label: "Monthly" },
];

const DAYS_OF_WEEK = [
  { id: 1, label: "Monday" },
  { id: 2, label: "Tuesday" },
  { id: 3, label: "Wednesday" },
  { id: 4, label: "Thursday" },
  { id: 5, label: "Friday" },
  { id: 6, label: "Saturday" },
  { id: 7, label: "Sunday" },
];

const TenantSchedulerModal = ({ open, onClose, tenantId }) => {
  const [frequency, setFrequency] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleClose = () => {
    setFrequency("");
    setSelectedTime("");
    setSelectedDay("");
    setSelectedDate("");
    onClose();
  };

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const schedule = await fetchTenantSchedule(tenantId);
        if (schedule) {
          setFrequency(schedule.frequency);
          setSelectedTime(schedule.time.hour);
          setSelectedDay(schedule.dayOfWeek || "");
          setSelectedDate(schedule.dayOfMonth ? String(schedule.dayOfMonth) : "");
        }
      } catch (error) {
        console.error("Failed to fetch schedule:", error);
      }
    };

    if (open) {
      fetchSchedule();
    }
  }, [open, tenantId]);

  const handleClearSchedule = async () => {
    setFrequency("");
    setSelectedTime("");
    setSelectedDay("");
    setSelectedDate("");
    
    const input = { 
      id: tenantId, 
      scheduleInfo: null
    };
  
    try {
      await API.graphql(graphqlOperation(updateTenant, { input }));
      toast.success("Schedule cleared successfully!")
    } catch (error) {
      console.error("Failed to clear schedule:", error);
      toast.error("Failed to clear schedule")
    }
  };

  const validateSchedule = () => {
    if (!frequency || !selectedTime) {
      throw new Error("Frequency and time are required");
    }

    if (frequency === "WEEKLY" && !selectedDay) {
      throw new Error("Day selection is required for weekly frequency");
    }

    if (frequency === "MONTHLY" && !selectedDate) {
      throw new Error("Date selection is required for monthly frequency");
    }

    if (frequency === "MONTHLY" && selectedDate) {
      const dateNum = parseInt(selectedDate);
      if (dateNum < 1 || dateNum > 31) {
        throw new Error("Invalid date selected");
      }
    }
  };

  const handleSave = async () => {
    try {
      validateSchedule();
      
      const scheduleInfo = {
        frequency,
        time: {
          hour: selectedTime,
          timezone: userTimezone
        },
        ...(frequency === "WEEKLY" && { dayOfWeek: selectedDay }),
        ...(frequency === "MONTHLY" && { dayOfMonth: parseInt(selectedDate) })
      };

      const input = { 
        id: tenantId, 
        scheduleInfo: JSON.stringify(scheduleInfo)
      };

      await API.graphql(graphqlOperation(updateTenant, { input }));
      toast.success("Trigger scheduled successfully!")
      handleClose();
    } catch (error) {
      toast.success("Failed to save schedule.")
      console.error("Failed to save schedule:", error);
    }
  };

  const timeOptions = Array.from({ length: 24 }, (_, i) => {
    return `${i.toString().padStart(2, '0')}:00`;
  });

  async function fetchTenantSchedule(tenantId) {
    try {
      const response = await API.graphql(graphqlOperation(getTenant, { id: tenantId }));
      if (response.data.getTenant && response.data.getTenant.scheduleInfo) {
        const schedule = JSON.parse(response.data.getTenant.scheduleInfo);
        return schedule
      }
    } catch (error) {
      console.error("Failed to fetch tenant's schedule:", error);
      throw error; 
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle>Schedule Settings</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Frequency</InputLabel>
            <Select
              value={frequency}
              label='Frequency'
              onChange={(e) => setFrequency(e.target.value)}>
              {FREQUENCY_OPTIONS.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {frequency === "WEEKLY" && (
            <FormControl fullWidth>
              <InputLabel>Day</InputLabel>
              <Select
                value={selectedDay}
                label='Day'
                onChange={(e) => setSelectedDay(e.target.value)}>
                {DAYS_OF_WEEK.map((day) => (
                  <MenuItem key={day.id} value={day.id}>
                    {day.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {frequency === "MONTHLY" && (
            <FormControl fullWidth>
              <InputLabel>Date</InputLabel>
              <Select
                value={selectedDate}
                label='Date'
                onChange={(e) => setSelectedDate(e.target.value)}>
                {Array.from({ length: 31 }, (_, i) => (
                  <MenuItem key={i + 1} value={String(i + 1)}>
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl fullWidth>
            <InputLabel>Time</InputLabel>
            <Select
              value={selectedTime}
              label='Time'
              onChange={(e) => setSelectedTime(e.target.value)}>
              {timeOptions.map((time) => (
                <MenuItem key={time} value={time}>
                  {time}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography variant='body2' color='text.secondary'>
            Current timezone: {userTimezone}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleClose} color='inherit'>
        Cancel
      </Button>
      <Button onClick={handleClearSchedule} color='error'>
        Clear Schedule
      </Button>
      <Button onClick={handleSave} variant='contained'>
        Save
      </Button>
    </DialogActions>
    </Dialog>
  );
};

export default TenantSchedulerModal;