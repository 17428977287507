import React, { useState, useEffect, useRef } from 'react';
import { 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getCompanyTreeData, safeJSONParse } from './getCompanyTreeData';
import { getCompaniesByCompanyName, listCompanySummaryData } from './utils/Helper';
import countryMapping from './utils/countries';
import { API } from 'aws-amplify';
import { graphqlOperation } from '@aws-amplify/api';
import { dnbSearchCompany } from './graphql/queries';
import { getTenantInfo } from './utils/GraphQLHelper/GetTenantInfo';
import { toast } from 'react-toastify';
import { createCompany } from './graphql/mutations';
import CreateCompanyModal from './CreateCompanyModal';

const CustomIcon = () => (
  <Box
    sx={{
      width: 30,
      height: 30,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.29)',
    }}
  >
    <ExpandMoreIcon style={{ color: "#5186EC" }} />
  </Box>
);

const SectionHeader = ({ title, isExpanded, onClick }) => (
  <Box 
    onClick={onClick}
    sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      cursor: 'pointer',
      marginBottom: '16px'
    }}
  >
    <Typography sx={{ marginRight: '8px', color: '#A2A2A2', fontSize: '20px' }}>
      {isExpanded ? '-' : '+'}
    </Typography>
    <Typography
      style={{
        fontSize: "16px",
        color: "#1A2A56",
        fontWeight: "500",
      }}
    >
      {title}
    </Typography>
  </Box>
);

const CompanyTree = ({ treeData, tenantId, isAdmin }) => {
  const data = safeJSONParse(treeData)
  const checkExist = getCompanyTreeData(treeData)
  const [expandedSections, setExpandedSections] = useState({
    companyInfo: false,
    keyStaff: false,
    parent: false,
    subsidiaries: false,
    related: false,
    competitors: false,
    relatedPeople: false
  });
  
  const [existingCompanies, setExistingCompanies] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingCompany, setIsAddingCompany] = useState(false);
  const hasCheckedCompanies = useRef(false);
  
  useEffect(() => {
    const checkCompanies = async () => {
      if (hasCheckedCompanies.current) return;
      
      const companiesToCheck = [
        ...(data.parent_company?.name ? [data.parent_company] : []),
        ...(data.subsidiaries || []),
        ...(data.related_companies || []),
        ...(data.competitors || [])
      ];

      if (companiesToCheck.length === 0) return;

      try {
        setIsLoading(true);
        // Fetch all companies once
        const allCompanies = await listCompanySummaryData(tenantId);
        
        const results = {};
        for (const company of companiesToCheck) {
          const fullCountryNames = countryMapping[company.country] || [];
          
          // Filter matching companies locally
          const matchingCompany = allCompanies.find(c => 
            c.companyName.toLowerCase() === company.name.toLowerCase()
          );

          if (matchingCompany) {
            results[company.name] = JSON.parse(matchingCompany.summaryData);
          }
        }
        
        setExistingCompanies(results);
      } catch (error) {
        console.error('Error fetching companies:', error);
      } finally {
        setIsLoading(false);
      }
      
      hasCheckedCompanies.current = true;
    };

    checkCompanies();
  }, [data, tenantId]);

  const {
    hasKeyStaff,
    hasParentCompany,
    hasSubsidiaries,
    hasRelatedCompanies,
    hasCompetitors,
    hasRelatedPeople,
    treeNotExist,
  } = checkExist;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  
  const handleCompanyClick = async (company) => {
    try {
      setIsLoading(true);
      console.time("getCompaniesByCompanyName")
      const foundCompanies = await getCompaniesByCompanyName(
        company.name,
        tenantId
      );
      console.timeEnd("getCompaniesByCompanyName")

      console.log("Found Companies By Name", foundCompanies);

      const fullCountryNames = countryMapping[company.country] || [];
      console.log("Full Country Names", fullCountryNames)
      const filteredCompanies = foundCompanies.filter(
        (c) => fullCountryNames.some(name => name.toLowerCase() === c.country.toLowerCase())
      );
      
      console.log("Filtered Companies", filteredCompanies);
      if (filteredCompanies && filteredCompanies.length > 0) {
        if (isAdmin) {
          window.open(
            `/tenant-dashboard/${tenantId}/company/${filteredCompanies[0].id}`,
            "_blank"
          );
        } else {
          window.open(`/company/${filteredCompanies[0].id}`, "_blank");
        }
      }
    } catch (error) {
      console.error("Error searching for company:", error);
      toast.error('Failed to search for company');
    } finally {
      setIsLoading(false);
    }
  };

  const CompanyName = ({ company }) => {
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const fullCountryNames = countryMapping[company.country] || [];
    let countryName="";
    if(fullCountryNames.length > 0) countryName = fullCountryNames[0];
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center'
        }}>
        <Typography 
          sx={{ 
            color: '#1A2A56',
            display: 'inline-flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {company.name}
        </Typography>
        {!existingCompanies[company.name] && (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={() => setOpenCreateModal(true)}
              sx={{
                backgroundColor: '#5186EC',
                '&:hover': {
                  backgroundColor: '#4070d6'
                },
                minWidth: '15px',
                padding: '2px 6px',
                fontSize: '0.75rem',
                height: '24px',
                borderRadius: "10px"
              }}
            >
              Add Company
            </Button>
            <CreateCompanyModal 
              open={openCreateModal}
              onClose={() => setOpenCreateModal(false)}
              tenantId={tenantId}
              initialSearchTerm={company.name}
              initialCountry={countryName}
              companyTree = {true}
            />
          </>
        )}
        <Box>
          {existingCompanies[company.name] && (
            <Box
              component="span"
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center'
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => handleCompanyClick(company)}
                sx={{
                  backgroundColor: '#5186EC',
                  '&:hover': {
                    backgroundColor: '#4070d6'
                  },
                  minWidth: '15px',
                  padding: '2px 6px',
                  fontSize: '0.75rem',
                  height: '24px',
                  borderRadius: "10px"
                }}
              >
                View Company
              </Button>
            </Box>
        )}
        </Box>
      </Box>
    );
  };

  const InfoRow = ({ label, value, isCompanyName = false, company = null }) => {
    if (!value || value === 'undefined' || value === 'NaN' || value === 'N/A' || value === '') {
      return null;
    }
    
    return (
      <Box display="flex" gap={2} mb={1}>
        <Typography color="textSecondary" style={{ minWidth: '150px' }}>
          {label}:
        </Typography>
        {isCompanyName ? (
          <CompanyName company={company} />
        ) : (
          <Typography>{value}</Typography>
        )}
      </Box>
    );
  };

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const formatRevenue = (revenue) => {
    if (!revenue) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(revenue);
  };

  const formatEmployees = (employees) => {
    if (!employees) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(employees);
  };

  if (treeNotExist) {
    return null;
  }

  return (
    <div style={{padding:"0 25px",marginBottom:"25px"}}>
    <Accordion
      defaultExpanded
      sx={{
        background: "#FAFAFC",
        boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
        borderRadius: "8px",
        opacity: isLoading ? 0.7 : 1,
        pointerEvents: isLoading ? 'none' : 'auto',
        position: 'relative',
        marginTop: "10px",
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <CircularProgress size={24} />
          <Typography>Loading...</Typography>
        </Box>
      )}
      <AccordionSummary expandIcon={<CustomIcon />}>
        <Typography
          style={{
            fontSize: "20px",
            color: "#1A2A56",
            fontWeight: "500",
          }}
        >
          Company Tree
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {hasKeyStaff && (
          <Box sx={{ marginBottom: 0 }}>
            <SectionHeader 
              title="Key Staff" 
              isExpanded={expandedSections.keyStaff}
              onClick={() => toggleSection('keyStaff')}
            />
            {expandedSections.keyStaff && (
              <Box sx={{ padding: "10px 0 10px 20px",backgroundColor:"#F5F5F7", borderRadius:"10px" }}>
                {data.key_staff.map((staff, index) => (
                  <Box key={index} mb={index !== data.key_staff.length - 1 ? 3 : 0}>
                    <InfoRow label="Name" value={staff.full_name} />
                    <InfoRow label="Position" value={staff.position} />
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}

        {hasParentCompany && (
          <Box sx={{ marginBottom: 0 }}>
            <SectionHeader 
              title="Parent Company" 
              isExpanded={expandedSections.parent}
              onClick={() => toggleSection('parent')}
            />
            {expandedSections.parent && (
              <Box sx={{ padding: "10px 0 10px 20px",backgroundColor:"#F5F5F7", borderRadius:"10px" }}>
                <InfoRow 
                  label="Name" 
                  value={data.parent_company.name} 
                  isCompanyName={true}
                  company={data.parent_company}
                />
                <InfoRow label="Country" value={data.parent_company.country} />
                <InfoRow label="Industry" value={data.parent_company.industry} />
                <InfoRow label="Revenue" value={formatRevenue(data.parent_company.revenue)} />
                <InfoRow label="Number of Employees" value={formatEmployees(data.parent_company.number_of_employees)} />
                {existingCompanies[data.parent_company.name] && <InfoRow label="ARC Score" value={existingCompanies[data.parent_company.name]["Combined Score"] || 'N/A'} />}
              </Box>
            )}
          </Box>
        )}

        {hasSubsidiaries && (
          <Box sx={{ marginBottom: 0 }}>
            <SectionHeader 
              title="Subsidiaries" 
              isExpanded={expandedSections.subsidiaries}
              onClick={() => toggleSection('subsidiaries')}
            />
            {expandedSections.subsidiaries && (
              <Box sx={{ padding: "10px 0 10px 20px",backgroundColor:"#F5F5F7", borderRadius:"10px" }}>
                {data.subsidiaries.map((subsidiary, index) => (
                  <Box key={index} mb={index !== data.subsidiaries.length - 1 ? 3 : 0}>
                    {subsidiary.name && <InfoRow 
                      label="Name" 
                      value={subsidiary.name} 
                      isCompanyName={true}
                      company={subsidiary}
                    />}
                    {subsidiary.country && <InfoRow label="Country" value={subsidiary.country} />}
                    {subsidiary.industry && <InfoRow label="Industry" value={subsidiary.industry} />}
                    {subsidiary.ownership_percentage && (
                      <InfoRow label="Ownership %" value={`${subsidiary.ownership_percentage}%`} />
                    )}
                    {subsidiary.revenue && <InfoRow label="Revenue" value={formatRevenue(subsidiary.revenue)} />}
                    {subsidiary.number_of_employees && (
                      <InfoRow label="Number of Employees" value={formatEmployees(subsidiary.number_of_employees)} />
                    )}
                    {existingCompanies[subsidiary.name] && <InfoRow label="ARC Score" value={existingCompanies[subsidiary.name]["Combined Score"] || 'N/A'} />}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}

        {hasRelatedCompanies && (
          <Box sx={{ marginBottom: 0 }}>
            <SectionHeader 
              title="Related Companies" 
              isExpanded={expandedSections.related}
              onClick={() => toggleSection('related')}
            />
            {expandedSections.related && (
              <Box sx={{ padding: "10px 0 10px 20px",backgroundColor:"#F5F5F7", borderRadius:"10px" }}>
                {data.related_companies.map((company, index) => (
                  <Box key={index} mb={index !== data.related_companies.length - 1 ? 3 : 0}>
                    {company.name && <InfoRow 
                      label="Name" 
                      value={company.name} 
                      isCompanyName={true}
                      company={company}
                    />}
                    {company.country && <InfoRow label="Country" value={company.country} />}
                    {company.relationship_type && <InfoRow label="Relationship" value={company.relationship_type} />}
                    {existingCompanies[company.name] && <InfoRow label="ARC Score" value={existingCompanies[company.name]["Combined Score"] || 'N/A'} />}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}

        {hasCompetitors && (
          <Box sx={{ marginBottom: 0 }}>
            <SectionHeader 
              title="Competitors" 
              isExpanded={expandedSections.competitors}
              onClick={() => toggleSection('competitors')}
            />
            {expandedSections.competitors && (
              <Box sx={{ padding: "10px 0 10px 20px",backgroundColor:"#F5F5F7", borderRadius:"10px"}}>
                {data.competitors.map((competitor, index) => (
                  <Box key={index} mb={index !== data.competitors.length - 1 ? 3 : 0}>
                    {competitor.name && <InfoRow 
                      label="Name" 
                      value={competitor.name} 
                      isCompanyName={true}
                      company={competitor}
                    />}
                    {competitor.country && <InfoRow label="Country" value={competitor.country} />}
                    {existingCompanies[competitor.name] && <InfoRow label="ARC Score" value={existingCompanies[competitor.name]["Combined Score"] || 'N/A'} />}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}

        {hasRelatedPeople && (
          <Box sx={{ marginBottom: 0 }}>
            <SectionHeader 
              title="Related People" 
              isExpanded={expandedSections.relatedPeople}
              onClick={() => toggleSection('relatedPeople')}
            />
            {expandedSections.relatedPeople && (
              <Box sx={{ padding: "10px 0 10px 20px",backgroundColor:"#F5F5F7", borderRadius:"10px" }}>
                {data.related_people.map((person, index) => (
                  <Box key={index} mb={index !== data.related_people.length - 1 ? 3 : 0}>
                    {person.full_name && <InfoRow label="Name" value={person.full_name} />}
                    {person.relationship_type && <InfoRow label="Relationship" value={person.relationship_type} />}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
    </div>
  );
};

export default CompanyTree; 